import { config } from '@/core/config'
import store from '@/store'
import mixpanel from 'mixpanel-browser'

export function setIdentity() {
  if (store.getters['auth/isLoggedIn']) {
    const userID = store.getters['auth/userID']
    mixpanel.identify(userID)
  }
}

mixpanel.init(config.mixpanel.token, {})

export default mixpanel
