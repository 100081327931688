export const mapParamsToUrl = (url, params = {}) => {
  let searchParams
  try {
    url = new URL(url)
    searchParams = url.searchParams
  } catch (err) {
    searchParams = new URLSearchParams()
  }

  Object.entries(params)
    .filter(([, value]) => !!value)
    .forEach(([key, value]) => searchParams.append(key, value))

  return url instanceof URL ? url.toString() : url + (searchParams.toString() ? `?${searchParams}` : '')
}

export const parseComputeUrlParameters = (computeParameters, opts = null) => {
  const parameters = {}
  computeParameters.split(',').forEach((paramExpression) => {
    if (!!paramExpression && paramExpression.length > 0) {
      let [key, value] = paramExpression.split('_')
      if (opts && opts.useTwoDotOperator && value.indexOf(':') > -1) {
        value = value.split(':')
      }
      parameters[key] = value
    }
  })

  return parameters
}
