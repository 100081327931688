const virtualTourValidDomains = [
  'ricoh360',
  'nodalview',
  'envisite',
  'klapty',
  'meilleurevisite',
  'hdmedia',
  'vr-interactive',
  'andersontech',
  'scenics',
  'visite360',
  'my360tours',
  'istaging',
  'ggnome',
  'matterport',
  '3dvista',
  'concept3d',
  'mytourlive',
  'tourwizard',
]

const virtualTourPartnerValidator = (virtualToolUrl) => {
  if (!virtualToolUrl || !virtualToolUrl.length) {
    return true
  }

  let mainDomain

  try {
    const url = new URL(virtualToolUrl)
    const domainPArts = url.hostname.split('.')
    if (domainPArts.length === 1) {
      return false
    }

    mainDomain = domainPArts[domainPArts.length - 2].toLowerCase()
  } catch (e) {
    console.log('Error parsing virtual tour url', e)

    return false
  }

  for (let i = 0; i < virtualTourValidDomains.length; i++) {
    if (mainDomain === virtualTourValidDomains[i].toLowerCase()) {
      return true
    }
  }

  return false
}

export default virtualTourPartnerValidator
