import { PromiseWithLoading, trackEvent } from '@/helpers'
import { rentalsService } from '@/services/rentals.service'
import { tenantsService } from '@/services/tenants.service'
import { gestionAlerts } from '@/models/gestion'
import { IFRAME_RELOAD_TYPES } from '@/store/index'

export const state = {
  tenants: [],
}

export const SET_TENANTS = 'SET_TENANTS'
export const ADD_TENANT = 'ADD_TENANT'
export const UPDATE_TENANT_QUIT_DATE = 'UPDATE_TENANT_QUIT_DATE'

export const actions = {
  get({ commit, getters, dispatch, rootGetters }, { force } = { force: false }) {
    if (force || rootGetters.isForceReload(IFRAME_RELOAD_TYPES.TENANTS) || getters.getAll.length <= 0) {
      return PromiseWithLoading((resolve, reject) => {
        tenantsService
          .get({ ownerId: rootGetters['auth/user'].id, onlyActive: false })
          .then((tenants) => {
            commit(SET_TENANTS, tenants)
            dispatch(
              'setStoreReload',
              { type: IFRAME_RELOAD_TYPES.REAL_ESTATES, status: false },
              { root: true },
            )
            resolve(tenants)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  getById({ commit }, { tenantId }) {
    return PromiseWithLoading((resolve, reject) => {
      tenantsService
        .getById(tenantId)
        .then((tenant) => {
          commit(ADD_TENANT, tenant)
          resolve(tenant)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getByRealEstateId({ commit, getters }, { realEstateId }) {
    const tenantPromises = []

    getters.getByRealEstateId(realEstateId).forEach((tenant) =>
      tenantPromises.push(
        PromiseWithLoading((resolve, reject) => {
          tenantsService
            .getById(tenant.tenant.id)
            .then((tenantResponse) => {
              commit(ADD_TENANT, tenantResponse)
              resolve(tenantResponse)
            })
            .catch((error) => {
              reject(error)
            })
        }),
      ),
    )

    return Promise.all(tenantPromises)
  },
  async add({ dispatch }, { tenant, buildingId, realEstateId }) {
    await rentalsService
      .create({
        buildingId: buildingId,
        realEstateId: realEstateId,
        charges: parseInt(tenant.charges),
        entryDate: tenant.entryDate,
        rentAmount: parseInt(tenant.rent),
        tenant: {
          email: tenant.email || '',
          firstName: tenant.firstname.trim(),
          lastName: tenant.lastname.trim(),
        },
      })
      .then(() => dispatch('get', { force: true }))
      .then(() => dispatch('userContext/loadUserRealEstates', {}, { root: true }))

    trackEvent('[Gestion] Ajout Locataire', {
      loyer: tenant.rent,
      emailLocataire: tenant.email ? 'yes' : 'no',
    })

    // Need to reset all tenants because data are missing in response (ex: rental.balance used for tenants list page)
    // And need to reload all real estates because we store tenants information in two different places
    // commit(ADD_TENANT, res)
  },
  async updateTenantQuitDate({ commit }, { tenantId, quitDate }) {
    await tenantsService.patch(tenantId, { quitDate })
    commit(UPDATE_TENANT_QUIT_DATE, { tenantId, tenantQuitDate: quitDate })
  },
}

export const getters = {
  getAll: (state) => state.tenants,
  getById: (state) => (tenantId) => state.tenants.find((tenant) => tenant.tenant.id === tenantId),
  getByRealEstateId: (state) => (realEstateId) =>
    state.tenants.filter((tenant) => tenant.realEstate.id === realEstateId),
}

export const mutations = {
  [SET_TENANTS](state, tenants) {
    state.tenants = tenants.map((tenant) => generateTenantAlerts(tenant)) || []
  },
  [ADD_TENANT](state, tenant) {
    state.tenants = state.tenants.filter((stateTenant) => stateTenant.tenant.id !== tenant.tenant.id)
    state.tenants = [...state.tenants, generateTenantAlerts(tenant)]
  },
  [UPDATE_TENANT_QUIT_DATE](state, payload) {
    state.tenants = state.tenants.map((t) => {
      if (t.tenant.id === payload.tenantId) {
        t.rental.endDate = payload.tenantQuitDate ? payload.tenantQuitDate : null
        t.rental.isGoingToLeave = !!payload.tenantQuitDate
      }

      return t
    })
  },
}

// TODO: Do it in hz-workspace. New mongo collections for alerts (useful if one day we want to show history of tenant in his profile)
// TODO: Cron each day to compute negative rent, etc.
const generateTenantAlerts = (tenant) => {
  const { active, hasRentLate, endDate, lease } = tenant.rental
  const today = new Date()

  const isOnDeparture = endDate && new Date(endDate) > today
  const hasWaitingSignatureLease = lease && lease.status === 'signature' && !lease.complete

  const alerts = []

  if (active && hasRentLate) {
    alerts.push(gestionAlerts.NEGATIVE_BALANCE)
  }

  if (isOnDeparture) {
    alerts.push(gestionAlerts.ON_DEPARTURE)
  }

  if (active && hasWaitingSignatureLease) {
    alerts.push(gestionAlerts.SIGNATURE_IN_PROGRESS)
  }

  tenant.alerts = alerts

  return tenant
}

const tenantsModule = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}

export default tenantsModule
