function isFeatureActivated(key) {
  return key && import.meta.env[key] === 'true'
}

function getEnvValue(key) {
  return import.meta.env[key]
}

const mode = getEnvValue('MODE')
const isProduction = 'production' === mode
const domainName = getEnvValue('VITE_APP_DOMAIN_NAME')

export const config = {
  mode,
  isProduction,
  urls: {
    rl: getEnvValue('VITE_APP_RL_URL'),
    api: getEnvValue('VITE_APP_API_URL'),
    hooks: getEnvValue('VITE_APP_HOOKS_URL'),
    home: getEnvValue('VITE_APP_HOME_URL'),
    keycloak: getEnvValue('VITE_APP_KC_BASE_URL'),
    sellBuilding: getEnvValue('VITE_APP_SELL_BUILDING_URL'),
    supportBuyRealEstate: getEnvValue('VITE_APP_SUPPORT_BUY_REAL_ESTATE_URL'),
    domainName,
    app: `https://app.${domainName}`,
  },
  features: {
    devToolBar: isFeatureActivated('VITE_APP_ENABLE_DEV_TOOL_BAR'),
    tracking: isFeatureActivated('VITE_APP_TRACKING_ENABLED'),
    discounts: isFeatureActivated('VITE_APP_ENABLE_DISCOUNTS'),
  },
  mixpanel: {
    token: getEnvValue('VITE_APP_MIXPANEL_TOKEN'),
  },
  google: {
    gtm: {
      url: getEnvValue('VITE_APP_GTM_URL'),
      containerId: getEnvValue('VITE_APP_GTM_CONTAINER_ID'),
    },
    apiKey: getEnvValue('VITE_APP_GOOGLE_API_KEY'),
  },
  keycloak: {
    clientId: getEnvValue('VITE_APP_KC_CLIENT_ID'),
    tokenTtl: getEnvValue('VITE_APP_KC_TOKEN_TTL'),
  },
  sentry: {
    dns: getEnvValue('VITE_APP_SENTRY_DSN'),
    env: getEnvValue('VITE_APP_SENTRY_ENV'),
  },
  onesignal: {
    apiId: getEnvValue('VITE_APP_ONESIGNAL_APP_ID'),
    safariWebId: getEnvValue('VITE_APP_SAFARI_WEB_ID'),
  },
  app: {
    // eslint-disable-next-line no-undef
    name: APP_NAME,
    // eslint-disable-next-line no-undef
    version: APP_VERSION,
  },
  stripe: {
    pk: getEnvValue('VITE_APP_STRIPE_PK'),
  },
  tolgee: {
    apiUrl: getEnvValue('VITE_TOLGEE_API_URL'),
    apiKey: getEnvValue('VITE_TOLGEE_API_KEY'),
  },
}

export const useConfig = () => {
  return config
}
