const sessionStorageStartAuthDateKey = 'start-auth-date'

function getNowTimeStamp() {
  return Math.floor(Date.now() / 1000)
}

function getKeycloak() {
  return window.keycloak
}

function getAuthenticated() {
  return !!getKeycloak() && getKeycloak().authenticated
}

function login(args) {
  sessionStorage.setItem(sessionStorageStartAuthDateKey, getNowTimeStamp().toString())

  // Avoid KC window.location.replace to erase current url from history
  window.history.pushState({}, 'description', window.location.href)

  getKeycloak().login(args)
}

function hasPermissions(permissions) {
  if (permissions && !Array.isArray(permissions)) {
    throw new Error('hasPermissions: permissions must be a array')
  }

  if (getKeycloak() && getAuthenticated() && permissions) {
    const authorizationRequest = { permissions }

    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      identity.authorization
        .entitlement('refonte-api', authorizationRequest)
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
  } else {
    return Promise.resolve(false)
  }
}

function loginAndGetBack(email = null) {
  const url = new URL(window.location.href)
  url.searchParams.append('fromMessage', 'signInSignUpSuccess')

  const args = {
    action: 'login',
    redirectUri: url.toString(),
  }

  if (email) {
    args.loginHint = email
  }

  login(args)
}

function registerAndGetBack(email = null) {
  const url = new URL(window.location.href)

  const args = {
    action: 'register',
    redirectUri: url.toString(),
  }

  if (email) {
    args.loginHint = email
  }

  login(args)
}

function register(options) {
  login({
    ...options,
    action: 'register',
  })
}

function changePassword() {
  login({
    action: 'UPDATE_PASSWORD',
  })
}

async function getToken() {
  await getKeycloak().updateToken(70)

  return getKeycloak().token
}

export const keycloakService = {
  hasPermissions,
  login,
  loginAndGetBack,
  registerAndGetBack,
  register,
  changePassword,
}

export const useKeycloakService = () => {
  return {
    hasPermissions,
    login,
    loginAndGetBack,
    registerAndGetBack,
    register,
    changePassword,
    getAuthenticated,
    getToken,
  }
}
