import { defineStore } from 'pinia'
import { UserProfile } from '@/services/models'
import { getCookieByName } from '@/helpers/getCookieByName'

const ORGANIZATION_ID_NOT_DEFINED = 'not_set'

/*
 * App User store mission is to manage user states, do not confuse with permissions
 */
export const useUserStore = defineStore('app-user', {
  state: () => ({
    isHorizAdmin: false,
    isHorizSales: false,
    isHorizOrganizationManager: false,
    isPro: false,
    isFreeUser: false,
    isPremium: false,
    user: null,
    isPIN: false,
    isFIN: false,
    businessPartner: getCookieByName('partner_name'),
    organizationActive: false,
    organizationId: ORGANIZATION_ID_NOT_DEFINED,
  }),
  actions: {
    setUpFromRlProfile(rlProfile) {
      this.$reset()
      if (rlProfile) {
        this.isFreeUser = rlProfile.plan === 'free'
        this.isPremium = rlProfile.plan !== 'free'
        this.user = rlProfile
        this.isPIN = rlProfile.profiles[UserProfile.INVESTOR] === 'true'
        this.isFIN = rlProfile.profiles[UserProfile.FUTURE_INVESTOR] === 'true'
        this.businessPartner = rlProfile.partner_name || getCookieByName('partner_name')
        this.isHorizAdmin = rlProfile.isHorizAdmin
        this.isHorizSales = rlProfile.isHorizSales
        this.isHorizOrganizationManager = rlProfile.isHorizOrganizationManager
        this.isPro = rlProfile.isPro === 'true'
        this.organizationActive = rlProfile.organizationActive
        this.organizationId = rlProfile.organizationId
      }
    },
  },
  getters: {
    userID: (state) => state.user.id || null,
    isMemberOfProOrganization: (state) => state.organizationId !== ORGANIZATION_ID_NOT_DEFINED,
    hasProOffer: (state) => state.isPro,
  },
})
