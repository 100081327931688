import { config } from '@/core/config'
import i18n from '@/i18n'
import { usePaymentApi } from '@/services/payment.service'
import formatCurrency from '@/filters/formatCurrency'
import { useToastsStore } from '@hz/ui-kit'
import { catch404ToNull } from '@/services/api'

export const state = {
  computedState: {},
  currentProduct: null,
  formerSubscription: null,
  invoice: null,
  paymentIntent: null,
  paymentMethod: null,
  reset: false,
  stripe: null,
  subscription: null,
  mySubscriptionDetails: null,
  mySubscriptionProductDetails: null,
}

export const SET_COMPUTED_STATE = 'SET_COMPUTED_STATE'
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT'
export const SET_MY_SUBSCRIPTION_DETAILS = 'SET_MY_SUBSCRIPTION_DETAILS' // subscription info for my-subscription
export const SET_MY_SUBSCRIPTION_PRODUCT_DETAILS = 'SET_MY_SUBSCRIPTION_PRODUCT_DETAILS' // subscription info for my-subscription
// STRIPE RELATED ENTITIES
export const SET_STRIPE = 'SET_STRIPE'
export const SET_INVOICE = 'SET_INVOICE'
export const SET_PAYMENT_INTENT = 'SET_PAYMENT_INTENT'
export const SET_PROMOTION_CODE_DETAILS = 'SET_PROMOTION_CODE_DETAILS'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'

const SET_FORMER_SUBSCRIPTION = 'SET_FORMER_SUBSCRIPTION'
const SET_RESET_PAYMENT = 'SET_RESET_PAYMENT'
const RESET_PAYMENT = 'RESET_PAYMENT'

const paymentService = usePaymentApi()

export const actions = {
  async confirmPaymentIntent(_, paymentIntentId) {
    return await paymentService.confirmPaymentIntent({
      paymentIntentId: paymentIntentId,
    })
  },
  async regularizePayment(_, { clientSecret, payment_method }) {
    return await state.stripe.confirmCardPayment(clientSecret, { payment_method })
  },
  async resetPayment({ commit, dispatch, getters }) {
    if (getters.paymentIntent?.id) {
      await dispatch('deletePaymentMethod', getters.paymentIntent?.id)
    }
    commit(RESET_PAYMENT)
    await dispatch('previewInvoice', {})
    commit('SET_RESET_PAYMENT', true)
  },
  async setResetPayment({ commit }, value = false) {
    // * used to reset cardblock
    commit(SET_RESET_PAYMENT, value)
  },
  async createPaymentMethod({ commit, state }, cardElement) {
    const response = await state.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    if (response.error) {
      throw new Error(response.error.message)
    }
    commit(SET_PAYMENT_METHOD, response?.paymentMethod)

    return response
  },
  async deletePromotionCode({ commit, dispatch }) {
    await paymentService.deletePromotionCode()
    commit(SET_PROMOTION_CODE_DETAILS, {})
    dispatch('previewInvoice', {})
  },
  async createSubscription({ commit, state, getters }, payload) {
    let options = {
      priceId: state.currentProduct.pricing.stripe.priceId,
    }

    // Handle Promotion Code
    if (getters.hasPromotionCode || (payload && payload.promotionCode)) {
      const { promotionCodeDetails } = state.computedState
      options.promotionCode = promotionCodeDetails?.code ?? payload?.promotionCode
    }

    if (payload.lastCommittedSubscriptionId) {
      options.lastCommittedSubscriptionId = payload.lastCommittedSubscriptionId
    }

    const response = await paymentService.createSubscription(options)
    if (response.error) {
      if (response.error === 'INVALID_PROMOTION_CODE') {
        useToastsStore().error(
          i18n.global.t(`stripeCodes.invalid_promo_code.${response.error.reason || 'default'}`),
        )
      }

      return response
    }

    response.invoice.changementType = 'Souscription'
    commit(SET_INVOICE, response.invoice)
    // handle payment with amount == 0 (no payment intent)
    if (!response.paymentIntent) {
      response.paymentIntent = {
        status: 'succeeded',
        amount: 0,
      }
    }
    commit(SET_PAYMENT_INTENT, response.paymentIntent)
    commit(SET_SUBSCRIPTION, response.subscription)
    if (response.promotionCodeDetails) {
      commit(SET_PROMOTION_CODE_DETAILS, response.promotionCodeDetails)
    }

    return response
  },
  async deletePaymentMethod(_, paymentIntentID) {
    return await paymentService.deletePaymentMethod({
      paymentIntent: paymentIntentID,
    })
  },
  async updatePaymentMethod(_, id) {
    return await paymentService.updatePaymentMethod({
      paymentMethod: id,
    })
  },
  async getSubscriptionInfo({ commit }) {
    const response = await paymentService.getSubscriptionInfo()

    commit(SET_PAYMENT_METHOD, response?.method)
    commit(SET_MY_SUBSCRIPTION_DETAILS, response)
    commit(SET_MY_SUBSCRIPTION_PRODUCT_DETAILS, response.product)

    return response
  },
  async getState({ commit }, payload) {
    const getStateParams = { ...payload }
    delete getStateParams.productId
    const computedState = await paymentService.getState(getStateParams)

    if (payload.productId) {
      computedState.product = await paymentService
        .getProduct({ productId: payload.productId })
        .catch(catch404ToNull)
    }

    commit(SET_COMPUTED_STATE, computedState)
    commit(SET_CURRENT_PRODUCT, computedState.product)

    commit(SET_PAYMENT_METHOD, computedState?.cards?.length ? computedState.cards[0] : null)
    if (computedState.promotionCodeDetails) {
      commit(SET_PROMOTION_CODE_DETAILS, computedState.promotionCodeDetails)
    }

    return computedState
  },
  async previewInvoice({ commit }, payload) {
    let options = {
      priceId: state.currentProduct?.pricing?.stripe?.priceId,
    }

    // Handle Promotion Code
    if (getters.hasPromotionCode || (payload && payload.promotionCode)) {
      const { promotionCodeDetails } = state.computedState
      options.promotionCode = promotionCodeDetails?.code ?? payload?.promotionCode

      if (payload.promotionCodeTyped) {
        options.promotionCodeTyped = true
      }
    }

    if (payload.omitError) {
      options.omitError = true
    }

    const response = await paymentService.previewInvoice(options)
    if (response.error) {
      if (response.error === 'INVALID_PROMOTION_CODE') {
        useToastsStore().error(
          i18n.global.t(`stripeCodes.invalid_promo_code.${response.reason || 'default'}`),
        )
      }

      return response
    }

    response.invoice.changementType = response.changement
    commit(SET_INVOICE, response.invoice)

    if (response.currentSubscription) {
      commit(SET_FORMER_SUBSCRIPTION, response.currentSubscription)
    }

    if (response.promotionCodeDetails) {
      commit(SET_PROMOTION_CODE_DETAILS, response.promotionCodeDetails)
      if (payload.promotionCode) {
        useToastsStore().success(i18n.global.t('horiz.payment.notifications.promoCode'))
      }
    } else {
      commit(SET_PROMOTION_CODE_DETAILS, null)
    }

    return response
  },
  setPaymentIntent({ commit }, payload) {
    commit(SET_PAYMENT_INTENT, payload)
  },
  setPaymentMethod({ commit }, payload) {
    commit(SET_PAYMENT_METHOD, payload)
  },
  setStripe({ commit }) {
    commit(SET_STRIPE)
  },
}

export const mutations = {
  [SET_STRIPE](state) {
    // eslint-disable-next-line no-undef
    state.stripe = Stripe(config.stripe.pk)
  },
  [SET_COMPUTED_STATE](state, payload) {
    state.computedState = payload
  },
  [SET_INVOICE](state, payload) {
    state.invoice = payload
  },
  [SET_SUBSCRIPTION](state, payload) {
    state.subscription = payload
  },
  [SET_CURRENT_PRODUCT](state, payload) {
    state.currentProduct = payload
  },
  [SET_FORMER_SUBSCRIPTION](state, payload) {
    state.formerSubscription = payload
  },
  [SET_MY_SUBSCRIPTION_DETAILS](state, payload) {
    state.mySubscriptionDetails = payload
  },
  [SET_MY_SUBSCRIPTION_PRODUCT_DETAILS](state, payload) {
    state.mySubscriptionProductDetails = payload
  },
  [SET_PAYMENT_INTENT](state, payload) {
    state.paymentIntent = payload
  },
  [SET_PAYMENT_METHOD](state, payload) {
    state.paymentMethod = payload
  },
  [RESET_PAYMENT](state) {
    state.paymentIntent = null
    state.subscription = null
  },
  [SET_RESET_PAYMENT](state, value) {
    state.reset = value
  },
  [SET_PROMOTION_CODE_DETAILS](state, payload) {
    state.computedState = { ...state.computedState, promotionCodeDetails: payload }
  },
}

export const getters = {
  currentProduct: (state) => state.currentProduct,
  invoice: (state) => state.invoice,
  isRegularisation: (state) => state.invoice?.changementType === 'Régularisation' || false,
  hasPromotionCode: (state) => !!(state.computedState && state.computedState.promotionCodeDetails),
  hasPaymentMethod: (state) =>
    (state.computedState && state.computedState.cards && !!state.computedState.cards.length) ||
    !!state.paymentMethod?.card,
  paymentIntent: (state) => state.paymentIntent,
  paymentMethod: (state) => state.paymentMethod?.card ?? null,
  paymentMethodFull: (state) => state.paymentMethod ?? null,
  promotionCodeDetails: (state) => (state.computedState && state.computedState.promotionCodeDetails) ?? null,
  reset: (state) => state.reset,
  stripe: (state) => state.stripe,
  subscription: (state) => state.subscription,
  formerSubscription: (state) => state.formerSubscription,
  // Summary data
  currentPrice: (
    state, // TODO: find .amount prop not in 'lines'
  ) => {
    const productPricing = state.currentProduct?.pricing?.price
    const invoicePricing = state.invoice?.pricing?.amount_due

    return formatCurrency((productPricing && productPricing / 100) || invoicePricing / 100, 2) || null
  },
  credits: (state) => (state.invoice && formatCurrency(state.invoice.starting_balance / 100, 2)) || null,
  subTotal: (state) => {
    const subTotal = state.invoice.subtotal

    return formatCurrency((subTotal < 0 ? 0 : subTotal) / 100, 2)
  },
  totalExcludingTax: (state) => {
    const amountExcludingTax = state.invoice.lines.data.reduce((amount, line) => {
      return amount + line.amount_excluding_tax
    }, 0)

    return formatCurrency((amountExcludingTax < 0 ? 0 : amountExcludingTax) / 100, 2)
  },
  tva: (state) => {
    const tva = state.invoice.lines.data.reduce((amount, line) => {
      return amount + line.tax_amounts[0].amount
    }, 0)

    return formatCurrency(tva / 100, 2)
  },
  tvaRate: () => {
    return 20
  },
  balance: (state) => state.computedState?.balance ?? 0,
  promotionCode: (state) => {
    if (
      state.invoice &&
      state.invoice.total_discount_amounts &&
      state.invoice.total_discount_amounts.length &&
      state.invoice.total_discount_amounts[0].amount !== 0
    ) {
      return `- ${formatCurrency(state.invoice.total_discount_amounts[0].amount / 100, 2)}`
    } else return formatCurrency(0)
  },
  proration: (state) => {
    if (!state.invoice || !state.invoice.lines) return undefined
    const proration = state.invoice.lines.data.reduce((amount, line) => {
      return amount + line.amount
    }, 0)

    const amount = state.currentProduct.pricing.price - proration

    return formatCurrency(-amount / 100, 2)
  },
  creditAfter: (state, getters) => {
    if (state.invoice.starting_balance) {
      if (getters.sameBillingInterval) {
        const creditAfter = (state.invoice.starting_balance + state.invoice.subtotal) / 100
        if (creditAfter < 0) return formatCurrency(-creditAfter, 2)
      } else {
        const promo = state.invoice?.total_discount_amounts[0]?.amount || 0
        const creditAfter = (state.invoice.starting_balance + state.invoice.subtotal - promo) / 100
        if (creditAfter < 0) return formatCurrency(-creditAfter, 2)
      }
    }
    if (state.invoice.total < 0) return formatCurrency(-state.invoice.total / 100, 2)

    return 0
  },
  remainingAmountProration: (state) => {
    const line = state.invoice.lines.data.find((line) => {
      return line.description.includes('Remaining') || line.description.includes('restant')
    })

    return line ? formatCurrency(line.amount / 100, 2) : formatCurrency(0)
  },
  sameBillingInterval: (state) =>
    state.invoice &&
    state.invoice.lines.data[0]?.plan.interval === state.invoice.lines.data[1]?.plan.interval,
  unusedTimeProration: (state) => {
    const line = state.invoice.lines.data.find((line) => {
      return line.proration || line.description.includes('Unused') || line.description.includes('inutilisé')
    })

    return line ? formatCurrency(line.amount / 100, 2) : formatCurrency(0)
  },
  total: (state) => state.invoice && formatCurrency(state.invoice.amount_due / 100, 2),
  // My Subscription
  mySubscriptionDetails: (state) => state.mySubscriptionDetails,
  mySubscriptionProductDetails: (state) => state.mySubscriptionProductDetails,
  isCommitted: (state) => state.mySubscriptionDetails?.subscription?.committed ?? false,
  isCommitmentCanceled: (state) =>
    state.mySubscriptionDetails?.subscription?.committed &&
    state.mySubscriptionDetails?.subscription?.status === 'canceled',
  currentProductName: (state) => {
    const replacement =
      state.mySubscriptionDetails.product.pricing.billingInterval === 'month'
        ? state.mySubscriptionDetails?.subscription?.committed
          ? ' engagement'
          : ' mensuel'
        : ' annuel'

    return state.mySubscriptionDetails.product.name.replace(replacement, '').toUpperCase()
  },
  mySubscriptionIsPastDue: (state) => state.mySubscriptionDetails?.subscription?.status === 'past_due',
}

const paymentModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default paymentModule
