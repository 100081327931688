import store from '@/store'
import stepperModule from '@/store/stepper.module'
import { realEstatesService } from '@/services/realEstates.service'
import { HZ_INVESTOR_ONBOARDING_PATHNAME } from '@/router/constants'

export const investorBeforeEnter = async () => {
  if (!store.hasModule('stepper')) {
    store.registerModule('stepper', stepperModule)
  }

  const count = await realEstatesService.getRealEstateNumber()

  if (count.count > 0) {
    return true
  } else {
    return HZ_INVESTOR_ONBOARDING_PATHNAME
  }
}
