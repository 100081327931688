export const TENANTS_PAGE_FILTERS_KEY = 'tenants-page-filters'

const clear = (key) => localStorage.removeItem(key)

const setString = (key, string) => localStorage.setItem(key, string)
const setObject = (key, object) => localStorage.setItem(key, JSON.stringify(object))

// eslint-disable-next-line no-prototype-builtins
const hasKey = (key) => localStorage.hasOwnProperty(key)

const getString = (key, defaultString) => {
  if (hasKey(key)) {
    return localStorage.getItem(key)
  } else {
    return defaultString
  }
}

const getBoolean = (key, defaultString) => {
  return JSON.parse(getString(key, defaultString))
}

const getObject = (key, defaultObject) => {
  if (hasKey(key)) {
    return JSON.parse(localStorage.getItem(key))
  } else {
    return defaultObject
  }
}

export const localStorageService = {
  clear,
  getBoolean,
  getObject,
  getString,
  setObject,
  setString,
  hasKey,
}
