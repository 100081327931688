import { synchroService } from '@/services/synchro.service'

const batchSize = 200

const state = {
  accountAccess: null,
  accounts: [],
  transactions: [],
  synchronizing: false,
  skip: [],
  lastPage: [],
}

const getters = {
  accountAccess: (state) => state.accountAccess,
  accounts: (state) => state.accounts,
  transactions: (state) => state.transactions,
  synchronizing: (state) => state.synchronizing,
  lastPage: (state) => state.lastPage,
}
const actions = {
  async redirectBudgea() {
    await synchroService.redirectBudgea()
  },
  async fetchAccounts({ commit }) {
    const accounts = await synchroService.getBankAccounts()
    if (accounts === '') return

    commit('setAccounts', accounts)
  },
  setAccountAccess({ commit }, accountAccess) {
    commit('setAccountAccess', accountAccess)
  },

  async fetchTransactions({ commit, state }, { accountId }) {
    if (!state.skip[accountId]) state.skip[accountId] = 0
    if (typeof state.lastPage[accountId] === 'undefined') state.lastPage[accountId] = false

    const transactions = await synchroService.getTransactions(
      'id_account=' + accountId + `&skip=${state.skip[accountId]}&limit=${batchSize}&sort=-application_date`,
    )
    if (transactions && transactions.length < batchSize) commit('setLastPage', accountId)

    commit('pushTransactions', transactions)
    commit('updateSkip', accountId)
  },

  async linkBuildings({ commit }, { buildingIds, accountId }) {
    const accounts = state.accounts.map((acc) => {
      if (acc.id === accountId) {
        acc.buildingIds.push(...buildingIds)
      }

      return acc
    })
    commit('setAccounts', accounts)
    await synchroService.linkBuildings(buildingIds, accountId)
  },
  async unlinkBuilding({ commit, state }, { buildingId, accountId }) {
    await synchroService.unlinkBuilding(buildingId, accountId)
    commit(
      'setAccounts',
      state.accounts.map((acc) => {
        if (acc.id === accountId) {
          acc.buildingIds = acc.buildingIds.filter((e) => e !== buildingId)
        }

        return acc
      }),
    )
  },
  async deleteAccount({ commit, state }, { userId, accountId }) {
    await synchroService.deleteAccount(accountId, userId)
    commit(
      'setAccounts',
      state.accounts.filter((acc) => acc.id !== accountId),
    )
  },

  unlinkOperation({ commit, state }, { operationId, transactionId }) {
    const transactions = state.transactions.map((tr) => {
      if (tr._id === transactionId) {
        tr.RLlinkedOperations = tr.RLlinkedOperations.filter((op) => op.id !== operationId)
      }

      return tr
    })
    commit('setTransactions', transactions)
  },

  linkOperation({ commit, state }, { operation, transactionId }) {
    const transactions = state.transactions.map((tr) => {
      if (tr._id === transactionId) {
        tr.RLlinkedOperations.push({
          id: operation._id,
          operation: operation,
          type: operation.type,
        })
      }

      return tr
    })
    commit('setTransactions', transactions)
  },

  updateLinkedOperation({ commit, state, rootGetters }, { operation, transactionId }) {
    if (operation.locataireId) {
      const tenant = rootGetters['tenants/getAll'].find(
        (tenant) => tenant.tenant.id === operation.locataireId,
      )
      if (tenant) {
        operation.locataireId = {
          id: operation.locataireId,
          nom: tenant.tenant.lastName,
          prenom: tenant.tenant.firstName,
        }
      }
    }
    const transactions = state.transactions.map((tr) => {
      if (tr._id === transactionId) {
        tr.RLlinkedOperations.map((op) => {
          if (op.id === operation._id) {
            op.operation = operation
          }

          return op
        })
      }

      return tr
    })
    commit('setTransactions', transactions)
  },

  setSynchronizing({ commit }, synchronizing) {
    commit('setSynchronizing', synchronizing)
  },
}

const mutations = {
  setAccountAccess(state, accountAccess) {
    state.accountAccess = accountAccess
  },
  setAccounts(state, accounts) {
    state.accounts = accounts
  },
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setSynchronizing(state, synchronizing) {
    state.synchronizing = synchronizing
  },
  pushTransactions(state, transactions) {
    state.transactions.push(...transactions)
  },
  updateSkip(state, accountId) {
    state.skip[accountId] += batchSize
  },
  setLastPage(state, accountId) {
    state.lastPage[accountId] = true
  },
}

const synchroModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default synchroModule
