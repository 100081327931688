import { createI18n } from 'vue-i18n'

import { localeFR } from './locales/fr'
import numberFormats from './locales/numberFormats'

const i18n = createI18n({
  locale: 'fr',
  numberFormats,
  allowComposition: true,
  fallbackLocale: 'fr',
  messages: localeFR,
  warnHtmlInMessage: 'off',
})

export default i18n
