export const state = {
  data: {
    version: 1,
  },
}

export const RESET = 'RESET'
export const SET_DATA = 'SET_DATA'

export const actions = {
  reset({ commit }) {
    commit(RESET)
  },
  setData({ commit }, stepData) {
    commit(SET_DATA, stepData)
  },
}

export const mutations = {
  [RESET](state) {
    state.data = {
      version: 1,
    }
  },
  [SET_DATA](state, stepData) {
    const { step, payload } = stepData
    state.data[step.path] = payload
  },
}

export const getters = {
  data: (state) => state.data,
  hasData: (state) => state && state.data && Object.keys(state.data).length,
}

const stepperModule = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}

export default stepperModule
