import KeycloakAuthorization from '@/plugins/keycloak-authz'

export default function (keycloak) {
  this.loggedIn = true

  this.claims = {}
  this.claims.name = keycloak.idTokenParsed.name

  this.authc = {}
  this.authc.token = keycloak.token

  this.logout = function () {
    keycloak.logout()
  }

  this.account = function () {
    keycloak.accountManagement()
  }

  this.hasRole = function (name) {
    if (keycloak && keycloak.hasRealmRole(name)) {
      return true
    }

    return false
  }

  this.isAdmin = function () {
    return this.hasRole('admin')
  }

  // eslint-disable-next-line no-undef
  this.authorization = new KeycloakAuthorization(keycloak)

  this.ready = this.authorization.ready
}
