import { API, catch404ToNull, HOOKS } from '@/services/api'
import { formatPrice } from '@/helpers/formatNum'

export async function getSimulations() {
  return await HOOKS.get('/v1/simulations')
}

export async function getSimulationById(simulationId) {
  return await HOOKS.get(`/v1/simulations/${simulationId}`).catch(catch404ToNull)
}

export async function getLastSimulation() {
  return await HOOKS.get('/v1/simulations/last').catch(catch404ToNull)
}

export async function getFullReportById(id) {
  return await HOOKS.get(`/v1/simulations/full-report/${id}`)
}

export function printPptReport(reportId, adId) {
  return HOOKS.post(
    '/v1/simulations/print',
    {
      format: 'pptx',
      reportId,
      adId,
    },
    {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  )
}

export function printPdfReport(inputs, additionalParams) {
  return API.post(
    '/simulation/pdf-report',
    { inputs, additionalParams },
    {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  )
}

export function getPrintParameters() {
  return HOOKS.get('/v1/print-parameters')
}

export function updatePrintParameters(params) {
  return HOOKS.put('/v1/print-parameters', params)
}

function setDefaultRevenus(revenus) {
  return HOOKS.patch('/v1/simulationParameters', { revenus, active: true })
}

function getOtherParams() {
  return HOOKS.get('/v1/simulationOtherParameters')
}
function updateOtherParams(params) {
  return HOOKS.patch('/v1/simulationOtherParameters', params)
}

function updateDefaultValues(params) {
  return HOOKS.patch('/v1/simulationParameters', params)
}

function getDefaultValues() {
  return HOOKS.get('/v1/simulationParameters')
}

function addFavorite(payload) {
  return HOOKS.post('/v1/simulations/favorites', payload)
}
function deleteFavorite(id) {
  return HOOKS.delete(`/v1/simulations/favorites/${id}`)
}

function addFolder(folderName) {
  return HOOKS.post('/v1/simulations/favorites/folders', { folderName })
}

function updateFolderName(folderName, payload) {
  return HOOKS.patch(`/v1/simulations/favorites/folders/${folderName}`, payload)
}

function deleteFolder(folderName) {
  return HOOKS.delete(`/v1/simulations/favorites/folders/${folderName}`)
}

function fetchUserDirectory() {
  return HOOKS.get('/v1/simulations/favorites')
}

function getNetVendeur(prixfai, agenceRate) {
  const price = prixfai / (1 + agenceRate / 100)

  return formatPrice(price)
}

function getResellPrice(netVendeur, travauxr, valorisationreventeprix, reventeannee) {
  const price = (netVendeur + travauxr) * Math.pow(1 + valorisationreventeprix / 100, reventeannee)

  return formatPrice(price)
}

function getResellValorisationPrice(reventeprix, netVendeur, travauxr, reventeannee) {
  const ratio = (reventeprix / (netVendeur + travauxr) - 1) * 100
  const price = Math.pow(Math.abs(ratio), 1 / reventeannee) * (ratio < 0 ? -1 : 1)

  return formatPrice(price)
}

export const simulationService = {
  getFullReportById,
  getSimulationById,
  getLastSimulation,
  getOtherParams,
  getSimulations,
  printPdfReport,
  printPptReport,
  setDefaultRevenus,
  updateOtherParams,
  updateDefaultValues,
  getDefaultValues,
  getPrintParameters,
  updatePrintParameters,
  addFavorite,
  deleteFavorite,
  addFolder,
  updateFolderName,
  deleteFolder,
  fetchUserDirectory,
}

export const useSimulationService = () => {
  return {
    getNetVendeur,
    getResellPrice,
    getResellValorisationPrice,
  }
}
