const defaultOptions = {
  anchorEl: null,
  showOverlay: true,
  noPadding: false,
  maskClosable: true,
  useOverlayStyle: true,
  overflowVisible: false,
  headerIncluded: false,
}

export const state = {
  component: null,
  props: {},
  isOpen: false,
  classes: '',
  options: {
    ...defaultOptions,
  },
  onClosed: () => {},
}

export const MUTATIONS = {
  OPEN_LIGHT_BOX: 'OPEN_LIGHT_BOX',
  CLOSE_LIGHT_BOX: 'CLOSE_LIGHT_BOX',
}

export const actions = {
  open({ commit }, { component, props, classes, options, onClosed }) {
    commit(MUTATIONS.OPEN_LIGHT_BOX, { component, props, classes, options, onClosed })
    if (state.options.showOverlay) {
      document.querySelector('body').classList.add('lightbox-no-scroll')
    }
  },
  close({ commit }, payload) {
    if (
      typeof state.onClosed !== 'undefined' &&
      typeof payload !== 'undefined' &&
      typeof payload.userHasQuit !== 'undefined'
    ) {
      state.onClosed()
    }
    document.querySelector('body').classList.remove('lightbox-no-scroll')
    document.querySelector('body').style.overflow = ''
    commit(MUTATIONS.CLOSE_LIGHT_BOX)
  },
}

export const mutations = {
  [MUTATIONS.OPEN_LIGHT_BOX](state, { component, props, classes, options, onClosed }) {
    state.component = component
    state.props = props
    state.isOpen = true
    state.classes = classes || ''
    state.options = {
      ...defaultOptions,
      ...options,
    }
    state.onClosed = onClosed
  },
  [MUTATIONS.CLOSE_LIGHT_BOX](state) {
    state.component = null
    state.props = {}
    state.isOpen = false
    state.classes = ''
    state.onClosed = () => {}
  },
}

export const getters = {}

const lightboxModule = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default lightboxModule
