import { Layouts } from '@/layouts'

const meta = {
  layout: Layouts.PRINT,
  spontaneousInteractionAllowed: false,
  hideZendesk: true,
}

const routes = [
  {
    name: 'hz-pdf-report',
    path: '/pdf-report',
    component: () => import('@/views/PDF/ReportPDF.vue'),
    meta,
  },
]

export default routes
