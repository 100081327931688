import i18n from '@/i18n'

export const simulatorRealEstateTypes = {
  APARTMENT: 'appartement',
  HOUSE: 'maison',
  BUILDING: 'immeuble',
  PARKING: 'parking',
}

export const simulatorInvestTypes = {
  FUTURE: 'futurinvestment',
  ACQUIRED: 'alreadyacquired',
}

export const rentingRentState = {
  RENTED: 'rented',
  NOT_RENTED: 'notrented',
}

export const rentingState = {
  OLD: 0,
  NEW: 1,
}

export const taxRegimes = {
  DEFICIT_FONCIER_REEL: 'rr',
  MICRO_FONCIER: 'mf',
  LMNP_MICRO_BIC: 'me',
  LMNP_REEL: 'mer',
  LMP_REEL: 'mepr',
  DNORANDIE_M6: 'norm6',
  DNORANDIE_9: 'norm9',
  DNORANDIE_12: 'norm12',
  SCIIS: 'sciis',
  PINEL_6: 'pin6',
  PINEL_9: 'pin9',
  PINEL_12: 'pin12',
  COINTER: 'cointer',
  COSOCIAL: 'cosocial',
  COTRESSOCIAL: 'cotressocial',
  COINTERMED: 'cointermed',
  DUFLOT: 'duf',
  BORLOO_30: 'bo30',
  BORLOO_60: 'bo60',
  BORLOO_70: 'bo70',
  SCELLIER_2009: 'se2009',
  SCELLIER_2011: 'se2011',
  SCELLIER_2011B: 'se2011b',
  SCELLIER_2012: 'se2012',
  LOC_AVANTAGES: 'locAvantages2022',
}

export const taxRegimesSpecificForNewRealEstate = [
  taxRegimes.PINEL_6,
  taxRegimes.PINEL_9,
  taxRegimes.PINEL_12,
  taxRegimes.DUFLOT,
]

export const taxRegimesSpecificForOldRealEstate = [
  taxRegimes.DNORANDIE_M6,
  taxRegimes.DNORANDIE_9,
  taxRegimes.DNORANDIE_12,
]

export const notRelevantTaxRegimesForToday = [
  taxRegimes.BORLOO_30,
  taxRegimes.BORLOO_60,
  taxRegimes.BORLOO_70,
  taxRegimes.SCELLIER_2009,
  taxRegimes.SCELLIER_2011,
  taxRegimes.SCELLIER_2011B,
  taxRegimes.SCELLIER_2012,
  taxRegimes.COINTER,
  taxRegimes.COSOCIAL,
  taxRegimes.COTRESSOCIAL,
  taxRegimes.COINTERMED,
]

export const detailedReport = {
  RESUME: 'Rapport-Tab-Resume',
  IMPOT_REVENUS: 'Rapport-Tab-Fiscalite',
  IMPOT_SOCIETE: 'Rapport-Tab-Fiscalite-IS',
  INVESTISSEMENT: 'Rapport-Tab-Investissement',
  CASH_FLOW: 'Rapport-Tab-Cash-flow',
  RENDEMENT_LOCATIF: 'Rapport-Tab-Rendements',
  RENDEMENT_PATRIMONIAL: 'Rapport-Tab-Rentabilite-financiere',
  REVENTE: 'Rapport-Tab-Revente',
  MEILLEUR_REGIME: 'Rapport-Tab-Meilleur-Regime',
  NEGOCIER_PRIX: 'Rapport-Tab-Negocier-Prix',
  QUEL_LOYER: 'Rapport-Tab-Loyer',
  QUAND_REVENDRE: 'Rapport-Tab-Quand-Revendre',
  OPTIMISER_EMPRUNT: 'Rapport-Tab-Optimiser-emprunt',
  NEGOCIER_BANQUE: 'Rapport-Tab-Negocier-Banque',
}

export const PNO_UNDER_110_SQUARE_METERS = 79
export const BENEFIT_GLI = 2.15

export const LIFE_INSURANCE_RATE = 1.1
export const BANKBOOK_A_RATE = 3
export const DEFAULT_LOAN_PERIOD = 20

export function regimeList() {
  const regimeSlugs = [
    'rr',
    'locAvantages2022',
    'mer',
    'sciis',
    'me',
    'mf',
    'mepr',
    'duf',
    'bo30',
    'bo60',
    'bo70',
    'ni',
    'norm6',
    'norm9',
    'norm12',
    'pin6',
    'pin9',
    'pin12',
    'se2009',
    'se2011',
    'se2011b',
    'se2012',
    'cointer',
    'cosocial',
    'cotressocial',
    'cointermed',
  ]
  const list = []
  for (let i = 0; i < regimeSlugs.length; i++) {
    list.push({ slug: regimeSlugs[i], name: i18n.global.t(`horiz.tax-regimes.${regimeSlugs[i]}`) })
  }

  return list
}

export const regimeTypes = regimeList().map((regime) => ({
  label: regime.name,
  value: regime.slug,
}))

export const regimeTypesForNewRealEstate = regimeTypes.filter(
  (regime) => regime.value !== 'ni' && !taxRegimesSpecificForOldRealEstate.includes(regime.value),
)
export const regimeTypesForOldRealEstate = regimeTypes.filter(
  (regime) => regime.value !== 'ni' && !taxRegimesSpecificForNewRealEstate.includes(regime.value),
)
