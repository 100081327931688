import { defineStore } from 'pinia'
import { notificationHandlerService } from '@/services/notificationHandler.service'

export const useAppNotificationsStore = defineStore('app-notifications', {
  state: () => ({
    notifications: [],
    unreadCounter: 0,
    notificationCenterIsOpened: false,
  }),
  actions: {
    async startFetchUnreadCount() {
      await this.fetchUnreadCount()
      setInterval(this.fetchUnreadCount, 1000 * 60 * 10)
    },

    async fetchUnreadCount() {
      const unseenCount = await notificationHandlerService.getUnSeenCount()
      await useAppNotificationsStore().setNotificationCounter(unseenCount.count ?? unseenCount)
    },

    async fetchNotifications() {
      this.notifications = await notificationHandlerService.getNotifications()
    },

    async openNotificationCenter() {
      await this.fetchNotifications()

      this.notificationCenterIsOpened = true
      this.unreadCounter = 0
      notificationHandlerService.handleOpenNotificationCenterClick()
    },

    closeNotificationCenter() {
      this.notificationCenterIsOpened = false
    },

    async toggleNotificationCenter() {
      if (this.notificationCenterIsOpened) {
        this.closeNotificationCenter()
      } else {
        await this.openNotificationCenter()
      }
    },

    async setNotificationCounter(counter) {
      if (this.notificationCenterIsOpened) {
        await this.fetchNotifications()
      }
      this.unreadCounter = counter
    },

    async clickOnNotification(clickedNotification) {
      await notificationHandlerService.handleNotificationClick(clickedNotification)
      this.notifications = this.notifications.map((notification) => ({
        ...notification,
        clicked: notification.id === clickedNotification.id ? true : notification.clicked,
      }))
    },
  },
})
