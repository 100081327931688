import numeral from 'numeral'
// eslint-disable-next-line no-unused-vars
import fr from 'numeral/locales'

numeral.locale('fr')

/**
 *
 * @param value
 * @param options string|object
 * if string : could be a predefined format name like percent, currency or a numeral format http://numeraljs.com/#format
 * if object :
 * {
 *   format: string (options type string above)
 *   reverse: boolean (default false) if true, doing unformat formatNumber('1 000€', {reverse: true}) ==> 1000
 *   decimals: int permits to add decimals to predefined formats
 *   removeDecimalsForInts: boolean (default: false) if true and value is int, don't display 0s (10 instead of 10,00)
 *   scale: boolean (default true) if false, don't scale the value * 100 for percent formatting (predefined or numeral native format)
 *   signed: boolean (default: false) if true add + for positive values (and keep - for negative)
 * }
 * @returns string
 */
export default function formatNumber(value, options) {
  options = options || 'default'
  let format, reverse, decimals, scale, signed, removeDecimalsForInts

  if (typeof options === 'string') {
    format = options
  } else if (typeof options === 'object') {
    ({ format, reverse, decimals, scale, signed, removeDecimalsForInts } = options)
    if (scale === false) {
      numeral.options.scalePercentBy100 = scale
    }
  } else {
    return value
  }

  format = format || 'default'

  if (reverse) {
    return numeral(value).value()
  }

  if (typeof value === 'string') {
    value = Number(value)
  }

  let parsed = parseFloat(value)
  if (isNaN(parsed)) {
    return parsed
  }

  // thousand sep, rounded
  const predefined = {
    default: '0,0',
    percent: '0,0%',
    currency: '0,0$',
  }

  let { isPredefined, formatToUse } = predefined[format]
    ? {
        isPredefined: true,
        formatToUse: predefined[format],
      }
    : { isPredefined: false, formatToUse: format }

  if (isPredefined && decimals) {
    // insert decimal pattern in predefined format which is '.' followed by '0' times decimals prop)
    formatToUse = formatToUse.replace(/^(0,0+){1}(%|\$)?$/, `$1.${'0'.repeat(decimals)}$2`)
  }

  if (isPredefined && signed && parsed !== 0) {
    formatToUse = `+${formatToUse}`
  }

  if (value <= 0) {
    formatToUse = formatToUse.replace(/[\\+\\(]/, '')
  }

  let formatted = numeral(parsed).format(formatToUse)

  if (removeDecimalsForInts === true && Number.isInteger(parsed)) {
    formatted = formatted.replace(/,0+/g, '')
  }

  return formatted
}
