import { Permissions, trackEvent, redirect, parseComputeUrlParameters } from '@/helpers'
import futureInvestorRoutes from './onboardings/futureInvestor'
import investorRoutes, { ONBOARDING_INVESTOR_ENTRY_POINT } from './onboardings/investor'
import mySubscriptionRoutes from './my-subscription'
import {
  HZ_FUTURE_INVESTOR_ONBOARDING,
  HZ_FUTURE_INVESTOR_ONBOARDING_PATHNAME,
  HZ_INVESTOR_ONBOARDING,
  HZ_INVESTOR_ONBOARDING_PATHNAME,
  LOGIN,
  SIGNUP,
  UNAUTHORIZED,
  LOADING,
  ERROR,
  SMART_BANNER_META,
} from '@/router/constants'
import { Layouts } from '@/layouts'
import store from '@/store'
import UserProfile from '@/services/models/userProfile'
import { realEstatesService } from '@/services/realEstates.service'
import { useOrganizationsService } from '@/services/organizations.service'
import checkPermission from '@/router/middleware/checkPermission'
import pdfRoutes from '@/router/routes/pdf'
import adminRoutes from '@/router/routes/admin'
import legacyRoutes from '@/router/routes/legacyRoutes'
import { HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME } from '@/router/constants'
import { keycloakService } from '@/services/keycloak.service'
import { requireAuthRedirect } from '@/router/middleware/requiredAuth'
import { investorBeforeEnter } from '@/router/rules/investorBeforeEnter'
import { futureInvestorBeforeEnter } from '@/router/rules/futureInvestorBeforeEnter'
import { usePermissionsStore } from '@/stores/permissions'
import myOrganizationRoutes from '@/router/routes/my-organization'
import { paymentRoutes } from '@/router/routes/payment'
import {
  addRealEstateAdRoute,
  documentTemplatesRoute,
  myAssetsPerformanceRoute,
  operationsRoute,
  profitabilityReportRoute,
  realEstateAdsListRoute,
  realEstatesRoute,
  tenantsRoute,
  updateRealEstateAdRoute,
} from '@/router/routes/contants'

import profileRoutes from '@/router/routes/profile'
import { useConfig } from '@/core/config'
import { useUserProductStore } from '@/stores/userProduct'
import i18n from '@/i18n'
import { useNotificationsPushStore } from '@/stores/appNotificationsPush'
import { shallowRef } from 'vue'
import DrawerNotificationsParameters from '@/components/Sidebar/Notifications/DrawerNotificationsParameters.vue'
import { useDrawerStore } from '@hz/ui-kit'
import { useUserStore } from '@/stores/user'

const routes = []

const layout = Layouts.EMPTY

// * INVESTOR ONBOARDING (PIN)
routes.push({
  path: HZ_INVESTOR_ONBOARDING_PATHNAME,
  name: HZ_INVESTOR_ONBOARDING,
  meta: {
    layout,
    requireAuth: true,
  },
  component: () => import('@/views/Onboardings/Investor/Index.vue'),
  children: investorRoutes,
  redirect: { name: ONBOARDING_INVESTOR_ENTRY_POINT },
  async beforeEnter(to, from, next) {
    if (useUserStore().isFreeUser) {
      const count = await realEstatesService.getRealEstateNumber()

      if (count.count > 0) {
        await store.dispatch('notifications/setDisplayNotPremium')
        next(false)

        return
      }
    } else {
      const permissionsStore = usePermissionsStore()
      await permissionsStore.refreshQuotasAndPermissions()

      if (!permissionsStore.canCreateNewBuilding) {
        await store.dispatch('notifications/setDisplayNotAllowed')
        next(tenantsRoute.path)

        return
      }
    }

    next()
  },
})

routes.push({
  path: '/onboarding/investor/:pathMatch(.*)*',
  redirect: { name: ONBOARDING_INVESTOR_ENTRY_POINT },
})

routes.push({
  path: '/operations/:operationId?',
  name: operationsRoute.name,
  meta: {
    requireAuth: true,
    requireAuthRedirect: requireAuthRedirect.LANDING_INVESTOR,
    permissions: [Permissions.LEGACY],
    layout: Layouts.DEFAULT,
    noTitle: true,
    setUserProfile: UserProfile.INVESTOR,
  },
  children: [
    {
      path: 'r',
      redirect: () => {
        return { name: operationsRoute.name, query: { update: true } }
      },
    },
  ],
  component: () => import('@/views/Operations.vue'),
  beforeEnter: investorBeforeEnter,
})

// * FUTURE_INVESTOR ONBOARDING
routes.push({
  path: HZ_FUTURE_INVESTOR_ONBOARDING_PATHNAME,
  name: HZ_FUTURE_INVESTOR_ONBOARDING,
  meta: {
    layout,
    ...SMART_BANNER_META.PERFORMANCE_ALERTS,
  },
  component: () => import('@/views/Onboardings/FutureInvestor/Index.vue'),
  redirect: { name: HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME },
  children: futureInvestorRoutes,
})
routes.push({
  path: '/onboarding/future-investor/:pathMatch(.*)*',
  redirect: { name: HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME },
})

// * FUTURE INVESTOR DASHBOARD
routes.push({
  ...profitabilityReportRoute,
  meta: {
    layout: Layouts.DEFAULT,
    locale: 'nav-report',
    setUserProfile: UserProfile.FUTURE_INVESTOR,
    ...SMART_BANNER_META.PERFORMANCE_ALERTS,
  },
  component: () => import('@/views/Dashboards/FutureInvestor/Index.vue'),
  beforeEnter: futureInvestorBeforeEnter,
})

routes.push({
  path: '/redirectBudgea',
  name: 'redirect-budgea',
  async beforeEnter(to, from, next) {
    try {
      await store.dispatch('synchro/redirectBudgea')
      next('/bank-synchronization')
    } catch (e) {
      console.log(e)
      next('/my-subscription')
    }
  },
})

routes.push({
  path: '/bank-synchronization',
  name: 'bank-synchronization',
  meta: {
    requireAuth: true,
    layout: Layouts.DEFAULT,
    ignorePresentPushNotifications: true,
  },
  component: () => import('@/views/BankSynchronization.vue'),
})

// * PAYMENT PAGE
routes.push(...paymentRoutes)

// * MY SUBSCRIPTION PAGE
routes.push(...mySubscriptionRoutes)

// * IDEAL PROJECT
routes.push({
  path: '/dashboard/ideal-project',
  name: 'hz-ideal-project',
  beforeEnter: (to) => {
    let link = `${useConfig().urls.home}/biens-rentables`
    if (to.query.computeParameters) {
      const parameters = parseComputeUrlParameters(to.query.computeParameters)
      if (!!parameters.budget || !!parameters.cityName || !!parameters.postalCode) {
        link += '/search?'
      }

      const queryParams = []
      if (!!parameters.cityName && !!parameters.postalCode) {
        const value = `${parameters.postalCode}|${parameters.cityName}|0`
        queryParams.push(`location=${encodeURIComponent(value)}`)
      }

      if (parameters.budget) {
        queryParams.push(`prixMax=${parameters.budget}`)
      }

      link += queryParams.join('&')
    }

    window.location = link
  },
})

/**
 * ADS
 */
const adsRouteCommonMetas = {
  layout: Layouts.DEFAULT,
  supportBannerUrl: false,
  whiteBackground: true,
  requireAuth: true,
  parentRouteName: 'ideal-project',
}

routes.push({
  ...realEstateAdsListRoute,
  meta: adsRouteCommonMetas,
  component: () => import('@/views/RealEstateAds/RealEstateAdsList.vue'),
})
routes.push({
  ...addRealEstateAdRoute,
  beforeEnter: (to, from, next) => {
    if (usePermissionsStore().canPublishAnnonces) {
      next(true)
    } else {
      useUserProductStore().showUpgradeProDrawer(i18n.global.t('announces.title'))

      next(false)
    }
  },
  meta: {
    ...adsRouteCommonMetas,
  },
  component: () => import('@/views/RealEstateAds/RealEstateAdsForm.vue'),
})
routes.push({
  ...updateRealEstateAdRoute,
  meta: adsRouteCommonMetas,
  component: () => import('@/views/RealEstateAds/RealEstateAdsForm.vue'),
})

/**
 * INVESTOR AREA ROUTES
 */
routes.push(
  {
    path: '/',
    name: 'index',
    meta: {
      requireAuth: true,
    },
    component: () => import('@/views/Index.vue'),
    beforeEnter(to, from, next) {
      const user = store.getters['auth/user']

      if (user && !store.getters['auth/isPIN'] && !store.getters['auth/isFIN']) {
        next()
      } else if (user && store.getters['auth/isPIN']) {
        next(tenantsRoute.path)
      } else {
        next(profitabilityReportRoute.path)
      }
    },
  },
  {
    path: '/unauthorized',
    name: UNAUTHORIZED,
    component: () => import('@/views/Unauthorized.vue'),
    meta: {
      layout: Layouts.DEFAULT,
    },
  },
  {
    path: '/loading',
    name: LOADING,
    component: () => import('@/views/Loading.vue'),
    meta: {
      layout: Layouts.EMPTY,
    },
  },
  {
    path: '/error',
    name: ERROR,
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: Layouts.EMPTY,
    },
  },
  {
    ...realEstatesRoute,
    meta: {
      requireAuth: true,
      requireAuthRedirect: requireAuthRedirect.LANDING_INVESTOR,
      locale: 'nav-real-estates',
      layout: Layouts.DEFAULT,
      setUserProfile: UserProfile.INVESTOR,
    },
    children: [
      {
        path: 'r',
        redirect: () => {
          return { name: realEstatesRoute.name, query: { update: true } }
        },
      },
    ],
    component: () => import('@/views/RealEstates.vue'),
    beforeEnter: investorBeforeEnter,
  },
  {
    ...myAssetsPerformanceRoute,
    meta: {
      requireAuth: true,
      requireAuthRedirect: requireAuthRedirect.LANDING_INVESTOR,
      locale: 'nav-performance',
      middlewares: [checkPermission],
      permissions: [Permissions.LEGACY],
      layout: Layouts.DEFAULT,
      noTitle: true,
    },
    component: () => import('@/views/PerformancePage.vue'),
    beforeEnter: investorBeforeEnter,
  },
  {
    ...tenantsRoute,
    meta: {
      requireAuth: true,
      requireAuthRedirect: requireAuthRedirect.LANDING_INVESTOR,
      locale: 'nav-locataires',
      layout: Layouts.DEFAULT,
      noTitle: true,
      setUserProfile: UserProfile.INVESTOR,
    },
    children: [
      {
        path: 'r',
        redirect: () => {
          return { name: tenantsRoute.name, query: { update: true } }
        },
      },
    ],
    component: () => import('@/views/Tenants.vue'),
    beforeEnter: investorBeforeEnter,
  },
  {
    path: '/login',
    name: LOGIN,
    meta: { requireAuth: true, locale: 'nav-signin', layout: Layouts.EMPTY },
    props: true,
    beforeEnter: async (to, from, next) => {
      next('/')
    },
  },
  {
    path: '/signup',
    name: SIGNUP,
    meta: { locale: 'nav-signup', layout: Layouts.EMPTY },
    props: true,
    component: () => import('@/views/Signup.vue'),
  },
  {
    ...documentTemplatesRoute,
    meta: {
      requireAuth: true,
      layout: Layouts.DEFAULT,
      locale: 'nav-document-templates',
    },
    component: () => import('@/views/DocumentTemplates.vue'),
  },
  {
    name: 'notification-push-redirection',
    path: '/notification',
    meta: { layout: Layouts.EMPTY },
    beforeEnter(to) {
      trackEvent('Clic notification', {
        contenu: to.query.tracking,
        destination: to.query.redirect,
        canal: 'push',
      })

      redirect(to.query.redirect)
    },
  },
  {
    name: 'notification-push-check',
    path: '/notification-push-check',
    meta: { layout: Layouts.EMPTY },
    beforeEnter() {
      setTimeout(async () => {
        window.OneSignalDeferred?.push(async () => {
          const pushStore = useNotificationsPushStore()
          await pushStore.initOS()

          window.top.postMessage(
            {
              name: 'hz-notification-push-check',
              browserPermissionGranted: pushStore.browserPermissionGranted,
              userIsOptIn: pushStore.userIsOptIn,
            },
            '*',
          )
        })
      }, 1000)
    },
  },
  {
    name: 'notification-push-parameters',
    path: '/notification-push-parameters',
    component: null,
    beforeEnter() {
      useDrawerStore().addDrawer({
        id: 'drawer-notifications-parameters',
        component: shallowRef(DrawerNotificationsParameters),
      })
    },
  },
)

/**
 * Invitation
 */
routes.push({
  name: 'hz-invitation',
  path: '/invitation',
  props: true,
  component: () => import('@/views/Organizations/InvitationView.vue'),
  beforeEnter: async (to, from, next) => {
    const isLoggedIn = store.getters['auth/isLoggedIn']
    if (isLoggedIn) {
      const invitation = await useOrganizationsService().getMyInvitation()

      if (invitation) {
        to.params.invitation = invitation
        next()
      } else {
        next('/')
      }
    } else {
      keycloakService.loginAndGetBack(to.query.email ?? null)
    }
  },
})

/**
 * Payment links
 */
routes.push({
  name: 'hz-payment-link',
  path: '/payment-link/:id',
  meta: { layout: Layouts.EMPTY },
  beforeEnter: async (to, from, next) => {
    const invoice = await useOrganizationsService()
      .getSubscriptionInvoice(to.params.id)
      .catch(() => {
        next('/')
      })
    window.location.href = invoice.paymentLink
  },
})

/**
 * LEGACY ROUTES
 */
routes.push(...legacyRoutes)

routes.push(...pdfRoutes)

routes.push(...adminRoutes)

routes.push(...profileRoutes)

routes.push(...myOrganizationRoutes)

routes.push({
  path: '/:pathMatch(.*)*',
  meta: { layout: Layouts.EMPTY },
  component: () => import('@/views/404.vue'),
})

export default routes
