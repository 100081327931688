import { userLoading } from '@/helpers'
import { defineStore } from 'pinia'
import { useProprietaryIdentitiesService } from '@/services/proprietaryIdentities.service'
import { useDrawerStore } from '@hz/ui-kit'
import { defineAsyncComponent, shallowRef } from 'vue'
import { AppException } from '@/core/exception/app-exception'

export const useProprietaryIdentitiesStore = defineStore('app-proprietary-identities', {
  state: () => ({
    identities: [],
  }),
  actions: {
    async fetch() {
      const { start, stop } = userLoading('fetch-proprietary-identities')

      try {
        start()
        const identities = await useProprietaryIdentitiesService().find({})
        this.identities = identities.data
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }

      return this.identities
    },
    async create(identity) {
      const { start, stop } = userLoading('create-proprietary-identity')

      try {
        start()
        await useProprietaryIdentitiesService().create(identity)
        await this.fetch()
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }
    },
    async update(id, identity) {
      const { start, stop } = userLoading('update-proprietary-identity')

      try {
        start()
        await useProprietaryIdentitiesService().update(id, identity)
        await this.fetch()
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }
    },
    async delete(id) {
      const { start, stop } = userLoading('delete-proprietary-identity')

      try {
        start()
        await useProprietaryIdentitiesService().delete(id)
        await this.fetch()
      } catch (error) {
        throw new AppException(error)
      } finally {
        stop()
      }
    },

    openProprietaryIdentitiesListDrawer(props = {}) {
      useDrawerStore().addDrawer({
        id: 'drawer-proprietary-identities-list',
        component: shallowRef(
          defineAsyncComponent(() => import('@/components/User/DrawerListProprietaryIdentities.vue')),
        ),
        props,
      })
    },
    openAddProprietaryIdentityDrawer(identity = null, props = {}) {
      useDrawerStore().addDrawer({
        id: 'drawer-add-proprietary-identity',
        component: shallowRef(
          defineAsyncComponent(() => import('@/components/User/DrawerAddProprietaryIdentity.vue')),
        ),
        props: {
          identity,
          ...props,
        },
      })
    },
  },
})
