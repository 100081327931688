import i18n from '@/i18n'

export const types = {
  APARTMENT: 1,
  STUDIO: 2,
  HOUSE: 3,
  PARKINGSPACE: 4,
  PREMICES: 5,
  CELLAR: 6,
  SITE: 7,
}

export const realEstateTypes = [
  { code: types.APARTMENT, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.apartment') },
  { code: types.STUDIO, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.studio') },
  { code: types.HOUSE, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.house') },
  { code: types.PARKINGSPACE, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.parkingSpace') },
  { code: types.PREMICES, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.premises') },
  { code: types.CELLAR, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.cellar') },
  { code: types.SITE, label: i18n.global.t('horiz.onboarding.pin.realEstateTypes.site') },
]

export default {
  types,
  realEstateTypes,
}
