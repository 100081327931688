import { clearMonitoringUserId, setMonitoringUserId } from '@/core/logging/sentry'
import UserProfile from '@/services/models/userProfile'
import { getCookieByName } from '@/helpers/getCookieByName'
// eslint-disable-next-line no-unused-vars
import { trackEvent } from '@/helpers'

const LocalStorageProfileKey = 'currentProfile'
const LocalStorageRegisteredKey = 'registered'

const currentProfile = localStorage.getItem(LocalStorageProfileKey)
const registered = localStorage.getItem(LocalStorageRegisteredKey)

export const state = {
  currentProfile,
  registered,
  userSignInLessThant5min: false,
  forceReload: false,
  token: window.keycloak && window.keycloak.tokenParsed,
}

export const LOGOUT = 'LOGOUT'
export const REGISTERED = 'REGISTERED'
export const SET_CURRENT_PROFILE = 'SET_CURRENT_PROFILE'
export const SET_SIGN_IN_LESS_5_MIN = 'SET_SIGN_IN_LESS_5_MIN'
export const SET_TOKEN = 'SET_TOKEN'

export const getters = {
  /* OK (user) */ isFreeUser: (state, getters) => getters.user?.plan === 'free',
  forceReload: (state) => state.forceReload,
  // eslint-disable-next-line no-undef
  /* OK (auth) */ isLoggedIn: () => window.keycloak && window.keycloak.authenticated,
  isRegistered: () => state.registered,
  userRegisteredLessThant30s: (state) =>
    !!state.token && state.token.auth_time - state.token.rl_profile.registered < 30,
  /* OK (user) */ user: (state) => state.token && state.token.rl_profile,
  /* OK (user) */ userID: () =>
    (window.keycloak && window.keycloak.tokenParsed && window.keycloak.tokenParsed.rl_profile.id) || null,
  /* OK (user) */ isPIN: (state, getters) => getters.user?.profiles[UserProfile.INVESTOR] === 'true',
  /* OK (user) */ isFIN: (state, getters) => getters.user?.profiles[UserProfile.FUTURE_INVESTOR] === 'true',
  /* OK (user) */ businessPartner: (state, getters) =>
    getters.user?.partner_name || getCookieByName('partner_name'),
  userSignInLessThant5min: (state) => state.userSignInLessThant5min,
  registeredLessThanAWeek: (state) => {
    const now = Math.floor(Date.now() / 1000)

    return now - state.token.rl_profile.registered < 604800
  },
  /* OK (user) */ isPro: (state) => state.token?.rl_profile?.isPro === 'true',
}

const sessionStorageStartAuthDateKey = 'start-auth-date'

export const actions = {
  forceReload({ commit }) {
    commit('forceReload')
  },
  login({ getters }) {
    setMonitoringUserId(getters.user.id, getters.user.username)
  },
  logout({ commit }) {
    sessionStorage.removeItem(sessionStorageStartAuthDateKey)
    clearMonitoringUserId()
    trackEvent(LOGOUT)
    commit(LOGOUT)
  },
  checkIsNewSignUp({ commit, getters }) {
    const isNewUser = Date.now() / 1000 - getters.user['registered'] < 300 // User registered less than 5min

    if (!getters.isRegistered && isNewUser) {
      commit(REGISTERED)
    }
  },
  setUserSignInLessThant5min({ commit }) {
    commit(SET_SIGN_IN_LESS_5_MIN, true)
  },
  resetUserSignInformations({ commit }) {
    commit(SET_SIGN_IN_LESS_5_MIN, false)
  },
  setToken({ commit }, token) {
    commit(SET_TOKEN, token)
  },
}

export const mutations = {
  forceReload(state) {
    state.forceReload = true
  },
  [LOGOUT](state) {
    localStorage.removeItem(LocalStorageProfileKey)
    state.currentProfile = null
    localStorage.removeItem(LocalStorageRegisteredKey)
    state.registered = false
    // eslint-disable-next-line no-undef
    keycloak.logout({
      redirectUri: window.location.href,
    })
  },
  [REGISTERED](state) {
    state.registered = true
    localStorage.setItem(LocalStorageRegisteredKey, true)
  },
  [SET_CURRENT_PROFILE](state, userProfile) {
    state.currentProfile = userProfile
    localStorage.setItem(LocalStorageProfileKey, userProfile)
  },
  [SET_SIGN_IN_LESS_5_MIN](state, userSignInLessThant5min) {
    state.userSignInLessThant5min = userSignInLessThant5min
  },
  [SET_TOKEN](state, token) {
    state.token = token
  },
}

const authModule = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}

export default authModule
