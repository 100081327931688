// export const activePlans = ['FUTÉE', 'BASIQUE']
import { config } from '@/core/config'

export const activePlans = ['HORIZ+']

export const plans = [
  {
    title: 'HORIZ+',
    pricing: {
      priceIdYearly: 'horizPlusYearly99',
      priceIdMonthly: 'horizPlusUncommitted1999',
    },
    pricePerMonth: '8,25',
    pricePerYear: '99',
    pricePerMonthUncommitted: '19.99',
    pricePerYearUncommitted: '239.88',
    ...(config.features.discounts && {
      discount: {
        percent: '58', // old: 20
        pricePerYear: '99', // old: 228
        pricePerMonth: '8.25', // old: 19
        // pricePerYearUncommitted: '238.88',
        // pricePerMonthUncommitted: '19.99',
      },
    }),
    descriptions: [
      {
        title: 'Simulateur de rendement',
        data: [
          {
            label: 'Nombre de nouvelles simulations',
            text: '30 / mois',
          },
          {
            label: 'Simulations location nue, meublée LMNP et SCI',
            type: 'checkboxTicked',
          },
          {
            label: 'Recherche de biens exclusifs Horiz.io',
            type: 'checkboxTicked',
          },
          {
            label: 'Rapport d’investissement complet et imprimable',
            type: 'checkboxTicked',
          },
        ],
      },
      {
        title: 'Gestion locative autonome',
        data: [
          {
            label: 'Nombre de biens à gérer',
            text: 'Illimité',
            prependStar: true,
          },
          {
            label: 'Mise en location et gestion',
            type: 'checkboxTicked',
          },
          {
            label: 'Bail numérique',
            text: '1 gratuit',
            prependStar: true,
          },
          {
            label: 'Performance patrimoniale',
            type: 'checkboxTicked',
          },
          {
            label: 'Aide à la déclaration d’impôts',
            type: 'checkboxTicked',
          },
        ],
      },
      {
        data: [
          {
            label: 'Accès au support',
            type: 'checkboxTicked',
          },
        ],
      },
    ],
  },
]
