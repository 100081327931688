import { defineStore } from 'pinia'

const MASKED_LOADERS = ['iframe']

/*
 * App Loader store mission is to manage loader across all application, no more
 */
export const useLoaderStore = defineStore('app-loader', {
  state: () => ({
    loaders: [],
    loaderApp: {
      show: () => {},
      hide: () => {},
      setOpaque: () => {},
    },
  }),
  actions: {
    start(loaderApp) {
      this.loaderApp = loaderApp
      this.load('system-loading')

      setTimeout(() => {
        this.loaderApp.setOpaque(true)
        this.end('system-loading')
      }, 700)
    },
    load(loadMessage) {
      this.loaders.push(loadMessage)
      this.loaders = uniq(this.loaders)
      if (this.loaders.length > 0) {
        this.loaderApp.show()
      }
    },
    end(loadMessage) {
      this.loaders = uniq(this.loaders).filter((loader) => loader !== loadMessage)
      if (this.loaders.length === 0) {
        this.loaderApp.hide()
      }
    },
  },
  getters: {
    isLoading: (state) => (loadMessage) => !state.loaders.indexOf(loadMessage),
    anyVisibleLoading: (state) => !!state.loaders.filter((loader) => !MASKED_LOADERS.includes(loader)).length,
  },
})

const uniq = (array) => {
  return array.filter((el, index, arr) => index === arr.indexOf(el))
}
