import { API } from '@/services/api'

export function create(tenantData) {
  return API.post('/rentals', tenantData)
}

export function update(id, data) {
  return API.put(`/rentals/${id}`, data)
}

export function deleteRental(tenantId) {
  return API.delete(`/rentals/${tenantId}`)
}

export const rentalsService = {
  create,
  update,
}
