import { profileAttributes, useUserProfileStore } from '@/stores/userProfile'
import { useLoaderStore } from '@/stores/loader'

export function ensureUserProfileCompletedGuard(attributesToUpdate) {
  return (to, from, next) => {
    let profileReady = true

    if (
      attributesToUpdate.includes(profileAttributes.lastName) ||
      attributesToUpdate.includes(profileAttributes.firstName)
    ) {
      profileReady = profileReady && useUserProfileStore().areLastAndFirstNameDefined
    }

    if (
      attributesToUpdate.includes(profileAttributes.address) ||
      attributesToUpdate.includes(profileAttributes.postalCode) ||
      attributesToUpdate.includes(profileAttributes.city) ||
      attributesToUpdate.includes(profileAttributes.country)
    ) {
      profileReady = profileReady && useUserProfileStore().isAddressDefined
    }

    if (profileReady) {
      next()
    } else {
      useUserProfileStore().openUserProfileDrawer({
        attributesToUpdate,
        afterEditCallback: () => {
          next(to)
        },
      })
      useLoaderStore().end('navigation')
    }
  }
}
