export const gestionAlertTypes = {
  WARNING: 'warning',
  PROCESS: 'process',
}

export const gestionAlerts = {
  NEGATIVE_BALANCE: {
    priority: 0,
    type: gestionAlertTypes.WARNING,
    name: 'negativeBalance',
  },
  UNLEASED: {
    priority: 10,
    type: gestionAlertTypes.WARNING,
    name: 'unleased',
  },
  ON_DEPARTURE: {
    priority: 20,
    type: gestionAlertTypes.WARNING,
    name: 'onDeparture',
  },
  SIGNATURE_IN_PROGRESS: {
    priority: 30,
    type: gestionAlertTypes.PROCESS,
    name: 'signatureInProgress',
  },
}

export const performanceDataTypes = {
  PATRIMONY: 'patrimony',
  BUILDING: 'building',
  FINANCIAL_PRODUCT: 'financialProduct',
}

export const financialProducts = {
  scpi: {
    id: 'scpi',
    name: 'SCPI dit “Pierre papier” à 5% / an',
    legend: 'SCPI',
    return: {
      2022: {
        brut: 5,
        net: 5,
      },
    },
  },
  lifeInsurance: {
    id: 'lifeInsurance',
    name: 'Assurance-vie (fonds euro) à 2% / an',
    legend: 'Assurance-vie',
    return: {
      2022: {
        brut: 2,
        net: 2,
      },
    },
  },
}
