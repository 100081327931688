import { HOOKS } from '@/services/api'

const BASE_PATH = '/v1/validations'

export const useValidationService = () => ({
  phone: async (countryCode, phoneNumber) => {
    return await HOOKS.post(`${BASE_PATH}/phone`, {
      countryCode,
      phoneNumber,
    })
  },
})
