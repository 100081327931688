import { API, HOOKS } from '@/services/api'

const getUserMortgageAffordabilityStat = () => {
  return HOOKS.get('/v1/statistics/user/mortgage-affordability')
}

const getAverageRealEstatePriceForCity = (postalCode, surfaceArea) => {
  return API.get(`/reports/average-price?postalCode=${postalCode}&surfaceArea=${surfaceArea}`)
}

function getCityAverageRent(postalCode, surfaceArea, realEstateType) {
  let ENDPOINT = `/reports/average-rent?postalCode=${postalCode}&surfaceArea=${surfaceArea}`
  if (realEstateType) {
    ENDPOINT += `&realEstateType=${realEstateType}`
  }

  return API.get(ENDPOINT)
}

function averageSurface(postalCode, purchasePrice) {
  return API.get(`/reports/average-surface?purchasePrice=${purchasePrice}&postalCode=${postalCode}`)
}

function getBuildings(params) {
  return HOOKS.get('/v1/statistics/building/data', { params })
}

function getDetails(params) {
  return HOOKS.get('/v1/statistics/building/details', { params })
}

const defaultStatsValues = {
  RENT_CHARGE_PER_SQUARE_METER: 20,
  FISCAL_TAX_PER_SQUARE_METER: 18,
  MAX_BOOKING_FEES: 950,
}

function estimateTotalYearCharge(surface) {
  return Math.round(defaultStatsValues.RENT_CHARGE_PER_SQUARE_METER * surface)
}

function getMonthlyTenantCharge(yearlyTotalCharge) {
  return Math.round((yearlyTotalCharge * 0.25) / 12)
}

function getFiscalTax(surface) {
  return Math.round(defaultStatsValues.FISCAL_TAX_PER_SQUARE_METER * surface)
}

function getBookingFees(purchasePrice) {
  return Math.min(Math.round(purchasePrice * 0.01), defaultStatsValues.MAX_BOOKING_FEES)
}

export const statsService = {
  averageSurface,
  estimateTotalYearCharge,
  getUserMortgageAffordabilityStat,
  getAverageRealEstatePriceForCity,
  getBookingFees,
  getCityAverageRent,
  getFiscalTax,
  getMonthlyTenantCharge,
  getBuildings,
  getDetails,
}
