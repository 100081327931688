// TODO - Goal of this exception is transform api error into app error

export const ErrorTypes = {
  validation: 'validation',
  unknown: 'unknown',
}

export class AppException {
  error = null
  type = ErrorTypes.unknown

  constructor(error) {
    this.error = error

    if (error.errors && error.errors.length > 0) {
      for (const err of error.errors) {
        if (err.name === 'ValidationError') {
          this.type = ErrorTypes.validation
          break
        }
      }
    }
  }
}
