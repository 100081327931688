<template>
  <transition name="loader-fade" mode="out-in">
    <div v-if="display" class="horiz-io-loader">
      <div class="background" :class="{ opaque: opaque }"></div>
      <uikit-dot-lottie-player class="loader" src="/lottie/loader.lottie"></uikit-dot-lottie-player>
    </div>
  </transition>
</template>

<script setup>
import { UikitDotLottiePlayer } from '@hz/ui-kit'
import { ref, defineExpose } from 'vue'

const display = ref(true)
const opaque = ref(false)

const hide = () => {
  display.value = false
}

const show = () => {
  display.value = true
}

const setOpaque = (value) => {
  opaque.value = value
}

defineExpose({
  hide,
  show,
  setOpaque,
})
</script>

<style scoped lang="less">
.horiz-io-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  transition: opacity 700ms ease-in-out;

  z-index: 9999;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ds-color-white-100);
    opacity: 1;
    transition: opacity 700ms ease-in-out;

    &.opaque {
      opacity: 0.5;
    }
  }
}

:deep(.uikit-dot-lottie-player.loader) {
  width: 100px;
  height: 100px;
}

.loader-fade-enter-active {
}

.loader-fade-leave-active {
}

.loader-fade-enter-from,
.loader-fade-leave-to,
.loader-fade-enter,
.loader-fade-leave-active {
  opacity: 0;
}
</style>
