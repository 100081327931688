import { Layouts } from '@/layouts'
import store from '@/store'
import { useUserStore } from '@/stores/user'

// * PAYMENT PAGE
export const paymentRoutes = [
  {
    path: '/payment',
    name: 'payment',
    meta: {
      requireAuth: true,
      layout: Layouts.PAYMENT,
      ignorePresentPushNotifications: true,
      spontaneousInteractionAllowed: false,
      hideZendesk: true,
    },
    component: () => import('@/views/Payment.vue'),
    beforeEnter(to, from, next) {
      const isLoggedIn = store.getters['auth/isLoggedIn']
      if (isLoggedIn) {
        if (useUserStore().isMemberOfProOrganization) {
          next({ name: 'hz-organization-subscription' })
        } else {
          if (to.query.id) {
            next()
          } else {
            next({
              name: 'my-subscription',
            })
          }
        }
      } else {
        next({
          name: 'my-subscription',
        })
      }
    },
  },
]
