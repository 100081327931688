import { citiesService } from '@/services/cities.service'
import { legacyService } from '@/services/legacy.service'
import { isValid, parse } from 'date-fns'

/**
 *
 * @param {String} addressData addressData
 */
export async function validatePropertyAddressTryGuessPostalCode(addressData) {
  let isValid = true

  if (typeof addressData === 'object' && Object.keys(addressData).length > 0) {
    const mandatoryKeys = ['country', 'latitude', 'longitude', 'locality', 'street_number']

    mandatoryKeys.forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(addressData, key)) {
        isValid = false
      }
    })

    if (isValid && !Object.prototype.hasOwnProperty.call(addressData, 'postal_code')) {
      const city = await citiesService.findNearest(addressData.latitude, addressData.longitude)
      if (!city) {
        isValid = false
      }
    }
  } else {
    isValid = false
  }

  return isValid
}

/**
 *
 * @param {String} addressData addressData
 */
export async function validateCityPostalCode(cityWithPostalCode) {
  if (!cityWithPostalCode) {
    return false
  }

  const [city] = cityWithPostalCode.split(' (')
  const results = await legacyService.searchCity(city)

  if (!results || !Array.isArray(results)) {
    return false
  }

  return results.some((result) => result.value === cityWithPostalCode)
}

/**
 * TEMPORARY until datepicker is specified !!
 * @param {String} date basic French formated date
 */
export function validateBasicFrenchDate(date) {
  if (!date) return true
  var re = /^\d{2}\/\d{2}\/\d{4}$/i
  const DATE_FORMAT = 'dd/MM/yyyy'
  const parsed = parse(date, DATE_FORMAT, new Date())
  const toTest = new Date(parsed)

  return re.test(String(date).toLowerCase()) && toTest && isValid(toTest)
}

export function validateFrenchDateBeforeToday(date) {
  if (!date) return true
  var re = /^\d{2}\/\d{2}\/\d{4}$/i
  const DATE_FORMAT = 'dd/MM/yyyy'
  const parsed = parse(date, DATE_FORMAT, new Date())
  const toTest = new Date(parsed)

  return re.test(String(date).toLowerCase()) && toTest && isValid(toTest) && toTest < new Date()
}
