// http://kazupon.github.io/vue-i18n/guide/number.html
// https://kazupon.github.io/vue-i18n/api/#n
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export default {
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
}
