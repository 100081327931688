import { defineAsyncComponent, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import { downloadAndOpenPDFInNewTab } from '@/helpers/openFileHelper'
import { legacyService } from '@/services/legacy.service'
import { simulationService, useSimulationService } from '@/services/simulation.service'
import { statsService } from '@/services/stats.service'
import {
  DEFAULT_LOAN_PERIOD,
  PNO_UNDER_110_SQUARE_METERS,
  rentingRentState,
  rentingState,
  simulatorInvestTypes,
  simulatorRealEstateTypes,
  taxRegimes,
} from '@/models/simulation'
import i18n from '@/i18n'

import { trackEventWithUtm } from '@/helpers'
import { usePermissionsStore } from '@/stores/permissions'
import { useSimulationsHistoryStore } from '@/stores/simulationHistorys'
import { useDrawerStore, useToastsStore } from '@hz/ui-kit'
import { useUserProductStore } from '@/stores/userProduct'
import { useUserStore } from '@/stores/user'
import { useSettingsRepositoryStore } from '@/stores/settingsRepository'
import { useAuthStore } from '@/stores/auth'

const PNO_WITH_INSURED = PNO_UNDER_110_SQUARE_METERS

const defaultLoyer = 500

const defaultInputs = () => {
  const loanRates = useSettingsRepositoryStore().getLoanRates()

  return {
    type: simulatorRealEstateTypes.APARTMENT, // Type de bien: Appartement | Maison | Immeuble | Parking
    realestatetype: simulatorInvestTypes.FUTURE, // Il s'agit d'un: Futur investissement | Bien déja acquis
    isrented: rentingRentState.NOT_RENTED, // Le bien est: Actuellement loué | Non  loué
    neuf: rentingState.OLD, // Le bien est: Ancien | Neuf
    prix: 0, // Prix (fai)
    travauxr: 0, // Travaux de renovation
    thermalRenovation: false, // Travaux de renovation thermique
    surface: 30, // Surface
    code_postal: '0', // Postal code should always be a string (ex: 33000)
    ville: '', // Only city name, no postal code, no | (ex: Bordeaux)
    loyer: defaultLoyer, // Loyer
    loyerannuel: defaultLoyer * 12,
    chargest: 0, // Charges total
    chargesr: 0, // Charges récupérables
    travauxa: 0, // Travaux annuels
    tf: 0, // Taxe foncière
    mbles: 0, // Prix du mobilier
    regime: taxRegimes.DEFICIT_FONCIER_REEL, // Regime fiscal
    locAvantagesRentLevel: 2,
    rentalIntermediation: false,
    revenus: 18000,
    revenus2: 0,
    parts: 1,
    enfants: 0,
    apport: 0, // Apport
    duree: DEFAULT_LOAN_PERIOD, // Duree emprunt
    tauxemprunt: loanRates[DEFAULT_LOAN_PERIOD], // Taux d'emprunt
    assurance: 0.3, // Taux Assurance emprunt
    fraisdossier: 0, // Frais de dossier
    primes: 0, // Prime Anah
    creditlogement: 0, // Garantie credit logement
    agence: 8, // Frais agence
    fraisg: 0, // Frais gestion
    fraisrecherche: 0,
    assuranceli: 0, // Assurance loyers impayés
    tauxmois: 0, // Nbre de mois de vacances
    tauxan: 1, // Nbre années pour les vacances
    pno: PNO_WITH_INSURED,
    valorisation: 0.1, // Valorisation loyer
    valorisationc: 2, // Valorisation charges
    valorisations: 0.5, // Augmentation annuelle des revenus
    valorisationreventeprix: 1, // Augmentation annuelle de la valeur du bien (revente)
    emprunt: 'on',
    typeemprunt: 'amorti',
    emprunt110: '',
    fraisnotaire: '',
    tauxmi: 0, // Taux marginal imposition
    tauxps: 17.2, // Prelevements sociaux
    revente: 'on',
    reventeannee: 10,
    reventeprix: 0,
    agenceeuros: '',
  }
}

export const useSimulationStore = defineStore('app-simulation', {
  state: () => ({
    lastSimulation: null,
    inputs: {
      ...defaultInputs(),
    },
    unsavedSimulationParams: false,
    computesState: {
      mainCompute: false,
      computeRegimes: false,
      computeMortgageDuration: false,
    },
    report: null,
    regimes: null,
    mortgageDuration: null,
    isLoading: false,
    hasError: false,
    budget: 0,
    city: '',
    ads: [],
    reportByPurchasePrice: null,
    rentReport: null,
    rentOffers: [],
    purchaseOffers: [],
    maxRent: null,
  }),
  getters: {
    isReportLoaded: (state) => !!state.report,
    isMainComputeLoaded: (state) => !!state.computesState.mainCompute,
    isComputeRegimesLoaded: (state) => !!state.computesState.computeRegimes,
    isComputeMortgageDurationLoaded: (state) => !!state.computesState.computeMortgageDuration,
    hasMortgage: (state) =>
      !!state.report && !!state.report.hypothesis && state.report.hypothesis.hasMortgage,
    hasAds: (state) => state.ads.length > 0,
    hypothesis: (state) => state.report && state.report.hypothesis,
    byYears: (state) => state.report.byYears,
    detailedReport: (state) => state.report?.detailedReport,
    computedInputs: (state) => state.report?.computedInputs,
    detailedCashflow: (state) => state.report?.detailedReport?.cashflow,
    comparedRegimes: (state) => state.regimes && state.regimes.filter((regime) => regime.valid_for_compare),
    taxRegime() {
      return this.isReportLoaded && this.hypothesis.taxRegime
    },
    resell() {
      return this.isReportLoaded && this.hypothesis.resell
    },
    resellYear() {
      return this.isReportLoaded && this.resell ? this.hypothesis.resellYear : 10
    },
    formattedParameters: (state) => {
      const parameters = {}
      Object.keys(defaultInputs()).forEach((key) => {
        let value = state.inputs[key]

        const fieldsToIgnoreIfEmpty = ['fraisnotaire']
        const fieldsToIgnore = ['villevalue', 'code_postal']

        if (fieldsToIgnore.includes(key) || (fieldsToIgnoreIfEmpty.includes(key) && value === '')) {
          return
        }

        // Legacy api accept only ville field, code_postal and villevalue must be ignored
        if ('ville' === key) {
          // Ville format <city>|<postalCode> (ex: Bordeaux|33000)
          value = `${state.inputs.ville}|${state.inputs.code_postal}`
        }

        parameters[key] = value
      })

      return parameters
    },
    realEstatePurchaseOffers: (state) => state.purchaseOffers,
    realEstateRentOffers: (state) => state.rentOffers,
    rentDataWithVacancy: (state) => state.rentReport.dataWithVacancies,
    rentDataWithoutVacancy: (state) => state.rentReport.dataWithoutVacancies,
    cover: (state) => state.report?.pdf?.cover,
    favorite: (state) => {
      if (state.report) {
        const simulationId = state.report.initialReportId ?? state.report.id

        const simulationsHistoryStore = useSimulationsHistoryStore()
        let folderName = null
        let summary = simulationsHistoryStore.defaultFolder.find((s) => s.simulationId === simulationId)
        if (!summary) {
          for (let i = 0; i < simulationsHistoryStore.folders.length; i++) {
            for (let j = 0; j < simulationsHistoryStore.folders[i].simulations.length; j++) {
              if (simulationsHistoryStore.folders[i].simulations[j].simulationId === simulationId) {
                summary = simulationsHistoryStore.folders[i].simulations[j]
                break
              }
            }
            if (summary) {
              folderName = simulationsHistoryStore.folders[i].name
              break
            }
          }
        }

        if (summary) {
          return { folderName, summary }
        }
      }

      return null
    },
  },
  actions: {
    openAdvanceParametersDrawer(defaultTab = undefined) {
      useDrawerStore().addDrawer({
        id: 'drawer-advance-parameters-form',
        component: shallowRef(
          defineAsyncComponent(
            () => import('@/components/FutureInvestorDashboard/AdvanceParametersForm.vue'),
          ),
        ),
        props: {
          defaultTab,
        },
      })
    },
    openSimulatorParametersDrawer() {
      if (usePermissionsStore().canAccessSimParameters) {
        useDrawerStore().addDrawer({
          id: 'drawer-simulator-parameters',
          component: shallowRef(
            defineAsyncComponent(
              () => import('@/components/FutureInvestorDashboard/SimulatorDefaultParams.vue'),
            ),
          ),
        })
      } else {
        useUserProductStore().showUpgradeProDrawer(
          i18n.global.t('horiz.fin-report.hypothesis.form.parameters'),
        )
      }
    },
    openSimulatorDefaultValuesDrawer() {
      if (usePermissionsStore().canAccessSimDefaultParameters) {
        useDrawerStore().addDrawer({
          id: 'drawer-simulator-default-values',
          component: shallowRef(
            defineAsyncComponent(() => import('@/components/FutureInvestorDashboard/DefaultValuesForm.vue')),
          ),
        })
      } else {
        useUserProductStore().showUpgradeProDrawer(i18n.global.t('horiz.fin-report.hypothesis.form.default'))
      }
    },
    async getLastSimulation(reload = false) {
      if (reload || !this.lastSimulation) {
        this.lastSimulation = await simulationService.getLastSimulation()

        return this.lastSimulation
      } else {
        return Promise.resolve(this.lastSimulation)
      }
    },
    openNewSimulationDrawer() {
      if (usePermissionsStore().canCreateNewSimulation) {
        useDrawerStore().addDrawer({
          id: 'drawer-new-simulation',
          component: shallowRef(
            defineAsyncComponent(
              () => import('@/components/FutureInvestorDashboard/Drawer/DrawerNewSimulation.vue'),
            ),
          ),
          props: {
            defaultCity: this.report?.hypothesis
              ? {
                  value: this.inputs.ville,
                  label: `${this.inputs.ville} (${this.inputs.code_postal})`,
                  locality: this.inputs.ville,
                  postalCode: this.inputs.code_postal,
                  hasMultiplePostalCodes: null,
                }
              : null,
          },
        })
      } else {
        useUserProductStore().showUpgradeProDrawer(i18n.global.t('horiz.fin-report.rightBar.new'))
      }
    },
    async setDefaultRevenus(revenus) {
      await simulationService.setDefaultRevenus(parseInt(revenus, 10))
      await this.setUserDefaultParameters([])
    },
    async displayLoader() {
      this.isLoading = true
    },
    setEmprunt(value) {
      this.inputs.emprunt = value
    },
    async setUserDefaultParameters(parametersNamesToIgnore) {
      if (usePermissionsStore().canAccessSimDefaultParameters) {
        const defaultUserParameters = await legacyService.getDefaultSimulationParameters()
        const parameters = {}
        Object.keys(defaultUserParameters)
          .filter((key) => !parametersNamesToIgnore.includes(key) && defaultUserParameters[key] !== null)
          .forEach((key) => {
            parameters[key] = defaultUserParameters[key]
          })

        this.updateInputParameters(parameters)
      }
    },

    async initFromPlugin(parameters) {
      const parsedParameters = { ...parameters }

      parsedParameters.prix = parseInt(parsedParameters.prix)

      parsedParameters.code_postal =
        parsedParameters.codepostal.length > 5
          ? parsedParameters.codepostal.substr(parsedParameters.codepostal.length - 5)
          : parsedParameters.codepostal.length < 5
            ? parsedParameters.codepostal.padStart(5, '0')
            : parsedParameters.codepostal
      // Trim arrondissement (ex: 1975019 pour Paris 19)
      parsedParameters.ville = parsedParameters.ville.replace('e Arrondissement', '').trim().split('|')[0]

      delete parsedParameters.codepostal
      delete parsedParameters.villevalue

      if (parsedParameters.neuf && parsedParameters.neuf === '1') {
        parsedParameters.regime = taxRegimes.PINEL_9
        parsedParameters.neuf = rentingState.NEW
      } else {
        parsedParameters.regime = taxRegimes.DEFICIT_FONCIER_REEL
        parsedParameters.neuf = rentingState.OLD
      }

      await this.createReport(parsedParameters)
    },

    async createReport(inputParameters, isNew = false) {
      const defaultUserParameters = {}
      if (usePermissionsStore().canAccessSimDefaultParameters) {
        const defaultUserSimulationParameters = await legacyService.getDefaultSimulationParameters()
        Object.keys(defaultUserSimulationParameters)
          .filter((key) => defaultUserSimulationParameters[key] !== null)
          .forEach((key) => {
            defaultUserParameters[key] = defaultUserSimulationParameters[key]
          })
      }

      const dynamicParameters = await this.getDynamicParameters(
        {
          ...defaultInputs(),
          ...defaultUserParameters,
          ...inputParameters,
        },
        Object.keys({
          ...defaultUserParameters,
          ...inputParameters,
        }),
      )

      const finalParameters = {
        ...defaultInputs(),
        ...defaultUserParameters,
        ...inputParameters,
        ...dynamicParameters,
      }

      this.updateInputParameters(finalParameters)
      await this.compute({ isNew })
    },

    async getDynamicParameters(parameters, definedInputs) {
      const dynamicParameters = {}

      // Surface
      if (!definedInputs.includes('surface')) {
        const { prix, code_postal } = parameters
        const averageSurface = await statsService.averageSurface(code_postal, prix)
        dynamicParameters.surface = averageSurface ? averageSurface.surface : 30
      }

      // Loyer
      if (!definedInputs.includes('loyer')) {
        const { surface, code_postal } = { ...parameters, ...dynamicParameters }
        const averageRent = await statsService.getCityAverageRent(code_postal, surface)
        dynamicParameters.loyer = averageRent ? averageRent.average : 13 * surface
        dynamicParameters.loyerannuel = dynamicParameters.loyer * 12
      }

      // Charge totale
      if (!definedInputs.includes('chargest')) {
        const { surface } = { ...parameters, ...dynamicParameters }
        dynamicParameters.chargest = statsService.estimateTotalYearCharge(surface)
      }

      // Charge récupérable
      if (!definedInputs.includes('chargesr')) {
        const { chargest } = { ...parameters, ...dynamicParameters }
        dynamicParameters.chargesr = statsService.getMonthlyTenantCharge(chargest)
      }

      // Taxe foncière
      if (!definedInputs.includes('tf')) {
        const { surface } = { ...parameters, ...dynamicParameters }
        dynamicParameters.tf = statsService.getFiscalTax(surface)
      }

      // Frais de dossier
      if (!definedInputs.includes('fraisdossier')) {
        const { prix } = { ...parameters, ...dynamicParameters }
        dynamicParameters.fraisdossier = statsService.getBookingFees(prix)
      }

      // Apport et crédit logement
      if (!definedInputs.includes('apport') || !definedInputs.includes('creditlogement')) {
        const { prix, agence } = { ...parameters, ...dynamicParameters }
        const { notarialFees: fraisnotaire } = await legacyService.getNotarialFees(prix, agence, false)

        if (!definedInputs.includes('apport')) {
          dynamicParameters.apport = fraisnotaire
        }

        if (!definedInputs.includes('creditlogement')) {
          const { travauxr, apport, emprunt110, fraisdossier, mbles, regime } = {
            ...parameters,
            ...dynamicParameters,
          }
          const { homeLoan: creditlogement } = await legacyService.getHomeLoan(
            prix,
            agence,
            travauxr,
            apport,
            emprunt110,
            fraisdossier,
            mbles,
            fraisnotaire,
            0, // Prime Anah
            regime,
          )

          dynamicParameters.creditlogement = creditlogement
        }

        if (!definedInputs.includes('reventeprix') && parameters.revente === 'on') {
          const { prix, agence, travauxr, valorisationreventeprix, reventeannee } = {
            ...parameters,
            ...dynamicParameters,
          }

          const netVendeur = useSimulationService().getNetVendeur(prix, agence)

          dynamicParameters.reventeprix = useSimulationService().getResellPrice(
            netVendeur,
            travauxr,
            valorisationreventeprix,
            reventeannee,
          )
        }
      }

      return dynamicParameters
    },

    async initFromOnboarding(finOnboardingData) {
      const parsedParameters = {}
      const { prix, ville, code_postal, loyer, surface } = finOnboardingData

      parsedParameters.prix = prix
      parsedParameters.code_postal = code_postal
      parsedParameters.ville = ville
      if (surface) {
        parsedParameters.surface = surface
      }

      if (loyer) {
        parsedParameters.loyer = loyer
        parsedParameters.loyerannuel = loyer * 12
      }

      await this.createReport(parsedParameters, !useUserStore().isFreeUser)
    },
    async initFromUrlParameters(parameters, isNew = false) {
      const parsedParameters = { ...parameters }

      // The only way to pass city and postal city is ville field with format <city>|<postalCode>
      delete parsedParameters['villevalue']
      delete parsedParameters['code_postal']
      delete parsedParameters['codepostal']

      if (
        parsedParameters.ville &&
        parsedParameters.ville.length > 0 &&
        parsedParameters.ville.includes('|')
      ) {
        const [city, postalCode] = parsedParameters.ville.split('|')
        parsedParameters.ville = city.trim()
        parsedParameters.code_postal = postalCode.trim()
        if (parsedParameters.code_postal && parsedParameters.code_postal.length < 5) {
          parsedParameters.code_postal = parsedParameters.code_postal.padStart(5, '0')
        }
      }

      if (parsedParameters['revente'] === '1') {
        parsedParameters.revente = 'on'
      } else if (parsedParameters['revente'] === '0') {
        parsedParameters.revente = 'off'
      }

      if (!['true', 'false'].includes(parsedParameters['thermalRenovation'])) {
        delete parsedParameters['thermalRenovation']
      } else {
        parsedParameters['thermalRenovation'] = parsedParameters['thermalRenovation'] === 'true'
      }

      if (!['true', 'false'].includes(parsedParameters['rentalIntermediation'])) {
        delete parsedParameters['rentalIntermediation']
      } else {
        parsedParameters['rentalIntermediation'] = parsedParameters['rentalIntermediation'] === 'true'
      }

      if (!['1', '2', '3'].includes(parsedParameters['locAvantagesRentLevel'])) {
        delete parsedParameters['locAvantagesRentLevel']
      } else {
        parsedParameters['locAvantagesRentLevel'] = parseInt(parsedParameters['locAvantagesRentLevel'])
      }

      await this.createReport(parsedParameters, isNew && !useUserStore().isFreeUser)
    },
    async updateDefaultValues(parameters) {
      for (let params in parameters) {
        if (parameters[params] === null || parameters[params] === undefined) {
          switch (params) {
            case 'chargest':
              parameters[params] = statsService.estimateTotalYearCharge(parameters.surface)
              break
            case 'tf':
              parameters[params] = statsService.getFiscalTax(parameters.surface)
              break
            case 'fraisdossier':
              parameters[params] = statsService.getBookingFees(parameters.prix)
              break
            case 'regime':
              parameters[params] = taxRegimes.DEFICIT_FONCIER_REEL
              break
            case 'primes':
              parameters[params] = 0
              break
            default:
              parameters[params] = defaultInputs()[params]
              break
          }
        }
      }

      if (parameters['chargesr'] === null || parameters['chargesr'] === undefined) {
        parameters['chargesr'] = statsService.estimateTotalYearCharge(parameters.chargest)
      }

      if (parameters['apport'] === null || parameters['apport'] === undefined) {
        parameters['apport'] = parameters.fraisnotaire
      }

      if (parameters['fraisnotaire'] === null || parameters['fraisnotaire'] === undefined) {
        const { notarialFees: fraisnotaire } = await legacyService.getNotarialFees(
          parameters.prix,
          parameters.agence,
          false,
        )

        parameters['fraisnotaire'] = fraisnotaire
      }

      if (parameters['creditlogement'] === null || parameters['creditlogement'] === undefined) {
        const { homeLoan: creditlogement } = await legacyService.getHomeLoan(
          parameters.prix,
          parameters.agence,
          parameters.travauxr,
          parameters.apport,
          parameters.emprunt110,
          parameters.fraisdossier,
          parameters.mbles,
          parameters.fraisnotaire,
          parameters.primes,
          parameters.regime,
        )
        parameters['creditlogement'] = creditlogement
      }

      this.updateInputParameters(parameters)
    },
    updateParameters(parameters) {
      this.updateInputParameters(parameters)
    },
    setUnsavedFlag(flag) {
      this.unsavedSimulationParams = flag
    },
    resetInitialReportIdAttribute() {
      this.report.initialReportId = null
    },
    async compute(params) {
      const options = {
        report: null,
        isNew: false,
        isSameSimulation: false,
        ...params,
      }

      this.isLoading = true

      let report

      // Compute Regimes
      this.computesState.computeRegimes = false
      legacyService
        .computeRegimes(this.formattedParameters)
        .then((regimes) => {
          this.regimes = regimes
          this.computesState.computeRegimes = true
        })
        // eslint-disable-next-line no-console
        .catch((reason) => console.log('compute regimes error, reason: ', reason))

      // Compute Mortgage Duration
      this.computesState.computeMortgageDuration = false
      legacyService
        .getMortgageDuration(this.formattedParameters)
        .then((mortgageDuration) => {
          this.mortgageDuration = mortgageDuration
          this.computesState.computeMortgageDuration = true
        })
        // eslint-disable-next-line no-console
        .catch((reason) => console.log('get mortgage duration error, reason: ', reason))

      const authStore = useAuthStore()

      // Compute
      try {
        const simulationParams = { ...this.formattedParameters }
        if (authStore.isLoggedIn) {
          simulationParams.isNew = options.isNew

          if (!options.isNew && options.isSameSimulation) {
            simulationParams.simulationToken = this.report.simulationToken
          }
        }
        report = options?.report
          ? await Promise.resolve(options.report)
          : await legacyService.compute(simulationParams)
      } catch (e) {
        this.isLoading = false
        this.hasError = true

        return
      }

      // Update quotas
      if (authStore.isLoggedIn) {
        await usePermissionsStore().refreshQuotasAndPermissions()
      }

      if (options.isSameSimulation) {
        report.initialReportId = this.report.initialReportId ?? this.report.id
        this.setUnsavedFlag(true)
      } else {
        report.initialReportId = null
        this.setUnsavedFlag(false)
      }

      this.report = report
      this.computesState.mainCompute = true

      this.isLoading = false

      if (options?.notifySuccess) {
        useToastsStore().success(i18n.global.t('horiz.fin-report.popovers.calculationUpdated'))
      }

      if (report) {
        const { city, postalCode, rent, taxRegime, surface, purchasePrice } = report.hypothesis

        let fullNameTaxRegime = Object.entries(taxRegimes).find(
          (regime) => taxRegime.toLowerCase() === regime[1].toLowerCase(),
        )

        trackEventWithUtm('Rapport FIN Loaded', {
          Ville: city,
          Code_postal: postalCode,
          Prixfai: purchasePrice,
          Loyer: rent,
          Surface: surface,
          Regime: fullNameTaxRegime ? fullNameTaxRegime[0] : taxRegime,
          TravauxRenovation: report.rawInput.travauxr,
        })
      }
    },
    async computeFromFullReport(fullReport) {
      if (fullReport) {
        const { inputs, report, mortgageDuration, regimes, rent } = fullReport

        this.updateInputParameters(inputs)

        this.report = report
        this.computesState.mainCompute = true

        this.mortgageDuration = mortgageDuration
        this.computesState.computeMortgageDuration = true

        this.regimes = regimes
        this.computesState.computeRegimes = true

        this.rentReport = rent.rentData
        this.maxRent = rent.maxRent
      }
    },
    reset() {
      this.unsavedSimulationParams = false
      this.inputs = {
        ...defaultInputs(),
      }
      this.report = null
      this.isLoading = false
      this.budget = 0
      this.city = ''
      this.ads = []
    },
    async printPdfReport() {
      await downloadAndOpenPDFInNewTab({
        fileName: `Business Plan - ${this.inputs.type} ${this.inputs.surface}m2 ${this.inputs.ville}.pdf`,
        downloadHandler: async () =>
          simulationService.printPdfReport(this.formattedParameters, {
            reportName: this.favorite?.summary?.name,
            reportReference: this.favorite?.summary?.reference,
          }),
        errorMessage: 'Une erreur est survenue durant la génération du PDF',
      })
    },
    async printPptReport() {
      function forceFileDownload(response, title) {
        const url = window.URL.createObjectURL(response)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      }

      const docBuffer = await simulationService.printPptReport(this.report.id, this.favorite?.summary?.adId)
      const name = this.favorite?.summary?.name
        ? this.favorite.summary.name
            .trim()
            .replace(/[^A-Za-z0-9_.-]/g, '_')
            .substring(0, 240)
        : `Report - ${this.inputs.type} ${this.inputs.surface}m2 ${this.inputs.ville}`
      forceFileDownload(docBuffer, `${name}.pptx`)
    },
    async getRealEstateOffers(queryObject) {
      const offers = await legacyService.getRealEstateOffers(
        queryObject.postalCode,
        queryObject.city,
        queryObject.surface,
        queryObject.transaction,
      )
      if (queryObject.transaction === 'vente') {
        this.purchaseOffers = offers
      } else {
        this.rentOffers = offers
      }
    },
    async getYieldsByPurchasePrice() {
      this.reportByPurchasePrice = await legacyService.getYieldsByPurchasePrice(this.formattedParameters)
    },
    async getRentData() {
      this.rentReport = await legacyService.getRentData(this.formattedParameters)
    },
    async getMaxRent(queryObject) {
      const response = await legacyService.getMaxRent(
        queryObject.areaCode,
        queryObject.regime,
        queryObject.surface,
      )
      this.maxRent = response.maxRent
    },

    updateInputParameters(parameters) {
      const simulatorParameterNames = Object.keys(defaultInputs())
      Object.keys(parameters)
        .filter((key) => simulatorParameterNames.includes(key))
        .forEach((key) => {
          // Case with field that accept boolean values: true/false "true"/"false"
          if (['thermalRenovation', 'rentalIntermediation'].includes(key)) {
            this.inputs[key] =
              (typeof parameters[key] === 'string' && parameters[key] === 'true') ||
              (typeof parameters[key] === 'boolean' && parameters[key])
          }
          // Case with field that accept number values
          else if (
            ['locAvantagesRentLevel'].includes(key) &&
            parameters[key] !== null &&
            parameters[key] !== undefined &&
            !isNaN(parameters[key])
          ) {
            this.inputs[key] = parseInt(parameters[key])
          }
          // Other cases
          else {
            this.inputs[key] = parameters[key]
          }
        })
    },
    async initFromHistorySimulationId(simulationId) {
      const simulation = await simulationService.getSimulationById(simulationId)
      await this.initFromSimulationPayload(simulation)
    },
    async initFromSimulationPayload(simulation) {
      this.updateInputParameters(simulation.rawInput)
      await this.compute({
        report: simulation,
      })
    },
  },
})
