const fileValidator = (values, { size }) => {
  if (!values || !values.length) {
    return true
  }

  const files = values.filter((file) => file.isNew)

  for (const file of files) {
    const fileMb = file.file.size / 1024 ** 2
    if (fileMb >= size) {
      return false
    }
  }

  return true
}

export default fileValidator
