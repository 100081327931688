import { HOOKS } from '@/services/api'

const BASE_PATH = '/v1/settings-repository'

export const useSettingsRepositoryService = () => ({
  find: async () => {
    return await HOOKS.get(BASE_PATH)
  },
  update: async (id, value) => {
    return await HOOKS.patch(`${BASE_PATH}/${id}`, {
      value,
    })
  },
})
