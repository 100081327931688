<template>
  <uikit-drawer
    size="MEDIUM"
    @on-close="trackClose"
  >
    <template #header>
      <h2>{{ title }}</h2>
    </template>
    <div class="drawer-body">
      <div
        v-for="line in tableSections"
        :key="line.title || line.text"
        class="grid"
      >
        <h3
          v-if="line.title"
          class="section-title"
        >
          {{ line.title }}
        </h3>
        <div
          v-else
          class="dot-and-text"
        >
          <span class="text">{{ line.text }}</span>
          <uikit-dropdown v-if="line.tooltip">
            <uikit-tooltip-icon class="tooltip-icon" />
            <template #overlay>
              <div class="tooltip">
                <span v-html="line.tooltip" />
              </div>
            </template>
          </uikit-dropdown>
        </div>
        <span
          v-if="line.free !== 'x'"
          class="center text"
        >
          {{ line.free }}
        </span>
        <span
          v-else
          class="center text"
        >
          -
        </span>
        <span
          v-if="line.premiumButDifferent"
          class="lighter-purple purple center text"
        >
          {{ line.premiumButDifferent }}
        </span>
        <span
          v-else-if="line.premium !== 'v'"
          class="purple center text"
        >
          {{ line.premium }}
        </span>
        <span
          v-else
          class="center text purple"
        >
          <UikitTickIcon class="tick-icon" />
        </span>
      </div>
      <div
        v-if="!isLoggedIn"
        class="separator"
      />
      <div
        v-if="!isLoggedIn"
        class="footer"
      >
        <uikit-button
          type="link"
          @click="connect"
        >
          {{ t('DrawerSubscription.alreadyHaveAnAccount') }}
        </uikit-button>
      </div>
    </div>
    <template #footer>
      <div class="actions-container">
        <uikit-button
          class="cta"
          type="secondary"
          :loading="loading"
          @click="redirectStayFree"
        >
          {{ t('DrawerSubscription.stayFree') }}
        </uikit-button>
        <uikit-button
          class="cta"
          type="primary"
          :loading="loading"
          @click="redirectPaymentOrOffers"
        >
          <span>{{ t('DrawerSubscription.sub') }}</span>
        </uikit-button>
      </div>
    </template>
  </uikit-drawer>
</template>
<script setup>
import { BENEFIT_GLI, PNO_UNDER_110_SQUARE_METERS } from '@/models/simulation'
import { UikitDrawer, UikitDropdown, UikitTooltipIcon } from '@hz/ui-kit'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { UikitTickIcon, UikitButton } from '@hz/ui-kit'
import { keycloakService } from '@/services/keycloak.service'
import { useStore } from 'vuex'
import { useDrawerStore } from '@hz/ui-kit'
import { useRouter } from 'vue-router'
import { trackEvent } from '@/helpers'

const props = defineProps({
  isPin: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n({})
const store = useStore()
const drawerStore = useDrawerStore()
const router = useRouter()
const isLoggedIn = store.getters['auth/isLoggedIn']
const loading = ref(true)

const title = computed(() => {
  return props.isPin ? t('DrawerSubscription.title.pin') : t('DrawerSubscription.title.fin')
})

const tableSections = computed(() => {
  const array = [
    {
      title: t('DrawerSubscription.section.simulator'),
      free: props.isPin ? '' : 'Gratuit',
      premium: props.isPin ? '' : 'Premium',
    },
    { free: 'x', premium: 'v', text: t('DrawerSubscription.simulationNumber') },
    { free: 'x', premium: 'v', text: t('DrawerSubscription.unfurnishedRental') },
    { free: 'x', premium: 'v', text: t('DrawerSubscription.searchExclusiveAds') },
    { free: 'x', premium: 'v', text: t('DrawerSubscription.report') },
  ]

  const rentalManagement = [
    {
      title: t('DrawerSubscription.section.rentalManagement'),
      free: props.isPin ? 'Gratuit' : '',
      premium: props.isPin ? 'Premium' : '',
    },
    { free: 0, premium: t('DrawerSubscription.unlimited'), text: t('DrawerSubscription.realEstateNumber') },
    { free: 'x', premium: 'v', text: t('DrawerSubscription.management') },
    {
      free: 'x',
      premium: t('DrawerSubscription.oneFree'),
      text: t('DrawerSubscription.lease'),
      tooltip: t('DrawerSubscription.tooltipLease'),
    },
    {
      free: 'x',
      premium: 'v',
      text: t('DrawerSubscription.patrimoineReport'),
      tooltip: t('DrawerSubscription.tooltipReport'),
    },
    { free: 'x', premium: 'v', text: t('DrawerSubscription.taxesHelp') },
    {
      free: '',
      premiumButDifferent: t('DrawerSubscription.from'),
      title: t('DrawerSubscription.advantages'),
    },
    {
      free: 'x',
      premium: 'v',
      text: t('DrawerSubscription.support'),
    },
    {
      free: '79€/an',
      premium: '79€/an',
      text: t('DrawerSubscription.pno'),
      tooltip: t('DrawerSubscription.tooltipPNO', { price: PNO_UNDER_110_SQUARE_METERS }),
    },
    { free: '280€', premium: '280€', text: t('DrawerSubscription.expert') },
    {
      free: `${BENEFIT_GLI}%`,
      premium: `${BENEFIT_GLI}%`,
      text: t('DrawerSubscription.gli'),
      tooltip: t('DrawerSubscription.tooltipGLI', { percent: BENEFIT_GLI }),
    },
  ]

  if (props.isPin) array.unshift(...rentalManagement)
  else array.push(...rentalManagement)

  return array
})

// methods
const redirectPaymentOrOffers = () => {
  trackEvent('Click abonnement drawer subscription')
  if (!props.isPin && !isLoggedIn) {
    const redirectUri = window.location.origin + '/payment?id=horizPlusYearly99&behavior=register'
    keycloakService.register({
      redirectUri,
    })

    return
  }

  const subscriptionDetails = store.getters['payment/mySubscriptionDetails']
  if (subscriptionDetails?.subscription?.status === 'past_due') {
    router.push('/my-subscription/current')
    drawerStore.removeDrawer({ id: 'drawer-subscription' })

    return
  }

  router.push(
    `/payment?id=horizPlusYearly99&behavior=register&redirectURI=${encodeURIComponent(
      window.location.origin + window.location.pathname,
    )}`,
  )
  drawerStore.removeDrawer({ id: 'drawer-subscription' })
}

const redirectStayFree = () => {
  trackEvent('Continuer gratuitement')
  if (!isLoggedIn) {
    keycloakService.register({
      redirectUri: new URL(window.location.href).toString(),
    })
  }
  drawerStore.removeDrawer({ id: 'drawer-subscription' })
}

const trackClose = () => {
  trackEvent('Abandon drawer subscription')
}

onBeforeMount(async () => {
  if (isLoggedIn) {
    await store.dispatch('payment/getSubscriptionInfo')
  }
  loading.value = false
})

const connect = keycloakService.loginAndGetBack
</script>

<style scoped lang="less">
:deep(.drawer-body) {
  padding-top: 0;
}

.tooltip {
  padding: 20px;
}

.tooltip-icon {
  color: var(--ds-color-primary-100);
  margin-left: 8px;
}

.drawer-body {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--ds-color-gray-10);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 12px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 67px 67px;
  min-height: 40px;
}

.purple {
  background: rgba(116, 66, 236, 0.1); // merci eliot <3
  color: var(--ds-color-primary-100);
  font-weight: var(--ds-weight-semi-bold);
}

.text.lighter-purple {
  color: var(--ds-color-primary-100);
  font-size: 11px;
}

.section-title {
  color: var(--ds-color-primary-100);
  margin: 10px 0 0 0;
  padding: 10px 0 20px 0;
  font-size: 14px;
}

div.grid:first-child {
  & > span {
    border-radius: 10px 10px 0 0;
  }
}

div.grid:nth-child(14) {
  & > span {
    border-radius: 0 0 10px 10px;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-and-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  font-size: 12px;
}

.tick-icon {
  width: 8px;
  height: 8px;
}

.actions-container {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 12px;
  width: 100%;

  .cta {
    margin-bottom: 8px;
  }
}

.separator {
  height: 1px;
  border-bottom: 1px solid var(--ds-color-gray-10);
  margin-top: 24px;
  margin-bottom: 20px;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media @bp-lg {
  .grid {
    grid-template-columns: 1fr 121px 121px;
    min-height: 40px;
  }

  .section-title {
    margin: 10px 0 0 0;
    padding: 10px 20px 20px 0;
    font-size: 18px;
  }

  .text {
    font-size: 16px;
  }

  .text.lighter-purple {
    font-size: 16px;
  }

  .tick-icon {
    width: 16px;
    height: 16px;
  }

  .actions-container {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    width: 100%;
    justify-content: space-between;
    padding: 8px 0 12px 0;

    .cta {
      width: 48%;
      margin-bottom: 0;
    }
  }

  .separator {
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
</style>
