import { Layouts } from '@/layouts'
import checkPermissions from '@/router/middleware/checkPermissions'

const meta = {
  layout: Layouts.DEFAULT,
  requireAuth: true,
  middlewares: [
    checkPermissions({
      canManageMyOrganization: true,
    }),
  ],
}

const routes = [
  {
    name: 'hz-my-organization',
    path: '',
    component: () => import('@/views/Organizations/OrganizationsView.vue'),
  },
  {
    name: 'hz-my-organization-members',
    path: 'members',
    component: () => import('@/views/Organizations/OrganizationMembersView.vue'),
  },
  {
    name: 'hz-my-organization-fallback',
    path: ':pathMatch(.*)*',
    redirect: () => {
      return { path: '/my-organization' }
    },
  },
]

const myOrganizationRoutes = [
  {
    name: 'hz-my-organization-root',
    path: '/my-organization',
    meta,
    children: routes,
  },
]

export default myOrganizationRoutes
