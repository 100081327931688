import { Layouts } from '@/layouts'
import { Permissions } from '@/helpers'
import checkPermission from '@/router/middleware/checkPermission'
import {
  adminAgenciesRoute,
  adminCustomerDeleteRequestsRoute,
  adminCustomersRoute,
  adminNotificationTemplatesRoute,
  adminPaymentProductsRoute,
  adminRealEstateAdDetailsRoute,
  adminRealEstateAdsRoute,
} from '@/router/routes/contants'

const meta = {
  layout: Layouts.DEFAULT,
  requireAuth: true,
  permissions: [Permissions.ADMIN],
  middlewares: [checkPermission],
  spontaneousInteractionAllowed: false,
}

const routes = [
  {
    name: 'hz-admin-index',
    path: '',
    component: () => import('@/views/Admin/AdminView.vue'),
  },

  {
    name: 'hz-admin-organizations',
    path: 'organizations',
    component: () => import('@/views/Organizations/OrganizationsView.vue'),
    props: { adminMode: true },
  },
  {
    name: 'hz-admin-organization-members',
    path: 'organizations/:id/members',
    component: () => import('@/views/Organizations/OrganizationMembersView.vue'),
    props: { adminMode: true },
  },
  {
    name: adminRealEstateAdsRoute.name,
    path: 'real-estate-ads',
    component: () => import('@/views/Admin/AdminRealEstateAdsView.vue'),
  },
  {
    name: adminAgenciesRoute.name,
    path: 'agencies',
    component: () => import('@/views/Admin/AdminAgenciesView.vue'),
  },
  {
    name: adminCustomersRoute.name,
    path: 'customers',
    component: () => import('@/views/Admin/CustomersView.vue'),
  },
  {
    name: adminCustomerDeleteRequestsRoute.name,
    path: 'customer-delete-requests',
    component: () => import('@/views/Admin/CustomersDeleteRequestView.vue'),
  },
  {
    name: adminPaymentProductsRoute.name,
    path: 'payment-products',
    component: () => import('@/views/Admin/PaymentProductsView.vue'),
  },
  {
    name: adminRealEstateAdDetailsRoute.name,
    path: 'real-estate-ads/edit/:id',
    component: () => import('@/views/RealEstateAds/RealEstateAdsForm.vue'),
    props: { adminMode: true },
  },
  {
    name: 'hz-admin-ads-ingestion-configurations',
    path: 'ads-ingestion-configs',
    component: () => import('@/views/Admin/AdminAdsIngestionConfigurationsView.vue'),
  },
  {
    name: 'hz-admin-settings-repository',
    path: 'settings-repository',
    component: () => import('@/views/Admin/AdminSettingsRepositoryView.vue'),
  },
  {
    name: adminNotificationTemplatesRoute.name,
    path: 'notification-templates',
    component: () => import('@/views/Admin/AdminNotificationTemplatesView.vue'),
  },
  {
    name: 'hz-admin-fallback',
    path: ':pathMatch(.*)*',
    redirect: () => {
      return { path: '/admin' }
    },
  },
]

const adminRoutes = [
  {
    name: 'hz-admin-root',
    path: '/admin',
    meta,
    children: routes,
  },
]

export default adminRoutes
