import { createGtm } from '@gtm-support/vue-gtm'

const GtmPlugin = {
  install: (app, options) => {
    const { url, containerId, isEnabled, router } = options

    if (!isEnabled) return false

    app.use(
      createGtm({
        source: `${url}/attacus-atlas.js`,
        id: containerId,
        defer: true,
        vueRouter: router,
      }),
    )
  },
}

export default GtmPlugin
