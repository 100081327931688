import basicFrenchDateValidator from '@/helpers/validation/rules/basic-fr-date'
import beforeTodayValidator from '@/helpers/validation/rules/before-today'
import { defineRule } from 'vee-validate'
import virtualTourPartnerValidator from '@/helpers/validation/rules/virtual-tour-partner'
import withoutUrlValidator from '@/helpers/validation/rules/without-url'
import withoutEmailValidator from '@/helpers/validation/rules/without-email'
import withoutPhoneNumberValidator from '@/helpers/validation/rules/without-phone-number'
import fileValidator from '@/helpers/validation/rules/file'
import imageFileValidator from '@/helpers/validation/rules/imageFileValidator'
import phoneNumberValidator from '@/helpers/validation/rules/phone-number'

export function extendDefaultValidators() {
  defineRule('beforeToday', beforeTodayValidator)
  defineRule('basic-fr-date', basicFrenchDateValidator)
  defineRule('virtualTourPartner', virtualTourPartnerValidator)
  defineRule('withoutUrl', withoutUrlValidator)
  defineRule('withoutEmail', withoutEmailValidator)
  defineRule('withoutPhoneNumber', withoutPhoneNumberValidator)
  defineRule('file', fileValidator)
  defineRule('imageFile', imageFileValidator)
  defineRule('phoneNumber', phoneNumberValidator)
  defineRule('maxLength', (value, [limit]) => {
    if (!value || !value.length) {
      return true
    }
    if (value.length > limit) {
      return `Ce champ est limité à ${limit} cractères`
    }

    return true
  })
}
