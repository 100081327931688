import { computed, markRaw, ref } from 'vue'

import { useArrayDatasource } from '@hz/ui-kit'

export class RemoteDataSource {
  // Data and metas
  data = ref([])
  total = ref(0)
  pages = ref(0)

  // Query
  sort = ref(null)
  globalTextSearchFilter = ref(null)
  globalFilters = ref(null)
  filters = ref(null)

  // Pagination
  pageSize = ref(10)
  page = ref(1)

  remoteFetchCallback = async () => []

  constructor(remoteFetchCallback) {
    this.remoteFetchCallback = remoteFetchCallback
  }

  setPageSize(pageSize) {
    this.pageSize.value = pageSize
  }

  setPage(page) {
    this.page.value = page
  }

  getDataAndMetas() {
    return {
      data: computed(() => this.data.value),
      total: computed(() => this.total.value),
      pages: computed(() => this.pages.value),
    }
  }

  reset() {
    this.data.value = []
    this.total.value = 0
    this.pages.value = 0
    this.sort.value = null
    this.globalTextSearchFilter.value = null
    this.globalFilters.value = null
    this.pageSize.value = 10
    this.page.value = 1
  }

  async load() {
    const params = {
      skip: (this.page.value - 1) * this.pageSize.value,
      limit: this.pageSize.value,
    }
    if (this.sort.value) {
      params.sort = this.sort.value
    }

    const filters = {
      ...(this.globalFilters.value ?? {}),
      ...(this.filters.value ?? {}),
    }

    if (this.globalTextSearchFilter.value && this.globalTextSearchFilter.value.length) {
      filters['$or'] = this.globalTextSearchFilter.value.map((exp) => ({
        [exp.key]: { $regex: exp.value, $options: 'i' },
      }))
    }

    if (Object.keys(filters).length) {
      params['filter'] = JSON.stringify(filters)
    }

    const response = await this.remoteFetchCallback(params)
    this.data.value = response.data
    this.total.value = response.metas.total
    this.pages.value = response.metas.pageCount
  }

  sortBy(attribute, direction) {
    this.sort.value = attribute ? `${direction === 'asc' ? '+' : '-'}${attribute}` : null
  }

  search(filterExpression) {
    this.globalTextSearchFilter.value = filterExpression && filterExpression.length ? filterExpression : null
  }

  setGlobalFilters(filters) {
    this.globalFilters.value = filters
  }

  clearGlobalFilters() {
    this.globalFilters.value = null
  }

  setFilters(filters) {
    this.filters.value = filters
  }

  clearFilters() {
    this.filters.value = null
  }
}

export const useRemoteDatasource = (remoteFetchCallback) => {
  return new RemoteDataSource(remoteFetchCallback)
}

export const useRemoteDatasourceInStore = (remoteFetchCallback) =>
  markRaw(useRemoteDatasource(remoteFetchCallback))

export const useArrayDatasourceInStore = (values) => markRaw(useArrayDatasource(values))
