import { Layouts } from '@/layouts'
import { useUserProfileStore } from '@/stores/userProfile'
import { useProprietaryIdentitiesStore } from '@/stores/proprietaryIdentities'

const meta = {
  layout: Layouts.DEFAULT,
  requireAuth: true,
  spontaneousInteractionAllowed: false,
}

const routes = [
  {
    name: 'hz-profile',
    path: '',
    component: null,
    beforeEnter() {
      useUserProfileStore().openUserProfileDrawer()
    },
  },
  {
    name: 'hz-user-privacy',
    path: 'privacy',
    component: null,
    beforeEnter() {
      useUserProfileStore().openPrivacyDrawer()
    },
  },
  {
    name: 'hz-user-proprietary-identities',
    path: 'proprietary-identities',
    component: null,
    beforeEnter() {
      useProprietaryIdentitiesStore().openProprietaryIdentitiesListDrawer()
    },
  },
  {
    name: 'hz-profile-api-keys',
    path: 'api-keys',
    component: null,
    beforeEnter() {
      useUserProfileStore().openApiKeyDrawer()
    },
  },
  {
    name: 'hz-profile-fallback',
    path: ':pathMatch(.*)*',
    redirect: () => {
      return { path: '/profile' }
    },
  },
]

const profileRoutes = [
  {
    name: 'hz-profile-root',
    path: '/profile',
    meta,
    children: routes,
  },
]

export default profileRoutes
