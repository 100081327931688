import i18n from '@/i18n'
import { format } from '@/locales/dateFormat'
import { isValid, differenceInDays, isSameDay, isSameYear, isYesterday } from 'date-fns'

export default (date) => {
  if (new Date(date) === 'Invalid Date') return date
  const toTest = new Date(date)
  if (!isValid(toTest)) return date

  const today = new Date()
  const diff = differenceInDays(today, toTest)

  if (!isSameYear(today, toTest)) return format(toTest, 'd LLLL yyyy')
  if (diff > 21) return format(toTest, 'd LLLL')
  if (isSameDay(today, toTest)) return i18n.global.t('today')
  if (isYesterday(toTest)) return i18n.global.t('yesterday')
  if (diff <= 21) return format(toTest, 'EEEE d LLLL')

  return format(toTest, 'd LLLL yyyy')
}
