import { config } from '@/core/config'
import { API } from '@/services/api'
import store from '@/store'
import { redirect, trackEvent, useUrls } from '@/helpers'
import { useDrawerStore } from '@hz/ui-kit'
import { useAppNotificationsStore } from '@/stores/appNotifications'
import { realEstateAdsListRoute } from '@/router/routes/contants'
import router from '@/router'

const API_BASE_URL = '/notifications'

const DEFAULT_NOTIFICATIONS = []

const finPluginNotificationForNotConnectedUser = {
  id: '2',
  type: ['FIN'],
  action: 'REDIRECT_PRESENT_PLUGIN',
  clicked: false,
  message: 'Afficher instantanément le rendement sur chaque annonce consultée. Cliquer pour en savoir plus.',
  seen: false,
  label: 'NONE',
  messageKey: 'PRESENT_PLUGIN',
  user_id: '1',
  createdAt: new Date(Date.now() - 20000),
}

export function appendFinPluginNotificationForNotConnectedUser() {
  const notificationsStore = useAppNotificationsStore()
  if (
    !store.getters['auth/isLoggedIn'] &&
    !DEFAULT_NOTIFICATIONS.includes(finPluginNotificationForNotConnectedUser)
  ) {
    DEFAULT_NOTIFICATIONS.unshift(finPluginNotificationForNotConnectedUser)
    notificationsStore.setNotificationCounter(DEFAULT_NOTIFICATIONS.length)
  }
}

function getUnSeenCount() {
  return store.getters['auth/isLoggedIn']
    ? API.get(`${API_BASE_URL}/unseen-count`)
    : Promise.resolve(DEFAULT_NOTIFICATIONS.length)
}

async function handleNotificationClick(notification) {
  let properties = { contenu: notification.message }
  if (notification.action !== 'NONE') properties.destination = notification.action
  trackEvent('Clic notification', properties)

  // legacy
  if (notification.action) {
    switch (notification.action) {
      case 'REDIRECT_PRESENT_PLUGIN':
        window.open(`${config.urls.rl}/plugin?auto`, '_blank')
        break
      case 'REDIRECT_HORIZ_OFFERS':
        window.open(`${config.urls.home}/offres`, '_self')
        break
      case 'REDIRECT_TENANT_ACCOUNTING':
        redirect(notification.redirect)
        break
      case 'REDIRECT_USER_BANK':
        redirect('/bank-synchronization')
        break
      case 'REDIRECT_TENANT_EDIT':
        redirect(`/tenant-edit/${notification.payload.building_id}/${notification.payload.tenant_id}/email`)
        break
      case 'REDIRECT_SUBSCRIPTION_SITUATION':
        redirect('/my-subscription/current')
        break
      case 'REDIRECT_PAYMENT_METHOD':
        redirect('/my-subscription/payment-method')
        break
      case 'REDIRECT_SUBSCRIPTION_UPDATE':
        redirect('/my-subscription/update')
        break
      case 'REDIRECT_REAL_ESTATE_EXPIRED_OFFERS':
        await router.push({ name: realEstateAdsListRoute.name, query: { tab: 'expired' } })
        break
      case 'REDIRECT_REAL_ESTATE_OFFER_SEARCH':
        if (notification.payload.searchId) {
          window.open(useUrls().home.searchById(notification.payload.searchId), '_blank')
        } else {
          window.open(useUrls().home.ad(notification.payload.realEstateOfferId), '_blank') // Obsolete since 2024-08-28, keep it one year
        }
        break
      case 'REDIRECT_EXPERT_AVAILABLE':
        window.open(
          'https://citruce.typeform.com/to/GeTb7wzN#source=apphoriz&medium=notif&campaign=courtier',
          '_blank',
        )
        break
      case 'REDIRECT_ALERT_APP':
        window.open(
          'https://investissement-locatif.horiz.io/application-mobile-rentabilite-locative?utm_source=apphoriz&utm_medium=notif&utm_campaign=application-mobile',
          '_blank',
        )
        break
      default:
        break
    }
  } else if (notification.redirect) {
    redirect(notification.redirect, false, notification.redirect.includes('blank=true'))
  }

  const drawerStore = useDrawerStore()
  drawerStore.removeDrawer({ id: 'drawer-notifications-menu' })

  if (store.getters['auth/isLoggedIn'] && !notification.clicked) {
    return API.post(`${API_BASE_URL}/set-clicked`, { id: notification.id })
  }
}

function handleOpenNotificationCenterClick() {
  if (store.getters['auth/isLoggedIn']) return API.get(`${API_BASE_URL}/set-all-seen`)
}

function getNotifications() {
  return store.getters['auth/isLoggedIn']
    ? API.get(`${API_BASE_URL}`)
    : Promise.resolve(DEFAULT_NOTIFICATIONS)
}

function triggerDPELeaseNotification() {
  return API.get(`${API_BASE_URL}/add-dpe-lease-notification`)
}

function triggerDecorationNotification() {
  return API.get(`${API_BASE_URL}/add-decoration-notification`)
}

export const notificationHandlerService = {
  getNotifications,
  getUnSeenCount,
  handleNotificationClick,
  handleOpenNotificationCenterClick,
  triggerDPELeaseNotification,
  triggerDecorationNotification,
}
