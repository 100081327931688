import { getTranslateProps } from '@tolgee/web'

export const CustomVueTolgee = {
  install(app, options) {
    const tolgee = options?.tolgee

    if (!tolgee) {
      throw new Error('Tolgee instance not passed in options')
    }

    app.mixin({
      beforeCreate() {
        this.$options.__keySubscription = tolgee.onNsUpdate(() => {
          this.$forceUpdate()
        })
      },
      unmounted() {
        this.$options.__keySubscription.unsubscribe()
      },
      methods: {
        // @ts-ignore
        $_t(...props) {
          // @ts-ignore
          const params = getTranslateProps(...props)
          const { ns } = params
          this.$options.__keySubscription.subscribeNs(ns)

          return tolgee.t(params)
        },
      },
    })
    app.config.globalProperties.$tolgee = tolgee
  },
}
