import { HOOKS, catch404ToNull, removePatchFields } from '@/services/api'

const BASE_PATH = '/v1/organizations'

export function replaceEmptyByNull(permissionsToClean, fields) {
  const permissions = {
    ...permissionsToClean,
  }
  fields.forEach((field) => {
    if (permissions[field] === '') {
      permissions[field] = null
    }
  })

  return permissions
}

export function castStringNumber(permissionsToClean, fields) {
  const permissions = {
    ...permissionsToClean,
  }
  fields.forEach((field) => {
    if (permissions[field] !== null) {
      permissions[field] = parseInt(permissions[field])
    }
  })

  return permissions
}

export const useOrganizationsService = () => ({
  // Organization
  findOrganization: async (params) => {
    return await HOOKS.get(BASE_PATH, {
      params,
    })
  },
  create: async (organization) => {
    return await HOOKS.post(BASE_PATH, organization)
  },
  get: async (organizationId) => {
    return await HOOKS.get(`${BASE_PATH}/${organizationId}`)
  },
  update: async (organizationId, organization) => {
    return await HOOKS.patch(`${BASE_PATH}/${organizationId}`, removePatchFields(organization))
  },
  delete: async (organizationId) => {
    return await HOOKS.delete(`${BASE_PATH}/${organizationId}`)
  },

  // Organization Permissions
  getPermissions: async (organizationId) => {
    return await HOOKS.get(`${BASE_PATH}/${organizationId}/permissions`)
  },
  updatePermissions: async (organizationId, organizationPermissions) => {
    let permissions = replaceEmptyByNull(organizationPermissions, [
      'savedCalculations',
      'newCalculationsPerMonth',
      'realEstateAdTokensPerMonth',
    ])

    permissions = castStringNumber(permissions, [
      'savedCalculations',
      'newCalculationsPerMonth',
      'realEstateAdTokensPerMonth',
    ])

    return await HOOKS.put(`${BASE_PATH}/${organizationId}/permissions`, permissions)
  },

  // Organization Invitations
  findInvitations: async (organizationId, params) => {
    return await HOOKS.get(`${BASE_PATH}/${organizationId}/invitations`, {
      params,
    })
  },
  createInvitation: async (organizationId, invitations) => {
    return await HOOKS.post(`${BASE_PATH}/${organizationId}/invitations`, invitations)
  },
  updateInvitation: async (organizationId, invitationId, partialInvitation) => {
    return await HOOKS.patch(`${BASE_PATH}/${organizationId}/invitations/${invitationId}`, partialInvitation)
  },
  deleteInvitation: async (organizationId, invitationId) => {
    return await HOOKS.delete(`${BASE_PATH}/${organizationId}/invitations/${invitationId}`)
  },

  // Organization members
  findMembers: async (organizationId) => {
    return await HOOKS.get(`${BASE_PATH}/${organizationId}/members`)
  },
  updateMember: async (organizationId, memberId, partialMember) => {
    return await HOOKS.patch(`${BASE_PATH}/${organizationId}/members/${memberId}`, partialMember)
  },
  deleteMember: async (organizationId, invitationId) => {
    return await HOOKS.delete(`${BASE_PATH}/${organizationId}/members/${invitationId}`)
  },
  leave: async (organizationId) => {
    return await HOOKS.delete(`${BASE_PATH}/${organizationId}/members/me`)
  },

  // Invitation
  getMyInvitation: async () => {
    return await HOOKS.get(`${BASE_PATH}/my-invitation`).catch(catch404ToNull)
  },
  acceptMyInvitation: async (invitationId) => {
    return await HOOKS.post(`${BASE_PATH}/my-invitation/accept/${invitationId}`, {})
  },

  // Subscription
  createSubscription: async (organizationId, subscription) => {
    return await HOOKS.post(`${BASE_PATH}/${organizationId}/subscription`, subscription)
  },
  cancelSubscription: async (organizationId) => {
    return await HOOKS.delete(`${BASE_PATH}/${organizationId}/subscription`)
  },
  getSubscriptionInvoice: async (paymentId) => {
    return await HOOKS.get(`${BASE_PATH}/subscription/invoice/${paymentId}`)
  },
  sendPaymentEmail: async (organizationId) => {
    return await HOOKS.get(`${BASE_PATH}/${organizationId}/subscription/email-payment-link`)
  },
  getSubscriptionPaymentLink: async (organizationId) => {
    return await HOOKS.get(`${BASE_PATH}/${organizationId}/subscription/payment-link`)
  },
  createAccessToCustomerPortal: async (organizationId, originUrl) => {
    return await HOOKS.post(`${BASE_PATH}/${organizationId}/customer-portal-access`, {
      originUrl,
    })
  },
})
