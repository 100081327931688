const middlewarePipeline = (context, middlewares, index) => {
  if (!Array.isArray(middlewares)) {
    throw new Error('You must specify an array of middleware')
  }

  const nextMiddleware = middlewares[index]

  if (!nextMiddleware) {
    return () => {
      return true
    }
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middlewares, index + 1)

    return nextMiddleware({ ...context, next: nextPipeline })
  }
}

export default middlewarePipeline
