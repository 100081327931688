const RESOURCES = {
  FINANCIAL: 'financial',
  LEGACY: 'legacy',
}
const SCOPES = { VIEW: 'view' }

const Permissions = Object.freeze({
  FINANCIAL_VIEW: {
    id: RESOURCES.FINANCIAL,
    scopes: [SCOPES.VIEW],
  },
  LEGACY: {
    id: RESOURCES.LEGACY,
    scopes: [SCOPES.VIEW],
  },
  ADMIN: {
    id: 'app-admin',
    scopes: ['access'],
  },
})

export default Permissions
