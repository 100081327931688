import { API, HOOKS } from '@/services/api'

function getAccountAccess() {
  return HOOKS.get('/v1/synchro/accountAccess')
}

function getBankAccounts() {
  return HOOKS.get('/v1/synchro/accounts')
}

function getTransactions(params) {
  const url = params ? `/v1/synchro/transactions?${params}` : '/v1/synchro/transactions'

  return HOOKS.get(url)
}

function unlinkBuilding(buildingId, accountId) {
  return HOOKS.post('/v1/synchro/unlinkBuilding', { buildingId, accountId })
}

function linkBuildings(buildingIds, accountId) {
  return HOOKS.post('/v1/synchro/linkBuildings', { buildingIds, accountId })
}

function deleteAccount(accountId, userId) {
  return API.post('/banking/deleteAccount', { accountId, userId })
}

function getSettingsUrl() {
  return HOOKS.get('/v1/synchro/settingsUrl')
}

function unlinkOperation(operationId) {
  return HOOKS.post(`/v1/operations/unlink/${operationId}`, {})
}

function linkOperation(operationId, transactionId) {
  return HOOKS.post(`/v1/operations/link/${operationId}`, { transactionId })
}

function redirectBudgea() {
  return HOOKS.post('/v1/budgetInsight/redirect', {})
}

export const synchroService = {
  getAccountAccess,
  getBankAccounts,
  unlinkBuilding,
  linkBuildings,
  deleteAccount,
  getTransactions,
  unlinkOperation,
  getSettingsUrl,
  linkOperation,
  redirectBudgea,
}
