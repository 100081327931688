import { defineStore } from 'pinia'

const sessionStorageStartAuthDateKey = 'start-auth-date'

function getNowTimeStamp() {
  return Math.floor(Date.now() / 1000)
}

/*
 * App Auth store mission is to manage authentication state/actions (aka kc)
 */
export const useAuthStore = defineStore('app-auth', {
  state: () => ({
    token: null,
    isLoggedIn: false,
  }),
  actions: {
    getKeycloakInstance() {
      return window.keycloak
    },
    async register() {
      const url = new URL(window.location.href)
      sessionStorage.setItem(sessionStorageStartAuthDateKey, getNowTimeStamp().toString())
      await this.getKeycloakInstance().login({
        action: 'register',
        redirectUri: url.toString(),
      })
    },
    async login() {
      sessionStorage.setItem(sessionStorageStartAuthDateKey, getNowTimeStamp().toString())
      await this.getKeycloakInstance().login()
    },
    async logout() {
      sessionStorage.removeItem(sessionStorageStartAuthDateKey)
      await this.getKeycloakInstance().logout()
    },
    setToken(token) {
      this.token = token
    },
    setIsLoggedIn() {
      this.isLoggedIn = true
    },
    async forceRefreshToken() {
      await this.getKeycloakInstance().updateToken(-1)
    },
  },
})
