import { config } from '@/core/config'
import router from '@/router'
import mixpanel, { setIdentity } from './providers/mixpane'

const trackingIsEnable = config.features.tracking

const UTM_PARAMETERS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export function trackEvent(eventName, properties, callback) {
  setIdentity()
  mixpanel.track(eventName, properties, callback)
}

export function trackEventWithUtm(eventName, properties, callback) {
  trackEvent(eventName, { ...properties, ...extractUtmParams(router.currentRoute.value.query) }, callback)
}

export function trackGA4Event(name, options = {}) {
  if (!trackingIsEnable) return

  try {
    window.dataLayer?.push({
      event: name,
      ...options,
    })
  } catch (_) {
    // eslint-disable-next-line no-empty
  }
}

export function trackHotjarEvent(name) {
  if (!trackingIsEnable) return

  if (window.hj) {
    window.hj('event', name)
  }
}

function extractUtmParams(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => UTM_PARAMETERS.includes(key.toLowerCase()) && value),
  )
}
