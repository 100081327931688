import { defineAsyncComponent, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import { useArrayDatasourceInStore } from '@/helpers/datasources'
import { usePermissionsStore } from '@/stores/permissions'
import { useDrawerStore } from '@hz/ui-kit'
import { useSettingsRepositoryService } from '@/services/settingsRepository.service'

export const SettingRepositoryItemName = {
  WearRateUnder10: 'wearRate.under10',
  WearRate10To20: 'wearRate.10To20',
  WearRateOver20: 'wearRate.over20',
  LoanRatesBaseKey: 'loanRate.duration_',
}

/*
 * App Settings Repository store mission is to manage platform settings
 */
export const useSettingsRepositoryStore = defineStore('app-settings-repository', {
  state: () => ({
    dataSource: useArrayDatasourceInStore([]),
    settings: [],
  }),
  actions: {
    async load() {
      const settings = await useSettingsRepositoryService().find()
      this.settings = settings
      this.dataSource.setData(settings)
      this.dataSource.load()
    },

    openEditDrawer(setting) {
      if (usePermissionsStore().canManageSettingsRepository) {
        useDrawerStore().addDrawer({
          id: 'drawer-setting-repository-details',
          component: shallowRef(
            defineAsyncComponent(() =>
              import('@/components/Admin/SettingsReporitory/DrawerSettingRepositoryDetails.vue'),
            ),
          ),
          props: {
            setting,
          },
        })
      }
    },

    async updateValue(id, value) {
      if (usePermissionsStore().canManageSettingsRepository) {
        await useSettingsRepositoryService().update(id, value)
        await this.load()
      }
    },

    getValue(key) {
      const setting = this.settings.find((setting) => setting.name === key)
      if (!setting) {
        throw new Error(`Setting not found with ${key}`)
      }

      return setting.value
    },

    getLoanRates() {
      const settings = this.settings.filter((setting) =>
        setting.name.startsWith(SettingRepositoryItemName.LoanRatesBaseKey),
      )

      const rates = {}

      settings.forEach((setting) => {
        const duration = parseInt(setting.name.replace(SettingRepositoryItemName.LoanRatesBaseKey, ''))
        rates[duration] = setting.value
      })

      return rates
    },
  },
})
