import { defineAsyncComponent } from 'vue'

import { config, useConfig } from '@/core/config'
import router from '@/router'
import store from '@/store'
import appendSlug from './appendSlug'
import { allDocumentRoute } from '@/router/routes/contants'

export const URLS = {
  resources: {
    proOffers:
      'https://horiz.io/ressources/logiciel-agents-immobiliers?utm_source=horiz&utm_medium=drawer&utm_campaign=pro',
  },
}

export const useUrls = () => {
  const config = useConfig()

  return {
    ressources: {
      proOffers: URLS.resources.proOffers,
    },
    legacy: {
      myPurchases: `${config.urls.rl}${LEGACY_URLS.RL_EXPERTS_PURCHASES}`,
      exportMyData: `${config.urls.rl}/user/exportUserData`,
      admin: `${config.urls.rl}/admin`,
    },
    app: {
      payment: (legacyId) => `${config.urls.app}/payment?id=${legacyId}`,
      mySubscriptionUpdate: `${config.urls.app}/my-subscription/update`,
    },
    home: {
      offers: () => {
        return `${config.urls.home}/offres`
      },
      tubV2: (parameters = {}) => {
        let link = `${config.urls.home}/biens-rentables`

        const queryParams = []
        if (!!parameters.cityName && !!parameters.postalCode) {
          const value = `${parameters.postalCode}|${parameters.cityName}|0`
          queryParams.push(`location=${encodeURIComponent(value)}`)
        }

        if (parameters.budget) {
          queryParams.push(`prixMax=${parameters.budget}`)
        }

        if (queryParams.length > 0) {
          link += '/search?' + queryParams.join('&')
        }

        return link
      },
      previewAd: (adId) => `${config.urls.home}/preview/ou-investir/annonces/${adId}`,
      ad: (adId) => `${config.urls.home}/ou-investir/annonces/${adId}`,
      searchById: (searchId) => `${config.urls.home}/biens-rentables/search?searchId=${searchId}`,
      previewAgency: (agencySlug, agencyId, agencyPostalCode, agencyCity) => {
        let cp = `${agencyPostalCode}`

        if (cp.length === 4) {
          cp = `0${cp}`
        }

        const city = encodeURIComponent(agencyCity).replace(/[!'()*]/g, escape)

        return `${config.urls.home}/preview/ou-investir/villes/${city}/${cp}/agences/${agencySlug}/${agencyId}`
      },
    },
  }
}

// List of URLs used by the SPA
export const LEGACY_URLS = {
  RL_CGV: '/conditions-generales',
  RL_BALANCE: '/gestion/compteLocataire',
  RL_BUILDING_EDIT: '/gestion/ajout_immeuble',
  RL_DASHBOARD_DETAILED: '/gestion/rapportglobal',
  RL_DECORATION: '/expert/callback',
  RL_EXPERTS: '/expert',
  RL_EXPERTS_PURCHASES: '/expert/purchases',
  RL_DOCUMENTS: '/gestion/documents',
  RL_HOME: '/',
  RL_INFORMATION_CITY: '/investissement/villes',
  RL_INFORMATION_ADS: '/investissement/annonces',
  RL_INTERIOR_DESIGN: '/redecoration',
  RL_INVEST_ADD: '/gestion/ajout_investissement',
  RL_INVEST_EDIT: '/gestion/ajout_investissement',
  RL_LEASE: '/gestion/editBail',
  RL_MEL: '/gestion/mescandidats-welcome',
  RL_OFFRES: '/offres',
  RL_PROFILE: '/user/profile',
  RL_REAL_ESTATES: '/gestion/biensAll',
  RL_REAL_ESTATE_ADD: '/gestion/ajout_bien',
  RL_REAL_ESTATE_EDIT: '/gestion/ajout_bien',
  RL_RECEIPT: '/gestion/quittance',
  RL_SIMULATIONS: '/user/recents',
  RL_SUPPORT: '/user/requestSupport',
  RL_TENANTS: '/gestion/locatairesActifs',
  RL_TENANT_ADD: '/gestion/ajout_locataire',
  RL_TENANT_ADD_PAYMENT: '/gestion/ajout_operation',
  RL_TENANT_DOCUMENT: '/gestion/documents',
  RL_TENANT_EDIT: '/gestion/ajout_locataire',
  RL_TENANT_HISTORY: '/gestion/emails',
  RL_TENANT_OPERATIONS: '/gestion/operationsImmeuble',
  RL_USER: '/user',
  RL_OFFERS: '/offres',
  RL_CHURN: '/churn',
}

export const HELPDESK_URLS = {
  HP_ROOT: 'https://aide.horiz.io/hc/fr',
  HP_FIND_TENANT:
    'https://aide.horiz.io/hc/fr/articles/360029210411-Comment-remettre-son-bien-en-location-facilement-',
  HP_REFONTE: 'https://aide.horiz.io/hc/fr/articles/360019832180-Refonte-',
  HP_CHECK_IN:
    'https://aide.horiz.io/hc/fr/articles/360009562340-Etat-des-lieux-et-d%C3%A9gradations-des-locataires-?utm_source=gestion-app&utm_medium=liste_locataires__bouton_etat_des_lieux',
  HP_NEW_REQUEST: 'https://aide.horiz.io/hc/fr/requests/new',
  HP_PAYMENT: 'https://aide.horiz.io/hc/fr/sections/360003027239-Abonnement-et-paiements-',
}

export const PARTNERS_URLS = {
  HP_ROOT: 'https://horiz.io/ressources/expert-comptable-lmnp',
}

export const FEEDBACKS_URLS = {
  HP_ROOT: 'https://horiz.canny.io/',
}

/**
 * Some regex information :
 *
 * (\/\w*){x} ==> the url should have x params
 * (\/\w*){x, y} ==> the url should have between x and y params
 *  if any of those is present, no param is needed
 *
 * (\?\w+=\w*(&\w+=\w*)*)? is used to detect query params
 *
 */
const LEGACY_URLS_REGEX = {
  RL_CGV: /^(\/(rendement-locatif|loc))?\/conditions-generales$/,
  RL_BALANCE: /^(\/(rendement-locatif|loc))?\/gestion\/compteLocataire(\/\w*){1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_ALERTS: /^(\/(rendement-locatif|loc))?\/investissement\/alertes(\/\w*){0,1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_BUILDING_EDIT:
    /^(\/(rendement-locatif|loc))?\/gestion\/ajout_immeuble(\/\w*){1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_DECORATION: /^(\/(rendement-locatif|loc))?\/expert\/callback(\/\w*){2}\/bien\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_DASHBOARD_DETAILED: /^(\/(rendement-locatif|loc))?\/gestion\/rapportglobal\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_DOCUMENTS: /^(\/(rendement-locatif|loc))?\/gestion\/documents\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_EXPERTS: /^(\/(rendement-locatif|loc))?\/expert\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_EXPERTS_PURCHASES: /^(\/(rendement-locatif|loc))?\/expert\/purchases?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_HOME: /^(\/(rendement-locatif|loc))?\/(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_INTERIOR_DESIGN: /^(\/(rendement-locatif|loc))?\/redecoration\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_INVEST_ADD: /^(\/(rendement-locatif|loc))?\/gestion\/ajout_investissement\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_INVEST_EDIT:
    /^(\/(rendement-locatif|loc))?\/gestion\/ajout_investissement(\/\w*){1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_LEASE: /^(\/(rendement-locatif|loc))?\/gestion\/editBail(\/\w*){1,2}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_MEL: /^(\/(rendement-locatif|loc))?\/gestion\/mescandidats-welcome(\/\w*){2}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_OFFRES: /^(\/(rendement-locatif|loc))?\/offres\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_PROFILE: /^(\/(rendement-locatif|loc))?\/user\/profile\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_REAL_ESTATES: /^(\/(rendement-locatif|loc))?\/gestion\/biensAll\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_REAL_ESTATE_ADD:
    /^(\/(rendement-locatif|loc))?\/gestion\/ajout_bien(\/\w*){0,1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_REAL_ESTATE_EDIT:
    /^(\/(rendement-locatif|loc))?\/gestion\/ajout_bien(\/\w*){2}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_REAL_ESTATE_TENANT_ADD:
    /^(\/(rendement-locatif|loc))?\/gestion\/ajout_locataire(\/\w*){3}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_RECEIPT: /^(\/(rendement-locatif|loc))?\/gestion\/quittance(\/\w*){1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_REDIRECT_INSURANCE: /^(\/(rendement-locatif|loc))?\/r\/i(\/\w*){1}-\w*(\?\w+=\w*(&\w+=\w*)*)?/,
  RL_SUPPORT: /^(\/(rendement-locatif|loc))?\/user\/requestSupport\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_TENANTS: /^(\/(rendement-locatif|loc))?\/gestion\/locatairesActifs\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_TENANT_ADD: /^(\/(rendement-locatif|loc))?\/gestion\/ajout_locataire\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_TENANT_ADD_PAYMENT: /^(\/(rendement-locatif|loc))?\/gestion\/ajout_operation\/?(\?\w+=\w*(&\w+=\w*)*)?/, // ! add right parameters to this route before using it
  RL_TENANT_EDIT:
    /^(\/(rendement-locatif|loc))?\/gestion\/ajout_locataire(\/\w*){2}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_TENANT_DOCUMENT:
    /^(\/(rendement-locatif|loc))?\/gestion\/documents(\/\w*){3}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_TENANT_HISTORY: /^(\/(rendement-locatif|loc))?\/gestion\/emails(\/\w*){1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_TENANT_OPERATIONS:
    /^(\/(rendement-locatif|loc))?\/gestion\/operationsImmeuble(\/\w*){1}\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_USER: /^(\/(rendement-locatif|loc))?\/user\/?(\?\w+=\w*(&\w+=\w*)*)?$/,
  RL_OFFERS: /^(\/(rendement-locatif|loc))?\/offres\/?(\?\w+=[\w%-.]*(&\w+=[\w%-.]*)*)?$/,
  RL_CHURN: /^(\/(rendement-locatif|loc))?\/churn\/?(\?\w+=[\w%-.]*(&\w+=[\w%-.]*)*)?$/,
  RL_CALCUL: /^\/rendement-locatif\/calcul\/rendement/,
  GESTION: /^\/gestion\//, // every path starting with "/gestion/"
  PUBLIC_DOC_GESTION: /^\/public\/documents\/gestion\//,
  RL_PARAMS: /^(\/(rendement-locatif|loc))?\/user\/showOtherParam$/,
}

const EXCLUDED_FROM_AUTH = [/^\/public\/documents\/gestion\/expert\//, /^\/public\/documents\/gestion\//]

function isLegacyURL(url) {
  const matchToUrl = (legacyUrl) => url.match(legacyUrl)

  return Object.values(LEGACY_URLS_REGEX).some(matchToUrl)
}

export function redirect(entity, forceRedirect = false, forceNewTab = false, query = {}) {
  if (entity && typeof entity === 'object') {
    var { url, name, isRoute } = entity
    forceRedirect = isRoute
  } else {
    url = entity
  }

  if (
    Object.values(HELPDESK_URLS).includes(url) ||
    Object.values(PARTNERS_URLS).includes(url) ||
    Object.values(FEEDBACKS_URLS).includes(url) ||
    (url && url.includes('http'))
  ) {
    handleForceNewTab(url, forceNewTab)
  } else if (name) {
    // Route inside the SPA by Name
    router.push({ name, query }).catch(() => {})
  } else if (!isLegacyURL(url) || forceRedirect) {
    // Route inside the SPA
    router.push({ path: url, query }).catch(() => {})

    return url
  } else {
    // Route outside the SPA
    handleForceNewTab(getAbsoluteUrl(url), forceNewTab)

    return url
  }
}

function handleForceNewTab(url, forceNewTab) {
  if (!forceNewTab) {
    window.location.href = url
  } else {
    // Force new tab
    window.open(url, true)
  }
}

export const getAbsoluteUrl = (path) => {
  if (!isLegacyURL(path)) return path
  let redirectHandCheck = ''
  const userID = store.getters['auth/userID']
  if (userID) {
    redirectHandCheck = `redirectHandCheck=${userID}&`
  }

  const oAuthRedirect = `/user/login?${redirectHandCheck}redirectAfterLogin=`

  // Exclude some paths from being redirected by the Auth in Legacy API to avoid 404 error
  if (isExcludedFromAuth(path)) return config.urls.rl + path

  return config.urls.rl + oAuthRedirect + encodeURIComponent(path)
}

function isExcludedFromAuth(path) {
  return !!EXCLUDED_FROM_AUTH.filter((regex) => regex.test(path)).length
}

export const iframesPathMapping = (legacyPath, legacyQuery) => {
  const legacyPathArray = legacyPath.split('/')
  const firstPath = legacyPathArray[1] || ''
  const withBaseUrl = firstPath === 'rendement-locatif' || firstPath === 'loc'
  const controller = !withBaseUrl ? legacyPathArray[1] || '' : legacyPathArray[2] || ''
  const method = !withBaseUrl ? legacyPathArray[2] || '' : legacyPathArray[3] || ''
  const params = !withBaseUrl ? legacyPathArray.slice(3) || [] : legacyPathArray.slice(4) || []
  const searchParams = new URLSearchParams(legacyQuery)

  switch (controller) {
    case 'gestion':
      switch (method) {
        case 'operationsImmeuble':
          if (params.length === 0) {
            return '/building-finances'
          }
          if (searchParams.get('filters')) {
            return `/tenant-balance/${params[0]}/${searchParams
              .get('filters')
              .replace('locataireId_', '')}/${legacyQuery}`
          } else {
            return `/building-finances/${params[0]}`
          }
        case 'rapportglobal':
          return '/building-global-report'
        case 'completude':
          return '/real-estates/r'
        case 'ajout_investissement':
          if (params.length > 0) return `/building-investment/${params[0]}`
          else return '/building-add-investment'
        case 'emprunts':
          return `/building-loans/${params[0]}`
        case 'ajout_immeuble':
          return `/building-edit/${params[0]}`
        case 'biens':
          return '/real-estates/r'
        case 'ajout_bien':
          if (params.length > 1) {
            return `/real-estate-edit/${params[0]}/${params[1]}`
          } else if (params.length > 0) {
            return `/building-real-estate-add/${params[0]}`
          }

          return '/real-estate-add'
        case 'investissements':
          return '/real-estates/r'
        case 'inventaire':
          return `/real-estate-inventory/${params[0]}`
        case 'mescandidats':
          return `/real-estate-mel/${params[0]}/${params[1]}`
        case 'editBail':
          return `/real-estate-add-lease/${params[0]}${appendSlug(params[1])}`
        case 'optionsBien':
          return `/real-estate-automatic-receipt/${params[0]}`
        case 'ajout_operation':
          // should never be called
          store.dispatch('drawer/addDrawer', {
            id: 'drawer-operations',
            props: {
              operationId: params[1] || null,
            },
            component: defineAsyncComponent(() => import('@/components/Operations/DrawerAddOperation.vue')),
          })

          return null
        case 'quittance':
          return `/tenant-add-receipt/${params[0]}`
        case 'ajout_locataire':
          if (params.length === 2) return `/tenant-edit/${params[1]}/${params[0]}`
          else if (params.length === 3) return `/tenant-edit/${params[0]}/${params[1]}/${params[2]}`
          else if (params.length >= 4) return `/tenant-edit/${params[0]}/${params[1]}/${params[2]}`
          else return '/tenant-add'
        case 'documents':
          if (params.length > 1) return `/tenant-documents/${params[0]}/${params[2]}`
          else return allDocumentRoute.path
        case 'revisions':
          return `/tenant-revision/${params[0]}`
        case 'ajout_regul':
          return `/tenant-regularize/${params[0]}`
        case 'revenus':
          return '/user-income'
        case 'locataires':
          return '/tenants/r'
        case 'ajout_declaration':
          return `/building-declaration/${params[0]}`
        case 'ajout_bilan_syndic':
          return `/building-balance-sheet/${params[0]}`
        case 'resumeGeneral':
          return `/building-summary/${params[0]}`
        case 'ajout_annonce':
          return `/real-estate-add-ad/${params[0]}`
        case 'clore':
          return `/end-mel/${params[0]}`
        case 'emails':
          return `/tenant-emails/${params[0]}`
        case 'compteLocataire':
          return `/tenant-accounting/${params[0]}`
        case 'locatairesActifs':
          return '/tenants/r'
        case 'biensAll':
          return '/real-estates/r'
        case 'bien':
          return '/real-estates/r'
        case 'locatairesByImmeuble':
          return '/tenants/r'
        default:
          return
      }
    case 'expert':
      switch (method) {
        case 'callback':
          return `/real-estate-decoration/${params[0]}/${params[1]}/bien`
        default:
          return
      }
    case 'user':
      switch (method) {
        case 'profile':
          return '/user-profile'
        case 'addIdentity':
          return '/user-add-identity'
        default:
          return
      }
    case 'banque':
      if (method === 'transactions') return `/bank-transactions/${params[0]}`
      else return '/bank-synchronization'
    case 'churn':
      return '/churn'
    case 'mon':
      switch (method) {
        case 'annonce':
          return `/view-add/${params[0]}`
        default:
          return
      }
    case 'r':
      return `/r/${method}/${params.join('/')}${searchParams}`
    default:
      return
  }
}

const citiesWithDistrict = ['Paris', 'Lyon', 'Marseille']

export function redirectToCityInformation(city, postalCode) {
  const cityInformationLink = config.urls.rl + LEGACY_URLS.RL_INFORMATION_CITY + getCityPath(city, postalCode)
  window.open(cityInformationLink, '_blank')
}

function getCityPath(city, postalCode) {
  const suffix = '/' + postalCode
  if (citiesWithDistrict.includes(city)) {
    const district = city + ' ' + postalCode.substring(2, 5).replaceAll('0', '')

    return '/' + encodeURI(district) + suffix
  } else {
    return '/' + city + suffix
  }
}

// eslint-disable-next-line no-unused-vars
export function redirectToAds(city, type, surface, price) {
  const url = new URL(config.urls.rl + LEGACY_URLS.RL_INFORMATION_ADS)
  url.searchParams.append('hPP', 30)
  url.searchParams.append('idx', 'annonces_vente')
  url.searchParams.append('p', 0)
  url.searchParams.append('q', city)
  url.searchParams.append('is_v', 1)
  window.open(url, '_blank')
}

export const redirectToOffers = (queryPamars) => {
  window.location.href = getOffersUrl(queryPamars)
}

export const getOffersBaseUrl = () => {
  return config.urls.home + '/offres'
}

export const getOffersUrl = (queryParams = []) => {
  const url = new URL(getOffersBaseUrl())
  let redirectURI = new URL(window.location.href)

  // ignore payment page
  if (redirectURI.pathname === '/payment') {
    const currentURL = new URL(window.location.href)
    redirectURI = currentURL.searchParams.get('redirectURI') || config.urls.rl + LEGACY_URLS.RL_USER
  }
  queryParams.forEach((elt) => {
    redirectURI.searchParams.append(elt.param, elt.value)
  })
  url.searchParams.append('redirectURI', encodeURIComponent(redirectURI))

  return url.href
}
