import { useOneSignal } from '@onesignal/onesignal-vue3'
import { defineStore } from 'pinia'

import { useUserStore } from '@/stores/user'
import { config } from '@/core/config'

const oneSignal = useOneSignal()

export const useNotificationsPushStore = defineStore('app-notifications-push', {
  state: () => ({
    failedToInit: false,
    browserPermissionNotAsked: false,
    browserPermissionDenied: false,
    browserPermissionGranted: false,
    userIsOptIn: false,
  }),
  actions: {
    _refreshState() {
      const permissionNative = oneSignal.Notifications.permissionNative
      this.failedToInit = !['default', 'denied', 'granted'].includes(permissionNative)
      this.browserPermissionNotAsked = permissionNative === 'default'
      this.browserPermissionDenied = permissionNative === 'denied'
      this.browserPermissionGranted = permissionNative === 'granted'
      this.userIsOptIn = oneSignal.User.PushSubscription.optedIn
    },
    async initOS() {
      const self = this
      await oneSignal.init({
        appId: config.onesignal.apiId,
        safari_web_id: config.onesignal.safariWebId,
        welcomeNotification: {
          title: 'Notifications activées !',
          message: 'Vous serez informé des événements importants.',
        },
      })

      this._refreshState()

      if (this.browserPermissionGranted) {
        await this.login()
      }

      oneSignal.Notifications.addEventListener('permissionChange', async () => {
        self._refreshState()
        if (self.browserPermissionGranted) {
          await self.optIn()
          await self.login()
        } else if (self.browserPermissionDenied) {
          await self.optOut()
          await self.logout()
        }
      })

      oneSignal.User.PushSubscription.addEventListener('change', () => {
        self._refreshState()
      })

      return await Promise.resolve()
    },
    async requestBrowserPermission() {
      await oneSignal.Notifications.requestPermission()
    },
    async login() {
      const userStore = useUserStore()
      await oneSignal.login(`${userStore.userID}`)
      oneSignal.User.addTag('FIN', userStore.user.profiles.FIN)
      oneSignal.User.addTag('PIN', userStore.user.profiles.PIN)
    },
    async logout() {
      await oneSignal.logout()
    },
    async optIn() {
      await oneSignal.User.PushSubscription.optIn()
    },
    async optOut() {
      await oneSignal.User.PushSubscription.optOut()
    },
  },
})
