import DrawerSubscription from '@/components/MySubscription/DrawerSubscription.vue'
import { shallowRef } from 'vue'
import { useDrawerStore } from '@hz/ui-kit'

export const state = {}

export const actions = {
  hasAccess({ rootGetters }) {
    let hasAccess = true
    if (!rootGetters['auth/isLoggedIn'] || rootGetters['auth/isFreeUser']) {
      hasAccess = false
      const drawerStore = useDrawerStore()
      drawerStore.addDrawer({
        id: 'drawer-subscription',
        props: { isPin: false },
        component: shallowRef(DrawerSubscription),
      })
    }

    return hasAccess
  },
}

export const getters = {
  hasLimitedAccess: (state, getters, rootState, rootGetters) => {
    let isLimited = false
    if (!rootGetters['auth/isLoggedIn'] || rootGetters['auth/isFreeUser']) {
      isLimited = true
    }

    return isLimited
  },
}

export const mutations = {}

const finModule = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default finModule
