import { FormatIcu } from '@tolgee/format-icu'

import { CustomVueTolgee } from '@/plugins/custom-vue-tolgee'
import { DevTools, Tolgee } from '@tolgee/vue'
import { useConfig } from '@/core/config'

import localeFR from './locales/tolgee/fr.json'

let tolgeeInstance = null

export async function useHzTolgee(app) {
  const {
    tolgee: { apiUrl, apiKey },
    isProduction,
  } = useConfig()

  tolgeeInstance = Tolgee()
    .use(DevTools())
    // https://tolgee.io/platform/translation_process/icu_message_format
    // https://unicode-org.github.io/icu/userguide/format_parse/messages/
    // https://lokalise.com/blog/complete-guide-to-icu-message-format/
    .use(FormatIcu())
    .init({
      language: 'fr',
      apiUrl: !isProduction && apiUrl,
      apiKey: !isProduction && apiKey,
      staticData: {
        fr: localeFR,
      },
    })

  await tolgeeInstance.run()

  app.use(CustomVueTolgee, { tolgee: tolgeeInstance })
}

export const useT = () => {
  return tolgeeInstance.t
}
