import { defineStore } from 'pinia'

import { subscriptionsService } from '@/services/subscriptions.service'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'

export const PRO_PERMISSIONS = [
  'canPublishAnnonces',
  'canCreateNewSimulation',
  'canSaveSimulation',
  'canAccessSimPdfParameters',
  'canAccessSimPptPrint',
  'canAccessSimFolders',
  'canAccessSimDefaultParameters',
  'canAccessSimParameters',
]

/*
 * App Permissions store mission is to manage connected user permissions, no more
 */
export const usePermissionsStore = defineStore('app-permissions', {
  state: () => ({
    // Foncier
    canCreateNewBuilding: false,

    // Annonces
    canPublishAnnonces: false,

    // Simulator
    canCreateNewSimulation: false,
    canSaveSimulation: false,
    canAccessSimPdfParameters: false,
    canAccessSimPptPrint: false,
    canAccessSimFolders: false,
    canAccessSimDefaultParameters: false,
    canAccessSimParameters: false,

    // Organization
    _canInviteUserToOrganization: false,

    // Quotas
    quotas: {},
  }),
  actions: {
    async refreshQuotasAndPermissions() {
      this.$reset()

      const quotas = useAuthStore().isLoggedIn
        ? await subscriptionsService.getPermissions()
        : await subscriptionsService.getAnonymousPermissions()

      // Foncier
      this.canCreateNewBuilding = quotas.permissions.canCreateNewBuilding

      // Annonces
      this.canPublishAnnonces = quotas.permissions.canPublishAnnonces

      // Simulator
      this.canCreateNewSimulation = quotas.permissions.canCreateNewSimulation
      this.canSaveSimulation = quotas.permissions.canSaveSimulation
      this.canAccessSimPdfParameters = quotas.permissions.canAccessSimPdfParameters
      this.canAccessSimPptPrint = quotas.permissions.canAccessSimPptPrint
      this.canAccessSimFolders = quotas.permissions.canAccessSimFolders
      this.canAccessSimDefaultParameters = quotas.permissions.canAccessSimDefaultParameters
      this.canAccessSimParameters = quotas.permissions.canAccessSimParameters

      // Organization
      this._canInviteUserToOrganization = quotas.permissions.canInviteUserToOrganization

      // Quotas
      this.quotas = quotas.quotas
    },
  },
  getters: {
    // Admin
    canAccessAdmin: () => {
      return useUserStore().isHorizAdmin || useUserStore().isHorizSales
    },

    // Organizations
    canManageHorizOrganizations: () => {
      return useUserStore().isHorizAdmin || useUserStore().isHorizSales
    },
    canManageMyOrganization: () => {
      return useUserStore().isHorizOrganizationManager
    },
    canInviteUserToOrganization: (state) => {
      return (
        useUserStore().isHorizAdmin ||
        useUserStore().isHorizSales ||
        (useUserStore().isHorizOrganizationManager && state._canInviteUserToOrganization)
      )
    },
    canDeleteOrganization() {
      return useUserStore().isHorizAdmin
    },
    canCancelOrganizationSubscription() {
      return useUserStore().isHorizAdmin || useUserStore().isHorizSales
    },

    // Ads ingestion
    canManageAdsIngestionConfigurations: () => {
      return useUserStore().isHorizAdmin
    },

    // Settings repository
    canManageSettingsRepository: () => {
      return useUserStore().isHorizAdmin
    },
  },
})
