import { API, cacheApiResults, HOOKS } from '@/services/api'

function getDefaultSimulationParameters() {
  return API.get('/simulation/default-user-parameters')
}

function compute(payload) {
  return HOOKS.post('/v1/simulations', payload)
}

function computeRegimes(payload) {
  return HOOKS.post('/v1/simulations/regimes', payload)
}

function getMortgageDuration(payload) {
  return HOOKS.post('/v1/simulations/mortgage-durations', payload)
}

function getRentData(payload) {
  return HOOKS.post('/v1/simulations/rents', payload)
}

function getYieldsByPurchasePrice(payload) {
  return HOOKS.post('/v1/simulations/yields', payload)
}

function getMaxRent(areaCode, regime, surface) {
  return API.get(`/simulation/maxRent?areaCode=${areaCode}&regime=${regime}&surface=${surface}`)
}

function searchCity(query) {
  return API.get(`/simulation/search-city?query=${query}`)
}

const getNotarialFees = cacheApiResults((purchasePrice, agencyRatio, isNew) =>
  API.get(
    `/simulation/notarial-fees?purchasePrice=${purchasePrice}&agencyRatio=${agencyRatio}&isNew=${isNew}`,
  ),
)

const getAgencyFees = cacheApiResults((purchasePrice, type) =>
  API.get(`/simulation/agency-fees?purchasePrice=${purchasePrice}&type=${type}`),
)

const getApplicationFees = cacheApiResults((purchasePrice) =>
  API.get(`/simulation/application-fees?purchasePrice=${purchasePrice}`),
)

function getPrimeAnah(surface, renovationWork) {
  return API.get(`/simulation/prime-anah?surface=${surface}&renovationWork=${renovationWork}`)
}

const getHomeLoan = cacheApiResults(
  (
    purchasePrice,
    agencyFees,
    renovationCost,
    contribution,
    emprunt110,
    bookingFees,
    realEstateFurniture,
    notarialFees,
    anah,
    taxRegime,
  ) => {
    const params = {
      prix: purchasePrice,
      agence: agencyFees,
      travauxr: renovationCost,
      apport: contribution,
      emprunt110,
      fraisdossier: bookingFees,
      mbles: realEstateFurniture,
      fraisnotaire: notarialFees,
      primes: anah,
      regime: taxRegime,
    }

    return API.post('/simulation/home-loan', params)
  },
)

function getRealEstateOffers(postalCode, cityName, surface, transaction, maxDayOlds = 365, limit = 10) {
  return API.get(
    `/real-estate-offers/surface-area?areaCode=${postalCode}&cityName=${cityName}&surface=${surface}&transaction=${transaction}&maxDayOlds=${maxDayOlds}&limit=${limit}`,
  )
}

const getAssurance = cacheApiResults((payload) => API.post('/simulation/legacy-assurance', payload))

export const legacyService = {
  compute,
  computeRegimes,
  getAssurance,
  getDefaultSimulationParameters,
  getHomeLoan,
  getApplicationFees,
  getPrimeAnah,
  getMortgageDuration,
  getNotarialFees,
  getAgencyFees,
  searchCity,
  getYieldsByPurchasePrice,
  getRealEstateOffers,
  getRentData,
  getMaxRent,
}
