import { config } from '@/core/config'
import axios from 'axios'

import router from '@/router'
import store from '@/store'
import { useKeycloakService } from '@/services/keycloak.service'

/**
 * parse response
 */
function parseBody(response) {
  if (response && Object.prototype.hasOwnProperty.call(response, 'data')) {
    return response.data
  } else {
    return null
  }
}

export const API = axios.create({
  baseURL: config.urls.api,
  crossdomain: true,
  withCredentials: true,
})

export const HOOKS = axios.create({
  baseURL: config.urls.hooks.replace(/\/+$/, ''),
  crossdomain: true,
  withCredentials: true,
})

const handleToken = async (config) => {
  const kcService = useKeycloakService()
  if (kcService.getAuthenticated()) {
    const token = await kcService.getToken()
    config.headers['Authorization'] = `Bearer ${token}`
  } else {
    delete config.headers['Authorization']
  }

  return config
}

// Handle tokens
API.interceptors.request.use(handleToken)
HOOKS.interceptors.request.use(handleToken)

// Handle 401
API.interceptors.response.use(
  (response) => handleResponse(response),
  async (error) => await handleError(error),
)
HOOKS.interceptors.response.use(
  (response) => handleResponse(response),
  async (error) => await handleError(error),
)

const handleResponse = (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return parseBody(response)
}

const handleError = async (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response && error.response?.status === 401) {
    await store.dispatch('auth/logout')
    await router.push('/login')
  }

  const errorBody = parseBody(error.response)

  return Promise.reject({
    ...errorBody,
    status: error?.response?.status,
  })
}

export function removePatchFields(obj, fields = ['id']) {
  const newObj = {
    ...obj,
  }

  fields.forEach((field) => delete newObj[field])

  return newObj
}

export function catch404ToNull(error) {
  if (error.status === 404) {
    return null
  } else {
    throw error
  }
}

export function cacheApiResults(apiRequest) {
  const cacheRepository = {}

  return async (...args) => {
    const key = JSON.stringify(args)

    if (!cacheRepository[key]) {
      cacheRepository[key] = await apiRequest(...args)
    }

    return cacheRepository[key]
  }
}
