import { useToastsStore } from '@hz/ui-kit'
import { useT } from '@/tolgee'
import { ErrorTypes } from '@/core/exception/app-exception'

export const startAppErrorsHandler = (app) => {
  app.config.errorHandler = (error) => {
    const t = useT()
    if (error.type === ErrorTypes.validation) {
      useToastsStore().warning(t('global.errors.validation-error'))
    } else if (error.status === 403) {
      useToastsStore().warning(t('global.errors.action-not-permit'))
    } else if (error.status === 404) {
      useToastsStore().warning(t('global.errors.resource-not-found'))
    } else if (error.status === 502) {
      useToastsStore().error(t('global.errors.server-error'))
    }
  }
}
