import { HOOKS } from '@/services/api'

export const usePaymentApi = () => ({
  deletePromotionCode: async () => await HOOKS.delete('/v1/payment/promotion-code'),
  getState: async (payload) => await HOOKS.post('/v1/payment/purchase/tunnel/state', payload),
  getProduct: async (payload) => await HOOKS.post('/v1/payment/purchase/products', payload),

  previewInvoice: async (payload) => await HOOKS.post('/v1/payment/purchase/invoices/preview', payload),

  confirmPaymentIntent: async (payload) => await HOOKS.post('/v1/payment/purchase/intents/confirm', payload),

  deletePaymentMethod: async (payload) =>
    await HOOKS.delete('/v1/payment/purchase/payment-methods', { data: payload }),
  updatePaymentMethod: async (payload) => await HOOKS.put('/v1/payment/purchase/payment-methods', payload),

  createSubscription: async (payload) => await HOOKS.post('/v1/payment/purchase/subscriptions', payload),
  getSubscriptionInfo: async () => await HOOKS.get('/v1/payment/purchase/subscriptions/current'),
})
