import { defineStore } from 'pinia'
import { defineAsyncComponent, shallowRef } from 'vue'
import { useDrawerStore } from '@hz/ui-kit'
import { useUserStore } from '@/stores/user'
import { useToastsStore } from '@hz/ui-kit'
import { useT } from '@/tolgee'

/*
 * App User Product store mission is to manage user product states and actions
 */
export const useUserProductStore = defineStore('app-user-product', {
  actions: {
    showUpgradeProDrawer(origine) {
      if (useUserStore().isMemberOfProOrganization) {
        const t = useT()
        useToastsStore().warning(t('organization.action-not-permit'))
      } else {
        useDrawerStore().addDrawer({
          id: 'drawer-upgrade-pro-subscription',
          component: shallowRef(
            defineAsyncComponent(
              () => import('@/components/MySubscription/DrawerUpgradeProSubscription.vue'),
            ),
          ),
          props: {
            origine,
          },
        })
      }
    },
  },
})
