export async function downloadAndOpenPDFInNewTab(opts) {
  const openFileHelper = (newWindow, data) => {
    newWindow.location = window.URL.createObjectURL(data)

    setTimeout(function () {
      newWindow.document.title = opts.fileName
    }, 10)
  }

  let newWindow

  function* openFile() {
    setTimeout(() => {
      newWindow = window.open('/loading')
    })
    // get file after .next(file)
    let data = yield
    // Query can finish before window loaded,
    // So we need to check document.readyState, else listen event
    if (newWindow && newWindow.document && newWindow.document.readyState === 'complete') {
      openFileHelper(newWindow, data)
    } else {
      newWindow.onload = () => {
        openFileHelper(newWindow, data)
      }
    }
  }

  function redirectToError() {
    newWindow.location = '/error?message=' + encodeURIComponent(opts.errorMessage)
  }

  let timeId
  try {
    timeId = setTimeout(() => {
      console.log('timeout')
    }, 60000)
    const openPDF = openFile()
    openPDF.next()
    const data = await opts.downloadHandler()
    openPDF.next(data)
  } catch (e) {
    redirectToError()
  } finally {
    if (timeId) {
      clearInterval(timeId)
    }
  }
}
