const UserProfile = Object.freeze({
  GUARANTOR_TENANT: 'GAR',
  INVESTOR: 'PIN',
  FUTURE_INVESTOR: 'FIN',
  OWNER_PRINCIPAL_RESIDENCE: 'PRP',
  RENTAL_CANDIDATE: 'CAN',
  TENANT: 'LOC', // Not Used anymore
  USER_ACCOUNT_MANAGER: 'GCU',
})

export default UserProfile
