import { API, HOOKS } from '@/services/api'

async function getLastSubscription() {
  return API.get('/subscriptions/last')
}

const BASE_PATH_V1 = 'v1/permissions'

const getPermissions = async (userId = null) =>
  await HOOKS.get(userId ? `${BASE_PATH_V1}/${userId}` : BASE_PATH_V1)

const getAnonymousPermissions = async () => await HOOKS.get(`${BASE_PATH_V1}/anonymous`)

export const subscriptionsService = {
  getLastSubscription,
  getPermissions,
  getAnonymousPermissions,
}
