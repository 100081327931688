import i18n from '@/i18n'
import middlewarePipeline from '@/router/middlewarePipeline'
import requiredAuth from '@/router/middleware/requiredAuth'
import notificationMessageHandler from '@/router/middleware/notificationMessageHandler'
import store from '@/store'
import { useScreenSizeStore, useDrawerStore } from '@hz/ui-kit'
import NProgress from 'nprogress'

import { createRouter, createWebHistory } from 'vue-router'

import routes from '@/router/routes'
import { Layouts } from '@/layouts'
import { userService } from '@/services/user.service'
import { useLoaderStore } from '@/stores/loader'
import { useAuthStore } from '@/stores/auth'
import { useUserActionHistoryStore } from '@/stores/userActionHistory'
import { useUserProfileStore } from '@/stores/userProfile'
import { useT } from '@/tolgee'
import { useKeycloakService } from '@/services/keycloak.service'
import { useZendeskStore } from '@/stores/zendesk'
import { getCookieByName } from '@/helpers/getCookieByName'
import { marketingService } from '@/services/marketing.service'

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

NProgress.configure({ showSpinner: false, trickleSpeed: 50 })

router.beforeEach(async (to, from, next) => {
  const keycloakService = useKeycloakService()
  if (keycloakService.getAuthenticated()) {
    await keycloakService.getToken()
  } else {
    await Promise.resolve()
  }
  useLoaderStore().load('navigation')
  useDrawerStore().removeAll()
  next()
})

router.afterEach((to, from, failure) => {
  useLoaderStore().end('navigation')
  const t = useT()

  if (!failure) {
    if (
      to.meta?.setUserProfile &&
      useAuthStore().isLoggedIn &&
      store.getters['auth/user']?.profiles[to.meta?.setUserProfile] !== 'true'
    ) {
      userService
        .setUserProfile({ profile: to.meta.setUserProfile })
        .then(() => useAuthStore().forceRefreshToken())
    }

    if (
      to.meta?.spontaneousInteractionAllowed === undefined ||
      to.meta?.spontaneousInteractionAllowed === true
    ) {
      if (useAuthStore().isLoggedIn && !useUserActionHistoryStore().completeProfileAfterSignUpDone) {
        if (!useUserProfileStore().areLastAndFirstNameDefined) {
          useUserProfileStore().openUserProfileDrawer({
            updateProfileMessage: t('user-profile-drawer.messages.optimal-usage-need-info'),
            onCloseCallback: async () => {
              await useUserActionHistoryStore().flagActionAsDone('complete_profile_after_sign_up')
            },
          })
        }
      }
    }
  }
})

// * RULES PIPELINE TO APPLY
router.beforeEach(async (to, from) => {
  const { meta } = to
  const screenSizeStore = useScreenSizeStore()

  // HANDLES USER CONVERSION
  if (useAuthStore().isLoggedIn) {
    await store.dispatch('auth/checkIsNewSignUp')
  }

  // * 2 APPLY LAYOUT BY PROFILE
  if (meta && meta.requireProfileLayout) {
    meta.layout = Layouts.DEFAULT
  }

  const zendeskStore = useZendeskStore()

  if (meta && !!meta.hideZendesk !== zendeskStore.hide && !screenSizeStore.isMobileWidth) {
    zendeskStore.hideZendesk(!!meta.hideZendesk)
  }

  // * 5. MIDDLEWARES
  let middlewares = [notificationMessageHandler, requiredAuth]

  if (meta.middlewares) middlewares = middlewares.concat(meta.middlewares)
  if (!middlewares) {
    return true
  }

  const context = {
    to,
    from,
    store,
    router,
  }

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1),
  })
})

router.beforeEach(async (to, from, next) => {
  if (from?.meta?.layout === Layouts.IFRAME) {
    await store.dispatch('navigateFromIframe')
  }

  if (useAuthStore().isLoggedIn && getCookieByName('onboardingUUID')) {
    await marketingService.completeOnBoardingFin()
  }

  next()
})

router.beforeResolve((to, from, next) => {
  const { meta } = to

  // * APPLY PAGE TITLE
  store.dispatch('setTitle', meta.locale ? i18n.global.t(meta.locale) : null)

  // * REMOVE PREVIOUS PAGE META
  Array.from(document.querySelectorAll('[horiz-managed-meta]')).map((el) => el.parentNode.removeChild(el))

  // * APPLY PAGE META
  if (meta && meta.metaTags && meta.metaTags.length) {
    meta.metaTags
      .map((meta) => {
        const tag = document.createElement('meta')

        Object.keys(meta).forEach((key) => {
          tag.setAttribute(key, meta[key])
        })

        tag.setAttribute('horiz-managed-meta', '')

        return tag
      })
      .forEach((tag) => document.head.appendChild(tag))
  }

  next()
})

export default router
