import { Layouts } from '@/layouts'
import store from '@/store'
import { subscriptionsService } from '@/services/subscriptions.service'
import { redirectToOffers } from '@/helpers'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { ensureUserProfileCompletedGuard } from '@/router/guards/ensure-user-profile-completed.guard'
import { profileAttributes } from '@/stores/userProfile'

const meta = {
  layout: Layouts.DEFAULT,
  ignorePresentPushNotifications: true,
}

const subscriptionRedirect = async (next) => {
  const isLoggedIn = useAuthStore().isLoggedIn
  let lastSubscription = null

  if (isLoggedIn) {
    lastSubscription = await subscriptionsService.getLastSubscription()
  }

  if (!isLoggedIn || (isLoggedIn && !lastSubscription)) {
    redirectToOffers()
  } else {
    next()
  }
}

const mySubscriptionRoutes = [
  {
    name: 'hz-organization-subscription',
    path: 'organization-subscription',
    component: () => import('@/views/MySubscription/OrganizationSubscription.vue'),
    meta,
    async beforeEnter(to, from, next) {
      if (!useUserStore().isMemberOfProOrganization) {
        next({ name: 'hz-current-subscription' })
      } else {
        next()
      }
    },
  },
  {
    name: 'hz-current-subscription',
    path: 'current',
    component: () => import('@/views/MySubscription/Current.vue'),
    meta,
    async beforeEnter(to, from, next) {
      if (useUserStore().isMemberOfProOrganization) {
        next({ name: 'hz-organization-subscription' })
      } else {
        await subscriptionRedirect(next)
      }
    },
  },
  {
    name: 'hz-change-subscription',
    path: 'update',
    component: () => import('@/views/MySubscription/Update.vue'),
    meta,
    async beforeEnter(to, from, next) {
      if (useUserStore().isMemberOfProOrganization) {
        next({ name: 'hz-organization-subscription' })
      } else {
        await subscriptionRedirect(next)
      }
    },
  },
  {
    name: 'hz-my-payment-method',
    path: 'payment-method',
    component: () => import('@/views/MySubscription/PaymentMethod.vue'),
    meta,
  },
  {
    name: 'hz-invoices',
    path: 'invoices',
    beforeEnter: ensureUserProfileCompletedGuard([
      profileAttributes.lastName,
      profileAttributes.firstName,
      profileAttributes.address,
      profileAttributes.postalCode,
      profileAttributes.city,
      profileAttributes.country,
    ]),
    component: () => import('@/views/MySubscription/Invoices.vue'),
    meta,
  },
]

const routes = [
  {
    path: '/my-subscription',
    name: 'my-subscription',
    meta: {
      requireAuth: true,
      layout: Layouts.DEFAULT,
    },
    component: () => import('@/views/MySubscription/Index.vue'),
    children: mySubscriptionRoutes,
    redirect: { name: 'hz-current-subscription' },
    async beforeEnter(to, from, next) {
      if (!useAuthStore().isLoggedIn) {
        redirectToOffers()
      } else {
        await store.dispatch('payment/getSubscriptionInfo')
        next()
      }
    },
  },
  {
    path: '/my-subscription/:pathMatch(.*)*',
    redirect: { name: 'hz-current-subscription' },
  },
]

export default routes
