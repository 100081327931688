import { API, HOOKS } from '@/services/api'

const JOURNAL_ENTRIES_ROOT_URL = '/journal-entries'
const toDatabase = ({
  amount,
  auto,
  buildingId,
  description,
  eventDate,
  id,
  ownerId,
  paid,
  tenantId,
  type,
  dateStart,
  dateEnd,
  dateEndRecurrence,
  sourceTransactionMongoId,
  creationDate,
  charges,
  householdRefuseTaxAmount,
}) => {
  return {
    dateCreated: creationDate,
    immeubleId: buildingId,
    locataireId: tenantId,
    operationAuto: auto || false,
    operationDate: typeof eventDate === String ? new Date(eventDate) : eventDate,
    operationDesc: description,
    operationMontant: amount,
    operationMontantTEOM: householdRefuseTaxAmount,
    operationPaid: paid || false,
    operationName: type,
    user_id: ownerId,
    dateDebut: typeof dateStart === String ? new Date(dateStart) : dateStart,
    dateFin: typeof dateEnd === String ? new Date(dateEnd) : dateEnd,
    sourceTransactionMongoId,
    id,
    charges,
    dateEndRecurrence,
  }
}

async function patch(journalEntryId, payload) {
  return await HOOKS.patch(`/v1/operations/${journalEntryId}`, toDatabase(payload))
}
async function patchOnlyWantedPayload(journalEntryId, payload) {
  return await HOOKS.patch(`/v1/operations/${journalEntryId}`, payload)
}

async function createEntry(data) {
  return await HOOKS.post('/v1/operations/', toDatabase(data))
}

function getEntry(id) {
  try {
    return API.get(`${JOURNAL_ENTRIES_ROOT_URL}/${id}`)
  } catch (e) {
    return null
  }
}

async function getAll(params) {
  const url = params ? '/v1/operations?' + params : '/v1/operations'

  return await HOOKS.get(url)
}
async function count(params) {
  const url = params ? '/v1/operations/count?' + params : '/v1/operations/count'

  return await HOOKS.get(url)
}

async function cashFlow(params) {
  const url = params ? '/v1/operations/cashFlow?' + params : '/v1/operations/cashFlow'

  return await HOOKS.get(url)
}

async function deleteOperation(id) {
  return await HOOKS.delete(`/v1/operations/${id}`)
}

async function deleteRevisionPC(id) {
  return await HOOKS.delete(`/v1/operations/revisionpc/${id}`)
}

async function exportOp(params) {
  const url = params ? '/v1/operations/export?' + params : '/v1/operations/export'

  const response = await HOOKS.get(url, {
    responseType: 'blob',
  })

  // easy method to download the blob file
  const blobUrl = window.URL.createObjectURL(new Blob([response]))
  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', 'operations-export.xlsx')
  document.body.appendChild(link)
  link.click()

  return response.size
}

export const journalEntriesService = {
  count,
  createEntry,
  getEntry,
  patch,
  patchOnlyWantedPayload,
  getAll,
  exportOp,
  cashFlow,
  deleteOperation,
  deleteRevisionPC,
}
