const withoutUrlValidator = (value) => {
  if (!value || !value.length) {
    return true
  }

  // const tempDivElement = document.createElement('div')
  // tempDivElement.innerHTML = value
  // const strippedHtml = tempDivElement.textContent || tempDivElement.innerText || ''
  const strippedHtml = value.replace(/(<([^>]+)>)/gi, ' ')

  const urlWithProtocolRegex =
    // eslint-disable-next-line no-useless-escape
    /(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

  // eslint-disable-next-line no-useless-escape
  const urlWithoutProtocolRegex = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

  let hasUrlWithProtocol = false

  strippedHtml.replace(urlWithoutProtocolRegex, (match) => {
    // eslint-disable-next-line no-useless-escape
    if (!/^[0-9.,\/]+$/.test(match)) {
      try {
        // If throwing an exception, it is not a valid URL then it's a valid value
        const newUrl = new URL(`http://${match}`)

        if (match.toLowerCase() !== decodeURIComponent(newUrl.hostname).toLowerCase()) {
          hasUrlWithProtocol = true
        }
      } catch (e) {
        hasUrlWithProtocol = false
      }
    }
  })

  return !urlWithProtocolRegex.test(String(strippedHtml).toLowerCase()) && !hasUrlWithProtocol
}

export default withoutUrlValidator
