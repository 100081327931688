import { UNAUTHORIZED } from '@/router/constants'
import { keycloakService } from '@/services/keycloak.service'
import store from '@/store'
import { realEstatesRoute, tenantsRoute } from '@/router/routes/contants'

export default function checkPermission({ next, to, from }) {
  // eslint-disable-next-line no-undef
  const { keycloak } = window
  const { meta = {} } = to

  if (keycloak && keycloak.authenticated && meta.permissions) {
    return keycloakService.hasPermissions(meta.permissions).then((hasPermissions) => {
      if (hasPermissions) {
        return next()
      } else {
        if (
          (from.name === tenantsRoute.name || from.name === realEstatesRoute.name) &&
          store.getters['auth/isFreeUser']
        ) {
          store.dispatch('notifications/setDisplayNotPremium')

          return false
        }

        return { name: UNAUTHORIZED }
      }
    })
  } else {
    return next()
  }
}
