import { useLoaderStore } from '@/stores/loader'

export const PromiseWithLoading = (executor) =>
  new Promise((resolve, reject) => {
    useLoaderStore().load('promise')

    return new Promise(executor)
      .then((result) => {
        useLoaderStore().end('promise')
        resolve(result)
      })
      .catch((error) => {
        useLoaderStore().end('promise')
        reject(error)
      })
  })

export const userLoading = (name = 'promise') => {
  return {
    start: () => {
      useLoaderStore().load(name)
    },
    stop: () => {
      useLoaderStore().end(name)
    },
  }
}
