import { createStore } from 'vuex'

import authModule from './auth.module'
import finModule from './fin.module'
import lightboxModule from './lightbox.module'
import paymentModule from './payment.module'
import notificationsModule from './notifications.module'
import statsModule from './stats.module'
import tenantsModule from './tenants.module'
import userContext from './userContext.module'
import operationModule from './operation.module'
import synchroModule from './synchro.module'

export const DEFAULT_TITLE = 'Mon outil de gestion'
export const IFRAME_RELOAD_TYPES = {
  TENANTS: 'TENANTS',
  REAL_ESTATES: 'REAL_ESTATES',
}

const state = {
  currentTitle: DEFAULT_TITLE,
  reloadStoreFromIframe: Object.values(IFRAME_RELOAD_TYPES).reduce((acc, type) => {
    acc[type] = false

    return acc
  }, {}),
}

const actions = {
  setTitle({ commit, rootGetters }, title) {
    rootGetters['auth/isPIN']
      ? (document.title = title || 'Mon outil de gestion')
      : (document.title = title || 'Simulateur de rendement')
    commit('SET_TITLE', title)
  },
  navigateFromIframe({ commit }) {
    commit(
      'SET_RELOAD_STORE_FROM_IFRAME',
      Object.values(IFRAME_RELOAD_TYPES).reduce((acc, type) => {
        acc[type] = true

        return acc
      }, {}),
    )
  },
  setStoreReload({ commit }, { type, status }) {
    commit('SET_RELOAD_STORE_FROM_IFRAME', { ...state.reloadStoreFromIframe, [type]: status })
  },
}

const mutations = {
  SET_TITLE(state, title) {
    state.currentTitle = title || DEFAULT_TITLE
  },
  SET_RELOAD_STORE_FROM_IFRAME(state, reloadStoreFromIframe) {
    state.reloadStoreFromIframe = reloadStoreFromIframe
  },
}

const getters = {
  isForceReload: (state) => (type) => state.reloadStoreFromIframe[type],
}

export default createStore({
  actions,
  getters,
  mutations,
  state,
  modules: {
    auth: authModule,
    fin: finModule,
    lightbox: lightboxModule,
    payment: paymentModule,
    notifications: notificationsModule,
    stats: statsModule,
    tenants: tenantsModule,
    userContext: userContext,
    operation: operationModule,
    synchro: synchroModule,
  },
})
