import { API, HOOKS } from '@/services/api'

function add(data) {
  return API.post('/real-estates', data)
}

function getRealEstatesByBuildings(sort) {
  let url = '/real-estates'

  if (sort) url += `?sort=${sort}`

  return API.get(url)
}

function getRealEstateNumber() {
  return API.get('/real-estates/count')
}

function getRealEstatesBuildingStreetView(url) {
  return new Promise((resolve, reject) => {
    API.get(url, { responseType: 'arraybuffer' }).then((buildingStreetView) => {
      const arraybuffer = buildingStreetView
      const bytes = new Uint8Array(arraybuffer)
      const binary = bytes.reduce((data, b) => {
        return (data += String.fromCharCode(b))
      }, '')

      if (binary) {
        resolve('data:image/jpeg;base64,' + btoa(binary))
      } else {
        reject('Street view image not found')
      }
    })
  })
}

function getById(id) {
  return API.get(`/real-estates/${id}`)
}

function update(id, data) {
  return API.patch(`/real-estates/${id}`, data)
}

function patch(id, data) {
  return HOOKS.patch(`/v1/real-estates/${id}`, data)
}

export const realEstatesService = {
  add,
  getRealEstatesBuildingStreetView,
  getRealEstateNumber,
  getById,
  getRealEstatesByBuildings,
  update,
  patch,
}
