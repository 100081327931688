export const localeFR = {
  fr: {
    'add-data': 'Ajouter des données',
    'app-not-created': {
      title:
        'Une erreur est survenue. Essayez cela : désactivez votre bloqueur de publicité pour le site rendementlocatif.com.',
      'sub-title': 'Si ça ne fonctionne toujours pas, {0}.',
      'contact-here': 'contactez-nous ici',
    },
    back: 'Retour',
    'realEstate-onboarding-name': '{type} de {surface} m² à {city}',
    categorize: 'Catégoriser',
    continue: 'Continuer',
    connexion: 'Connexion',
    'confirm-password': 'Confirmation du mot de passe',
    date: 'Le {date}',
    details: 'Détails',
    documents: {
      consult: 'Consulter',
      download: 'Télécharger',
      getDocments: 'Pour obtenir des documents pré-remplis complètement, rendez-vous sur la page ',
      realEstate: "d'un bien ",
      or: 'ou ',
      tenant: "d'un locataire",
      findOutMore: 'En savoir plus',
      seeTemplates: 'Voir les modèles types de documents',
      templates: {
        leaseNotFurnished1: 'Bail nu',
        leaseNotFurnished2: 'Location nue',
        leaseFurnished1: 'Bail meublé',
        leaseFurnished2: 'Location meublée',
        mobility1: 'Bail mobilité',
        mobility2: 'Location meublée mobilité',
        bailComTitle: 'Bail commercial',
        bailComSubtitle: 'Locaux à destination commerciale',
        bailProTitle: 'Bail professionnel',
        bailProSubtitle: 'Location pour professionnels',
        leaseAmendement1: 'Avenant au bail',
        leaseAmendement2: "Départ d'un locataire",
        leaseAmendement3: "Changement d'un locataire",
        inventory: 'Etat des lieux',
        entry: "D'entrée",
        exit: 'De sortie',
        reminder: "Rappel d'obligations",
        beforeLeaving: 'Avant sortie',
        jointSurety: 'Caution solidaire',
        guarantor: 'Garant',
        claim: 'Réclamation',
        unpaidRent: 'Loyers impayés',
        application: 'Fiche candidature',
        newTenant: 'Nouveau locataire',
        recoverable: 'Charges récupérables',
        leaseAnnex: 'Annexe bail',
        fix: 'Réparations locatives',
        notice: 'Notice Locataires / Bailleurs',
        rentalCertificate: 'Attestation de loyer',
        forCAF: 'Pour la CAF',
        directPayment: 'Demande de versement direct',
        hadopi: 'Rappel loi Hadopi',
        arrivalForTax: 'Arrivée locataire / impôts',
        departureForTax: 'Départ locataire / impôts',
        forTax: 'À envoyer aux impôts',
        forTenant: 'Pour un locataire',
        tenantArrival: 'Arrivée d’un locataire',
        subLeaseRequest: 'Demande de sous-location',
        withItsOwner: 'Auprès de son bailleur',
        mondateRevocation: 'Révocation mandat de vente',
        withAgency: "Auprès d'une agence",
        rentClearnessPlan: "Plan d'apurement de loyer",
        forScales: 'Pour des échelonnements',
        returnDeposit: 'Restitution de caution',
      },
      unavailable: 'Indisponible',
    },
    'dot-space': '. ',
    download: 'Télécharger',
    DrawerChurn: {
      title: 'Vous voulez nous quitter ?',
      subtitle: 'Nous sommes convaincus que nos services peuvent vous être encore utiles.',
      mainText1: 'Nous vous proposons l’accès à ',
      mainText2: 'TOUS nos outils ',
      mainText3: 'pour seulement ',
      mainText4: '8,25€/mois.',
      getAccessTo: 'Retrouvez :',
      reason1: 'Des simulations personnalisées',
      reason2: 'Des simulations pour tous les régimes existants (pinel, sci, lmnp, etc)',
      reason3: 'Des alertes d’annonces immo rentables en temps réel',
      reason4: 'Rédaction de bail',
      reason5: 'Alerte en cas d’impayés de votre locataire',
      reason6: 'Modèle de documents',
      reason7: 'Synchronisation bancaire',
      more: 'Et bien plus encore !',
      cta1: 'Rester abonné pour 8,25€ /mois',
      cta2: 'Me désabonner',
    },
    DrawerSubscription: {
      section: {
        rentalManagement: 'Gestion locative',
        simulator: 'Simulateur de rendement',
      },
      title: {
        fin: 'Bénéficiez de l’expertise complète du simulateur',
        pin: 'Bénéficiez de tous les outils de la Gestion Locative',
      },
      patrimoineReport: 'Rapport de performance de votre patrimoine',
      support: 'Accès au support',
      simulationNumber: 'Simulations personnalisées',
      unfurnishedRental: 'Simulations location nue, meublée LMNP et SCI',
      searchExclusiveAds: 'Recherches de biens exclusifs Horiz.io',
      report: 'Rapport d’investissement imprimable',
      realEstateNumber: 'Nombre de biens',
      management: 'Mise en location et gestion',
      lease: 'Bail et signature électronique',
      taxesHelp: "Aide à la déclaration d'impôts",
      free: 'Gratuit',
      premium: 'Premium',
      unlimited: 'Illimité',
      oneFree: '1 gratuit/an',
      connection: 'J’ai un compte, me connecter',
      keepFree: 'Continuer gratuitement',
      churn: 'Me désabonner',
      advantages: 'Nos avantages exclusifs',
      pno: 'Assurance Propriétaire Non Occupant',
      expert: 'Votre expert comptable',
      gli: 'Assurance Garantie Loyers Impayés',
      from: 'À PARTIR DE',
      stayFree: 'Continuer gratuitement',
      sub: "M'abonner à partir de 8.25€ /mois",
      unsub: 'Je me désabonne',
      offer: "Je profite de l'offre",
      alreadyHaveAnAccount: "J'ai un compte, me connecter",
      tooltipLease: '10€ pour tout bail supplémentaire.',
      tooltipPNO: 'À partir de {price}€/an pour un appartement de moins de 200m2.',
      tooltipGLI: 'À partir de {percent}% du montant de votre loyer.',
      tooltipReport:
        '<a href="https://communaute.horiz.io/t/nouveautes-decembre-2022-rapport-de-performance-reelle-de-votre-patrimoine/165" target="_blank">Découvrir la performance de votre patrimoine.</a>',
    },
    DrawerUpgradeProSubscription: {
      title: 'Cette fonctionnalité n’est pas accessible avec votre offre actuelle.',
      message:
        'Souscrivez à nos offres dédiées aux professionnels pour profiter de l’ensemble des fonctionnalités !',
      buttons: {
        continue: 'Continuer avec mon offre',
        upgrade: "Changer d'offre pro",
      },
    },
    'email-address': 'Adresse email',
    'email-or-username': 'Adresse email ou Pseudo',
    'phone-number': 'Numéro de téléphone',
    'entered-on': 'Entrée le',
    'feedback-property-address-done':
      'Merci beaucoup ! Nous étudierons votre cas pour améliorer l’interface.',
    fields: {
      address: 'adresse',
      'current-password': 'Mot de passe actuel',
      'confirm-password': 'Confirmation du mot de passe',
      email: 'adresse email',
      'email-address': 'Adresse email',
      'email-or-username': 'Adresse ou Pseudo',
      loan: 'Est-il loué',
      entryDate: "Date d'entrée",
      firstname: 'Prénom',
      lastname: 'Nom',
      password: 'Mot de passe',
      'phone-number': 'Numéro de téléphone',
      price: "prix d'achat",
      'property-charge': 'Charges mensuelles',
      'property-release-signatureDate': 'Date de signature du bail',
      'property-entryDate': "Date d'entrée",
      'property-endDate': 'Date de sortie',
      'property-firstname': 'Prénom',
      'property-lastname': 'Nom',
      'property-purchase-price': "Prix d'achat",
      'property-rent': 'Loyer hors charges',
      'property-surface': 'Surface',
      'property-tenant-email': 'Adresse email du locataire',
      rent: 'Loyer',
      'rl-property-address': 'Adresse du bien',
      realEstateType: 'type de bien',
      surface: 'surface',
      year: "année d'achat",
      line2: "Complément d'adresse",
    },
    'filter-most-oldest': 'Du plus ancien',
    'filter-most-recent': 'Du plus récent',
    from: 'du',
    in: 'à',
    'journal-entries-description': {
      chargeAssLi: 'Charge Assurance Loyers Impayés',
      chargeAssPNO: 'Charge Assurance PNO',
      chargeAssistanceGestion: 'Charge Comptable ou Aide à Gestion',
      chargeAssistanceRL: 'Charge Abonnement Rendement Locatif',
      chargeAssuranceNonAmorti: "Emprunt - Assurance absents de l'amortissement",
      chargeAutre: 'Charges Autre non déductibles',
      chargeDeducA: 'Charge Déductible Autre',
      chargeEmpruntAutre: 'Emprunt - Charge Autre',
      chargeEmpruntCreditLogement: 'Emprunt - Charge Garantie Crédit Logement',
      chargeEmpruntFraisDossier: 'Emprunt - Charge Frais de dossier',
      chargeExcepSyndic: 'Charge Provision Non Prévu Syndic (Travaux)',
      chargeFraisProcedure: 'Charge Frais Procédure (Avocat, notaire, expert, litige...)',
      chargeGestion: 'Charge Gestion Agence',
      chargeIndemnitesRemboursement: 'Emprunt - Charge indemnités remboursement anticipé',
      chargeInteretsNonAmorti: "Emprunt - Intérêts absents de l'amortissement",
      chargeMeubles: 'Charge Achat Mobilier (Non déduct.)',
      chargeR: 'Charge Récupérable Entretien Immeuble',
      chargeRA: 'Charge Récupérable Autre Immeuble',
      chargeRConsC: 'Charge Récupérable Consommation Commune',
      chargeRConsP: 'Charge Récupérable Consommation Individuelle',
      chargeReguLocataire: 'Charge Régularisation crédit Locataire',
      chargeRembLoyer: 'Charge Remboursement de loyer',
      chargeRestDepot: 'Charge Restitution Dépôt de Garantie',
      chargeSyndic: 'Charge Provision Syndic',
      chargeTF: 'Charge Taxe Foncière',
      chargeTravaux: 'Charge Travaux Déductible dans le Bien',
      chargeTravauxAgrandissement: 'Charge Travaux Non-Déductible',
      loyerDette: 'Solde Loyer - Dette locataire',
      loyerGeste: 'Solde Loyer - Geste commercial',
      mensualiteEmprunt: 'Echéance emprunt',
      recetteAutre: 'Recette Autre (ANAH, indémnités…)',
      recetteCaf: 'Recette CAF (APL/ACL)',
      recetteDepotGar: 'Recette dépôt de garantie (caution)',
      recetteLoyer: 'Recette Loyers Hors Charges',
      recetteLoyerProvision: 'Recette Provision Charges Locataire',
      recetteOrdureM: 'Recette Remb. Taxe Ordures Ménagères',
      recettePrimeAnah: 'Recette Prime ANAH',
      recetteReguLocataire: 'Recette Régularisation Charges (débit Locataire)',
      entreeLocataire: 'Evénement - Nouveau Locataire',
      departLocataire: 'Evénement - Départ Locataire',
      modulationMensualite: 'Emprunt - Modification échéance',
      modulationAssurance: 'Emprunt - Modification assurance',
      modulationInterets: 'Emprunt - Modification intérêts',
      modulationTaux: 'Emprunt - Modification taux',
      modulationAssurancePourcent: 'Emprunt - Modification pourcent assurance',
      remboursementAnticipe: 'Emprunt - Remboursement anticipé',
    },
    horiz: {
      mySub: {
        pageTitle: 'Mon abonnement',
        nav: {
          current: 'Situation',
          update: 'Gestion',
          paymentMethod: 'Moyen de paiement',
          invoices: 'Factures',
        },
        noCurrent: {
          'come-back': "M'abonner de nouveau",
          warning: 'Votre dernier abonnement {0} a expiré le {1}',
        },
        noCurrentCommitted: {
          'come-back': 'Régulariser le paiement',
          warning:
            "Vous êtes engagé avec un abonnement {0} jusqu'au {1}.<br/>Mais cet abonnement est annulé depuis le {2}.",
        },
        current: {
          title: 'Abonnement',
          nextPayment: 'Prochain prélèvement de {invoiceAmount}€ le {nextInvoiceDate}',
          endSubscription: 'Votre abonnement se terminera le {cancelDate}',
          goToPaymentMethod: 'Voir la carte de paiement',
          goToUpdate: 'Gérer',
          unlimited: 'Illimité',
          pastDue: {
            goToPaymentMethod: 'Régulariser',
            desc: 'Vous êtes actuellement en défaut de paiement',
            help: 'Aide',
          },
          quotas: {
            availableRegimes: 'Régime(s) disponible(s)',
            calculationNumber: 'Nombre de nouvelles simulations',
            alerts: 'Alertes',
            realEstateOffers: 'Annonces',
            buildings: 'Bien(s) à gérer',
            digitalLease: 'Bail numérique',
            bankSync: 'Synchronisation bancaire',
            handle: 'Gérer',
          },
          bankSync: {
            enabled: 'Activée',
            disabled: 'Pas activée',
          },
          commitment: {
            without: 'Sans engagement',
            with: "Engagement jusqu'au {committedUntil}",
          },
          exclusiveBenefits: {
            _: 'AVANTAGES EXCLUSIFS',
            accountantLink: 'En profiter',
            unavailable: 'Disponible uniquement pour les abonnés engagés',
            readMore: 'En savoir plus',
            priceCondition: 'À partir de ',
            nonOccupantOwnerInsurance: {
              title: 'Assurance Propriétaire Non Occupant',
              priceDetails: 'Pour un appartement de moins de 200m<sup>2</sup>',
              elems: {
                1: 'Assurance obligatoire',
                2: 'Sans franchise',
                3: 'Mobilier assuré jusqu’à 5 000€ (option)',
                4: 'Déductible des revenus fonciers',
              },
            },
            unpaidRentGuarantee: {
              title: 'Garantie Loyers Impayés',
              priceDetails: 'du montant de votre loyer',
              elems: {
                1: 'Protection juridique',
                2: 'Remboursement intégral des impayés',
                3: 'Détérioration immobilière assurée',
                4: 'Démarches administratives assistées',
              },
            },
            yourExpertAccountant: {
              title: 'Votre expert comptable',
              elems: {
                1: 'Expert de la déclaration en LMNP',
                2: 'Stratégie et optimisations fiscales',
                3: 'Professionnel joignable par mail et téléphone',
                4: 'Une personne dédiée à votre dossier',
              },
            },
          },
        },
        update: {
          monthly: 'Sans engagement\n19,99€ / mois',
          yearly: 'Sans engagement\n99€ / an',
          unsubscribe: 'Vous souhaitez vous désabonner ?',
          unsubscribeModal: {
            title: 'Vous êtes engagé',
            desc: 'Il est impossible de supprimer votre compte en période d’engagement.<br>Veuillez contacter le service client.',
            action: 'Contacter le service client',
          },
          newOffer: 'Choisissez une nouvelle offre',
          currentOffer: 'Offre actuelle',
          yearlyPayment: 'En un paiement annuel',
          chooseOffer: 'Choisir',
          promotion: 'Promotion',
          withoutCommitment: 'Sans engagement',
          withCommitment: 'Offre engageante sur 12 mois',
          yOrM: 'Annuelle ou Mensuelle',
          from: 'À partir de',
          yearlyPromotion: 'Offre valable la 1ère année de votre abonnement',
          offer: 'Offre',
        },
        paymentMethod: {
          security: 'Votre carte est stockée en sécurité chez notre prestataire',
          stripe: 'Stripe',
          debit: 'Solde / Avoir',
          addMethod: 'Ajouter une carte bancaire',
          'modal-delete': {
            title: 'Êtes-vous sûr(e) de vouloir supprimer cette carte ?',
            body: 'Toutes les données relatives à cette carte seront définitivement supprimées',
            yes: 'Oui',
            no: 'Non',
          },
        },
        noActiveSub: {
          description:
            'Découvrez nos offres qui vous permettrons de trouver un bien idéal, calculer sa rentabilité et de faire sa gestion locative.',
        },
      },
      tooltips: {
        aboutThatCalculationWithHypothesis: {
          text: 'Nous prenons comme hypothèse que le prix augmente de 1% par an et que la revente se fait la 10ème année. Si vous souhaitez modifier ces données, il faut modifier les valeurs dans les "Options avancées" du formulaire de {cta}.',
          cta: 'modification de calcul',
        },
        aboutThatCalculationWithResale: {
          text: 'Calculs effectués avec les données renseignées dans les "Options avancées" du formulaire de {cta}.',
          cta: 'modification de calcul',
        },
      },
      payment: {
        notifications: {
          promoCode: 'Code promo appliqué !',
        },
        product: {
          'back-to-offers': 'Revenir aux offres',
          'change-product': "Changer d'offre",
          either: 'Soit',
          'per-month': '/mois',
          'per-year': '/an',
          'your-choice': 'Votre choix',
          'your-current-offer': 'Votre offre actuelle',
          'your-selection': 'Votre sélection',
          commitment: 'En souscrivant à cette offre, vous vous engagez sur une période de 12 mois.',
        },
        paymentMethod: {
          payment: 'Paiement',
          allTransactionAreCipheredAndSecured: 'Toutes les transactions sont chiffrées et sécurisées.',
          creditCard: 'Carte bancaire',
          lastCardUsed: 'Dernière carte utilisée',
          edit: 'Modifier',
          cardNumber: 'N° carte bancaire',
          expirationDate: 'Date d’expiration',
          cryptogram: 'Cryptogramme',
          validatePayment: 'Valider le paiement',
          pay: 'Payer',
          cgv1: 'En cliquant sur “Payer”, je déclare avoir lu, compris, et accepté les',
          cgv2: 'conditions générales de vente.',
          promotionCode: 'Code promo',
        },
        paymentSummaryMobile: {
          totalAmount: 'Montant total',
          details: 'Détails',
          cgv1: 'En cliquant sur “Valider le paiement”, je déclare avoir lu, compris, et accepté les ',
          cgv2: 'conditions générales de vente.',
        },
        paymentSummary: {
          subscription: 'Abonnement',
          asset: 'Avoir',
          subtotal: 'Sous total',
          totalExcludingTax: 'Total hors taxes',
          tva: 'TVA ({ tvaRate }%)',
          promotionCode: 'Code promo',
          totalAmount: 'Montant total',
          applied: 'appliqué',
          recap: 'Récapitulatif',
          proration: 'Montant recrédité',
          amountNewOffer: 'Montant de la nouvelle offre',
          withPromoCode: 'Code promo inclus',
          unusedTime: 'Montant recrédité non utilisé',
          creditAfter: 'Solde des avoirs après passage de la commande',
        },
      },
      'notification-messages': {
        'subscription-success': 'Merci de vous être abonné !',
        'subscription-success-redirect':
          'Merci de vous être abonné ! Vous allez être redirigé(e) dans un instant',
        'regularize-redirect': 'Votre situation est régularisée. Vous allez être redirigé(e) dans un instant',
        'sign-in-sign-up-success': 'Vous êtes maintenant connecté(e)',
        'gateway-fin-to-pin-success': 'Informations de votre simulation importées avec succès !',
        'no-acces-to-this-regime-warning':
          'Votre abonnement ne vous permet pas de faire une simulation avec ce régime fiscal.',
      },
      close: 'Fermer',
      menu: {
        'manage-profile': 'Gérer mon profil',
        'bank-sync': 'Synchro bancaire',
        'manage-subscription': 'Voir mon abonnement',
        'my-incomes': 'Mes revenus',
        'api-keys': 'Gérer mes clés API',
        'consult-experts': 'Consulter des Experts',
        suggestions: 'Suggestions d’amélioration',
        'help-and-contact': 'Aide et contact',
        'in-trial': "Actuellement en période d'essai",
        subscribe: 'Souscrire',
        logout: 'Déconnexion',
      },
      onboarding: {
        meta: {
          'page-title': 'Investissement locatif rentable et gestion locative en ligne',
          description:
            "Calculez la rentabilité réelle d'un bien immobilier, trouvez l'investissement idéal à réaliser selon votre profil, et pilotez votre gestion locative.",
          'img-alt': "Horiz.io, le génie de l'immobilier",
        },
        fin: {
          entry: {
            title: 'Pour quelle raison faites-vous appel à nos services ?',
            titleMobile: 'Que recherchez-vous ?',
            findProperty: 'Trouver un bien rentable',
            verifyRentability: 'Vérifier la rentabilité d’un bien',
          },
          findPropertyOne: {
            title:
              '<b>Où souhaitez-vous investir ?</b> Si vous ne le savez pas, dites-nous juste dans quelle ville vous habitez.',
            titleMobile:
              '<b>Où souhaitez-vous investir ?</b><br><br><span class="subtitle">Si vous ne le savez pas, dites-nous juste dans quelle ville vous habitez.</span>',
            inputPlaceholder: 'Entrez la ville ou code postal',
          },
          findPropertyTwo: {
            title:
              'Si le loyer perçu ne couvre pas tous les frais, quel coût pouvez-vous supporter par mois ?',
            inputPlaceholder: 'Entrez votre budget max',
          },
          stepper: {
            stepEntry:
              '<b>Besoin de nos lanternes sur un bien en particulier ou alors attendez-vous de trouver la pépite ?</b>',
            stepEntryMobile:
              '<b>Besoin de nos lanternes</b> sur un bien précis ou <b>attendez-vous de trouver une pépite ?</b>',
            stepFindPropertyOne: 'Saviez-vous que la majorité de gens investissent <b>près de chez eux ?</b>',
            stepFindPropertyOneMobile:
              'Saviez-vous que la majorité de gens investissent <b>près de chez eux ?</b>',
            stepFindPropertyTwo:
              "Pour dégoter une pépite <b>adaptée à votre profil</b>, nous devons connaitre l'effort d'épargne mensuel que vous consacrez à ce projet.",
            stepFindPropertyTwoMobile:
              "Nous avons besoin de connaitre <b>l'effort d'épargne mensuel</b> que vous souhaitez consacrer à ce projet.",
            stepVerifyRentabilityOne:
              "Afin d’être le <b>plus précis possible dans nos calculs</b>, nous avons besoin d'en savoir davantage sur votre bien",
            stepVerifyRentabilityOneMobile:
              "Afin d’être le <b>plus précis possible dans nos calculs</b>, nous avons besoin d'en savoir davantage sur votre bien",
          },
          next: 'Suivant',
          verifyRentabilityOne: {
            inputCityPlaceholder: "Ville <span class='small'>tapez la ville ou code postal</span>",
            pricePlaceholder: 'Prix du bien',
            rentPlaceholder: "Loyer <span class='small'>Hors charges</span>",
            surfacePlaceholder: "Surface <span class='small'>en m<sup>2</sup></span>",
            title: 'Dites-nous en plus sur ce bien :',
          },
        },
        pin: {
          '3+': '3 et +',
          addressHint:
            'Si plusieurs adresses sont rattachées à cet achat, vous pourrez le renseigner ultérieurement.',
          addressPlaceholder: "Entrez l'adresse de votre bien",
          'amount-per-month': '€/mois',
          finish: 'Terminer',
          stepper: {
            stepPropertyPurchase:
              "Pour que nous puissions vous <b>donner les conseils et outils adaptés à la bonne gestion de votre bien</b>, nous avons besoin d'en savoir un peu plus.",
            stepPropertyPurchaseMobile:
              'Dites-nous en plus et nous vous donnerons les <b>conseils et outils adéquats</b> à sa bonne gestion locative.',
            stepRealEstate: 'Concrètement quel est le bien qui nécessite <b>un gestionnaire hors-pair ?</b>',
            stepRealEstateMobile:
              'Concrètement quel est le bien qui nécessite un <b>gestionnaire hors-pair ?</b>',
            stepTenant:
              '<b>Connaître vos habitants est essentiel dans la gestion de votre bien</b> : rédaction du bail et/ou des quittances de loyer, etc.',
            stepTenantMobile:
              '<b>Connaître vos habitants est essentiel</b> pour la bonne gestion de votre bien.',
          },
          hasLoanLabel: 'Y a-t-il un emprunt en cours lié à cet achat ?',
          iscouple: "S'agit-il d'un couple ?",
          isRentedLabel: 'Est-il loué ?',
          next: 'Suivant',
          no: 'Non',
          pricePlaceholder: 'Prix d’achat frais d’agence inclus',
          skip: "Passer l'étape",
          continue: 'Continuer',
          continue_subtitle: 'Renseigner mes locataires <b>maintenant</b>',
          finish_subtitle: 'Renseigner mes locataires <b>plus tard</b>',
          realEstateTypes: {
            apartment: 'Appartement',
            cellar: 'Cave',
            house: 'Maison',
            parkingSpace: 'Parking',
            premises: 'Local',
            site: 'Terrain',
            studio: 'Studio',
          },
          realEstateTypePlaceholder: 'Type de bien',
          surfacePlaceholder: 'Surface',
          tenantCaseThreeAndMore:
            'Renseignez au moins l’un de vos locataires. Vous pourrez renseigner les autres locataires ultérieurement.',
          tenantData: {
            charges: 'Charges locatives',
            firstname: 'Prénom',
            lastname: 'Nom',
            email: 'Adresse E-mail',
            entryDate: "Date d'entrée du locataire | Date d'entrée des locataires ",
            rent: 'Loyer hors charges de ce locataire | Loyer hors charges de ces locataires',
          },
          tenantN: 'Locataire {index}',
          tenantNumbers: 'Nombre de locataires',
          tenantPropertyInfo: 'Informations du bien',
          titleRealEstateHint:
            'Si plusieurs biens sont rattachés à cet achat, vous pourrez le renseigner ultérieurement.',
          yearPlaceholder: 'Année d’achat de votre bien',
          yes: 'Oui',
          yourPurchase: 'Etape 1/3 : Indiquez votre achat',
          yourRealEstate: 'Etape 2/3 : Décrivez votre bien',
          yourTenants: 'Etape 3/3 : Renseignez vos locataires',
        },
        lastStep: 'Dernière étape !',
      },
      'pin-dashboard': {
        title: 'Tableau de bord',
        toaster: {
          trialPin: {
            welcome: 'Bienvenue ! ',
            welcome2: 'Vous bénéficiez d’une offre d’essai de 7 jours.',
          },
          subscription: {
            unauthorized: 'Cette fonctionnalité est uniquement accessible aux abonnés.',
            souldLogin: 'Cette fonctionnalité est uniquement accessible aux inscrits ou connectés.',
            login: 'Me connecter',
            subscribe: "M'inscrire",
            'need-higher-subscription': "Votre abonnement ne vous permet pas d'effectuer cette action.",
            'learn-more': 'En savoir plus',
          },
          realEstate: {
            notAllowed: "Votre abonnement ne vous permet pas d'effectuer cette action.",
            updatePlan: 'Modifier mon abonnement',
          },
        },
        widget: {
          loan: {
            title: {
              with: 'Votre crédit immobilier',
              without: 'Nouvel investissement',
            },
            subtitle: {
              realEstate: '{0} bien est rattaché | {0} biens sont rattachés ',
              mortgage: 'à cet emprunt | à ces emprunts',
            },
            content: {
              without: {
                title:
                  'De ce que nous savons de votre situation,<br>il semblerait que ça soit le moment de réinvestir !',
                subtitleStart: 'Vous avez un emprunt ? ',
                subtitleLink: 'Renseignez-le',
                subtitleEnd: ' pour préciser votre situation.',
              },
            },
            cta: {
              with: "Affiner mes données d'emprunt",
              without: 'Faire un investissement rentable',
            },
            graph: {
              linkedRealEstate: 'biens rattachés',
              fill: 'Renseignez-les',
              noRentsRegistered: "Vous n'avez aucun loyer enregistré.",
              ofYourRefund: 'de ce que vous avez remboursé',
              hasBeenFinancedByRents: 'a été financé par vos loyers.',
              noRentInquired:
                'Vous n’avez aucun loyer renseigné. Pour savoir ce qui a été remboursé grâce vos loyers, ',
              actualMoneyRefund: 'Montant actuel remboursé :',
              rentCashedIn: 'Loyers encaissés :',
              totalLoan: 'Montant à rembourser :',
              realEstates: 'biens',
              linkedToLoan: "liés à l'emprunt",
              rentHistory: 'Renseignez l’historique de tous vos loyers',
              here: 'ici',
            },
          },
          tenant: {
            tenantCard: {
              alert1: "Soyez alerté en cas d'impayé ",
              alert2: 'et épargnez-vous certaines tâches fastidieuses de la gestion locative.',
              connectionWithProblemCTA: 'Rétablir la connexion bancaire',
              connectionWithProblemDescription1: 'La connexion avec votre banque a été stoppée, ',
              connectionWithProblemDescription2: "l'automatisation de votre gestion est donc interrompue",
              checkRent: 'Vérifiez la bonne saisie de vos loyers',
              checkRent2: "avant de paramétrer l'automatisation.",
              fillTenants1: 'Renseignez vos locataires pour que nous puissions',
              fillTenants2: 'vous aider à gérer votre bien.',
              notRentedQuestion: "Votre bien n'est pas loué ?",
              clickHere: 'Cliquez ici',
              noTenants1: 'Dès que vous avez trouvé vos locataires',
              noTenants2: ', renseignez-les pour que nous puissions',
              noTenants3: 'vous aider à gérer votre bien',
              positiveBalance: 'Solde positif',
              negativeBalance: 'Solde négatif',
              noConnection: 'Automatiser ma gestion locative',
              failingTenant: 'locataires en incident de paiement',
              addTenant: 'Ajouter un locataire',
              tenantAccount: 'Voir le compte du locataire',
              reflateTenant: 'Relancer mon locataire',
              tenantData: 'Accéder à mes fiches locataires',
              receipt1: 'Envoi de quittance dans',
              receipt2: 'jours.',
              tenantError1: 'Il semblerait que votre locataire ait',
              tenantError2: 'un incident de paiement.',
              allGood: 'Tout va au beau fixe.',
              tenantsError1: 'Il semblerait que certains de vos locataires aient',
            },
          },
        },
        'real-estate-selector': {
          type: {
            appartement: 'appartement',
            studio: 'studio',
            house: 'maison',
            parkingSpace: 'parking',
            premises: 'local',
            cellar: 'cave',
            site: 'terrain',
          },
        },
        'partner-offer-banner': {
          offer: 'Votre comptable pour seulement',
          price: '280€ par an',
        },
      },
      'fin-report': {
        title: 'Rapport de rentabilité',
        'ideal-project': 'Trouver un bien',
        'report-switch-mode': {
          simplified: 'Simplifié',
          advanced: 'Avancé',
        },
        rightBar: {
          parameters: 'Paramètres du simulateur',
          new: 'Nouvelle simulation',
          history: 'Historique et sauvegardes',
          historyTooltip: "Historique jusqu'à 30 jours en arrière",
          share: 'Partager',
          favorite: 'Favori {title}',
          save: 'Sauvegarder',
          delete: 'Supprimer ma sauvegarde',
          print: 'Imprimer',
        },
        'advance-form': {
          type: 'Type de bien',
          neuf: 'Etat du bien',
          prix: 'Prix du bien (Frais agence et chasseur inclus)',
          travauxr: 'Travaux de rénovation (TTC)',
          surface: 'Surface',
          villevalue: 'Ville',
          loyer: 'Loyer mensuel (Hors Charges)',
          cta: 'Sauvegarder',
        },
        advancedReport: 'Rapport détaillé',
        tabs: {
          wearRate: 'Supérieur au taux d’usure ({rate}%) | Inférieur au taux d’usure ({rate}%)',
          sumUp: {
            title: 'En synthèse',
            seeDetails: 'Voir les détails',
            cashFlow: {
              title: 'Cashflow mensuel (2ème année)',
              description: 'Représente les flux entrants et sortants de trésorerie.',
            },
            patrimonialYield: {
              title: 'Rendement patrimonial',
              description:
                "Représente la rentabilité globale de l'investissement incluant la revente (Taux de rendement interne).",
            },
            enrichment: {
              title: 'Enrichissement',
              description:
                "Différence entre le prix d'achat et du prix de vente incluant le cashflow sur la période.",
            },
            netRate: {
              title: 'Rendement net',
              description: "Le rendement net est le rapport entre le bénéfice net et l'investissement total.",
            },
            taeg: {
              title: 'TAEG',
              description:
                'Taux annuel effectif global de l’emprunt ; permet de comparer différentes offres de prêt.',
              noTaeg: 'Un crédit actif est nécessaire',
            },
            ads: {
              title: 'Annonces avec une bonne rentabilité sur {ville}',
              knowMore: 'En savoir plus',
            },
          },
          cashFlow: {
            title: 'Coût',
            question: 'Combien cela va me coûter tous les mois ?',
            realEstateResell: 'Vente du bien',
            rent: 'Loyers',
            charges: 'Charges',
            taxes: 'Impôts',
            loanBalance: "Solde de l'emprunt",
            monthlyMortgage: "Mensualités d'emprunt",
            nothing: 'Rien du tout !',
            gainPerMonth: 'Cela va même vous rapporter {gain} / mois',
            perMonth: '{gain} / mois',
            detailed: {
              year: 'Année',
              balance: 'Solde',
              interests: 'Intêrets',
              fullAnnualCashflowDefault: 'Cash Flow complet',
              annualBalancePlacementDefault: 'Cash Flow',
              fullAnnualCashflowResale: 'Cash Flow complet',
              placementCashflow: 'Si le Cash-flow négatif était plutôt placé à {rate}% net',
              incomes: 'Revenus',
              annuityLoan: 'Annuité emprunt',
              cashflow: 'Cash Flow',
              termValue: 'Valeur à terme',
              enrichment: 'Enrichissement',
              irr: 'TRI',
              van: 'VAN',
              propertyTax: 'Taxe foncière',
              CFE: 'CFE',
              CGA: 'CGA',
              accounting: 'Comptable',
              loanInsuranceCharges: 'Assurance emprunt',
              rentalManagementFees: 'Gestion locative (TTC)',
              movingExpenses: 'Frais relocation',
              unpaidRentInsurance: 'Assurance loyers impayés (TTC)',
              fixedNonRecoverableCharges: 'Charges fixes non récup.',
              unforeseenWork: 'Travaux imprévus',
              PNOInsurance: 'Assurance PNO',
              accountingFees: 'Comptable',
              otherManagementFees: 'Autre frais gestion (poste)',
            },
            subTabs: {
              annualCashflow: 'Cashflow annuel',
              investVSFinancialInvestment: 'Investissement VS Placement Financier',
              CurrentMonthlyCasfhFlow: 'Cash-Flow Mensuel Courant',
              withResale: 'Avec revente',
              withoutResale: 'Sans revente',
              monthlyCashFlowSecondYear: 'Cashflow par année',
              evolutionByYear: 'Evolution par année',
            },
            bubble: {
              title: {
                total: 'Cashflow',
                charges: 'Charges',
              },
            },
            cashFlowChart: {
              title: 'Etude du cash-flow courant mensualisé',
              xTitle: 'Année',
              yTitle: {
                cashflow: 'Cash-flow',
                enrichment: 'Enrichissement',
              },
              label: {
                cashflow: 'Cash-flow',
                enrichment: 'Enrichissement',
              },
              eurosPerMonth: '€/mois',
              bntCashFlow: 'Cash Flow',
            },
          },
          performance: {
            title: 'Rentabilité',
            question: 'Qu’est ce que cela va me rapporter tous les ans ?  ',
            netYield: 'Rentabilité net',
            grossYield: 'Rentabilité brute',
            'by-year': '{gain} / an',
            'punch-lines': {
              step1: 'Un placement financier sans risque ferait mieux !',
              step2: 'À la hauteur de vos espérances ?',
              step3: 'Ça mérite réflexion, non ?',
              step4: 'Un coup de génie en perspective ?',
            },
            hypothesisScenario: 'Hypothèse d’une revente\ndans {year} ans',
            definedResaleScenario: 'Votre scénario de revente dans {year} ans',
            summary: {
              sciisRegime: "SCI / SARL imposé à l'IS (impôt sur les sociétés)",
              asPartOfThisSimulation: 'Dans le cadre de cette simulation:',
              accountingAdvice:
                'Nous vous conseillons fortement de faire appel à un comptable agréé pour faire les comptes.',
              dividendProfit: 'Les bénéfices sont distribués en dividende à hauteur de {p_dividendes}%',
              noDividend: "Aucun dividende n'est distribué",
              notaryFeesDeducted: 'Les frais de notaire sont déduits la 1ère année',
              furnitureAndAgencyFees:
                "Les meubles ({meubles}) et les frais d'agence ({frais_agence}) sont amortis sur {duree_amortissement_agencements} ans",
              works:
                'Les travaux sont amortis sur {duree_amortissement_agencements} ans si supérieurs à 500€ sinon ils sont déduits en charge la 1ère année',
              propertyInfo:
                "Le prix du bien moins {p_prix_terrain}% pour le prix du terrain ({prix_bien_a_amortir}) est amorti sur {duree_amortissement_gros_oeuvre} ans pour le gros oeuvre ({p_amortissement_gros_oeuvre}% du prix), sur {duree_amortissement_facades} ans pour les façades et l'étanchéité ({p_amortissement_facades}% du prix), sur {duree_amortissement_equipements} ans pour les équipements ({p_amortissement_equipements}% du prix) et enfin sur {duree_amortissement_agencements} ans pour les agencements ({p_amortissement_agencements}% du prix)",
              warningAmortizedCosts:
                " Les frais amortis sont reportables sans limite de temps mais ne peuvent qu'annuler le bénéfice de la Société (non déductibles des revenus personnels par exemple).",
              montant_reporte: '',
              montant_reporte_amortissement: '',
              perte_deficit_texte: '',
            },
            subTabs: {
              rentalYield: 'Rendement locatif',
              patrimonialYield: 'Rendement patrimonial',
            },
            table: {
              year: 'Année',
              loyer_: 'Recettes',
              collectedRents: 'Loyers\nperçus',
              financialExpenses: 'Charges\nfinancières',
              nonFinancialExpenses: 'Charges non-\nfinancières',
              landDeficitBenefit: 'Déficit/Bénéfice\nfoncier',
              landTaxableTotal: 'Foncier imposable\ntotal Bénéfice: B\n total Déficit: D',
              landIncomeTax: 'Impôt sur les\nrevenus fonciers',
              socialTax: 'Prélévement\nsociaux',
              interets_tableau: "Intérêts\nd'emprunt",
              charges: 'Charges',
              charges_amorti_: 'Amortiss.',
              imposable_calcul_actuel: 'Résultat\navant impôt',
              impot_revenu_foncier_total: 'Impôt\nBIC',
              impot_is: 'Impôt\nSociété (IS)',
              resultat: 'Résultat',
              dividendesBrut: 'Dividendes\ndistribués bruts',
              impot_dividende: 'Imposition\ndividendes',
              ps: 'Prél.\nSociaux',
              cashflow: 'Cash flow\ncourant',
              rendement: 'Rendement',
            },
            detailed: {
              irr: 'TRI',
              van: 'VAN',
            },
            netReturnChart: {
              title: 'Rendement Net',
              xTitle: 'Année',
              livretA: 'Livret A/Bleu',
              lifeInsurance: 'Assurance Vie (fond euros)',
              lifeInsuranceShort: 'Assurance Vie',
              yourInvestment: 'Votre investissement',
              yTitle: 'Rendement',
            },
          },
          taxes: {
            title: 'Impôts',
            question: 'Quel sera l’impact sur mes impôts ?',
            tableTitle: 'Impôts sur le revenu Barème {year}',
            'info-link': 'impôts sur les sociétés',
            'taxes-increase': 'Hausse des impôts',
            'taxes-decrease': 'Baisse des impôts',
            subTabs: {
              'personal-taxes': 'Impôts sur le revenu',
              'enterprise-taxes': 'Impôts sur les sociétés',
            },
            with: 'avec',
            without: 'sans',
            invest: 'investissement',
            'taxes-to-pay': 'Impôt à payer',
            'tax-exemption': 'Défiscalisation',
          },
          mortgage: {
            title: 'Emprunt',
            question: 'Comment gérer mon emprunt ?',
            tabs: {
              invest: 'Investissement',
              amortisationTable: 'Tableau amortissement',
              amortisationTableShort: 'Amortissement',
              optimalDelay: "Durée d'emprunt optimale",
              optimalDelayShort: 'Durée optimale',
            },
            globalInvest: ' Investissement global',
            yourCredit: 'Votre crédit',
            faiPrice: 'Prix vente du bien FAI',
            faiOfPrice: 'Prix de vente du bien FAI',
            priceNetVendeur: 'Prix du bien net vendeur',
            hunterPrice: "Frais d'agence / chasseur",
            notarialFeesPercent: 'Frais de notaire ({percent}%)',
            notarialFees: 'Frais de notaire',
            bankMortgageFees: "Frais bancaires liés à l'emprunt",
            bankMortgageFeesShort: "Frais de l'emprunt",
            applicationFees: 'Frais de dossier et courtage',
            creditLogementFees: 'Frais de crédit logement',
            fittingOutFees: 'Aménagement',
            renovationsFees: 'Travaux de rénovation',
            fournitureFees: 'Mobilier',
            renovationsAndFourniture: 'Travaux et mobilier',
            total: 'Total',
            capitalToTake: 'Capital à emprunter',
            contribution: 'Apport',
            monthlyPayment: 'Mensualité',
            mortgageDuration: 'Durée d’emprunt',
            insurance: 'Assurance',
            mortgageRatio: "Taux d'emprunt",
            taeg: 'TAEG',
            mortgageCost: 'Coût du prêt',
            interests: 'Intérêts',
            chart: {
              yAxisTitle: 'Valeur Actuelle Nette',
              title: 'Valeur Actuelle Nette',
              redLineLabel: "Durée d'emprunt optimale",
            },
          },
          regime: {
            title: 'Fiscalité',
            question: 'Quel est le meilleur régime fiscal ?',
            simulation: 'Lancer',
            chart: {
              title: 'Meilleur régime par VAN',
              yAxisTitle: 'Valeur Actuelle Nette',
            },
          },
          gains: {
            title: 'Gains',
            question: 'Quand dois-je revendre pour être le plus rentable ?',
          },
          resell: {
            title: 'Revente',
            question: 'Quelle sera la plus-value à la revente ? ',
            subTabs: {
              addedValue: 'Plus-value',
              whenResell: 'Quand revendre ?',
            },
            disabledHypothesis: 'Vous avez désactivé cette hypothèse.',
            reactiveResellOption:
              'Pour la paramétrer, il vous faut appliquer un scénario de revente dans les {link}',
            reactiveResellOptionLink: 'options avancées du formulaire de modification du calcul.',
            manualScenario: {
              loanCapital: 'Capital emprunté à l’achat',
              dueCapital: 'Capital restant dû à la revente',
              interestsSaved: 'Intérêts économisés grâce à la revente',
              yieldIncrease: 'Rendement supplémentaire de {yield} par année grâce à la revente {info}',
              yieldIncreaseTooltip:
                'Pour évaluer ce que la revente apporte dans la rentabilité globale du projet, veuillez consulter la section {cta}',
              yieldIncreaseTooltipCta: '"Rentabilité/Rendement patrimonial".',
              netSellingPrice: 'Prix de revente net vendeur',
              netSellingPriceTooltip: 'Pour comprendre ce calcul, {cta}',
              netSellingPriceTooltipCta: 'lire l’article suivant.',
              globalInvest: 'Investissement global',
              taxeOnAddedValue: 'Impôts sur la plus-value',
              taxeOnAddedValueTooltip: 'Dû à la revente seule, différence avec/sans la revente.',
              addedValueTaxeFree: 'plus-value nette d’impôts',
              purchasePrice: "Prix d'acquisition",
              amortizations: 'Amortissements',
              amortizationsTooltip: 'Amortissements de l’immeuble/travaux à déduire du prix de revente.',
              grossBeforeTaxes: 'Plus-value brute (avant impôts)',
              taxAbatement: 'Abattemment impôts',
              taxAbatementTooltip: "Taux d'abattement pour l'impôt sur le revenu",
              socialAbatement: 'Abattement prélèvements sociaux',
              socialAbatementTooltip: "Taux d'abattement pour les prélèvements sociaux",
              addedValueTaxe: 'Impôts sur plus-value',
              resellYear: 'Année de revente',
              resellPrice: 'Prix de revente',
              AddedValueTaxeFree: 'Plus-value nette d’impôts',
              society: 'Société',
              person: 'Personne',
              dividendsPaid: 'Dividendes versés',
              dividendsPaidTooltip: "Du résultat de l'année de revente. À configurer dans {cta}.",
              dividendsPaidTooltipCta: 'les paramètres',
              incomeTaxe: 'Impôts sur le revenu',
              incomeTaxeTooltip: 'Dû aux dividendes.',
            },
            resellGraph: {
              title: 'Rentabilité en fonction de l’année de revente',
              xTitle: 'Année',
              yTitle: 'Valeur Actuelle Nette',
              yTitleMobile: 'VAN',
            },
          },
          buyingPrice: {
            title: 'Prix d’achat',
            question: 'Est-ce que j’achète au bon prix ?',
            subTabs: {
              marketPrice: {
                title: 'Prix du marché',
                purchasePriceSection: {
                  description: 'Comparaison au marché dans cette ville :',
                  purchasePrice: 'Prix du bien',
                  low: 'Bas',
                  medium: 'Moyen',
                  high: 'Haut',
                },
                adsSection: {
                  description:
                    'Vous trouverez ci-dessous des exemples d’annonces de ventes récentes dans la même ville et pour une surface proche.',
                  descriptionShort: "Exemples d'annonces de ventes similaires",
                  createAlert: 'Créer une alerte',
                  createAlertDesc:
                    'Soyez alerté dès que nous avons connaissance d’un bien correspondant à votre recherche.',
                  columns: {
                    type: 'Type',
                    price: 'Prix',
                    rent: 'Loyer',
                    surface: 'Surface',
                    squareMeterPrice: 'Prix au m²',
                    m2: 'm²',
                    viewed: 'Vu le',
                    view: 'Voir',
                  },
                },
              },
              whichGainTab: {
                title: 'Quel gain en négociant le prix ?',
                columns: {
                  fai: 'Prix FAI à négocier',
                  return: 'Rendement',
                  year2: 'Année 2',
                  cashflow: 'Cash-flow',
                  euroByMonth: '(€/mois)',
                  tri: 'TRI',
                  van: 'VAN',
                  auto12Years: '(revente auto 10 ans)',
                  withResellYears: '(revente à {year} ans)',
                  benefit: 'Apport',
                },
              },
            },
          },
          rent: {
            title: 'Loyer',
            question: 'Quel loyer pratiquer ?',
            adsSectionDesc:
              'Retrouvez ci-dessous des exemples d’annonces de location récentes dans la même ville et pour une surface proche.',
            adsSectionDescShort: "Exemples d'annonces de location similaires",
            maxLimitSection: {
              maximumAuthorizedRent: 'Loyer maximum autorisé',
              byMonth: '/mois',
              localized: ' est située en zone ',
              maxRegime: 'Le loyer est plafonné comme prévu par le régime',
            },
            subTabs: {
              withoutVacancy: {
                title: 'Rentabilité VAN en fonction du loyer</br>(sans vacance)',
                columns: {
                  rent: 'Loyer',
                  cashFlow: 'Cash-flow année 2',
                  tri1: 'TRI',
                  tri2: 'Avec scénario de revente',
                  van1: 'VAN',
                  van2: 'Avec scénario de revente',
                },
              },
              withVacancy: {
                title: 'Rentabilité VAN en fonction du loyer</br>(avec vacance)',
                columns: {
                  rent: 'Loyer',
                  rentalVacancy: 'Vacance locative',
                  tri1: 'TRI',
                  tri2: 'Avec hypothèse d’une revente dans 10 ans',
                  van1: 'VAN',
                  van2: 'Avec hypothèse d’une revente dans 10 ans',
                },
              },
            },
            charts: {
              'chart-1': {
                title: 'Rentabilité (VAN) en fonction du loyer (sans vacance)',
                yAxisTitle: 'Valeur Actuelle Nette',
                redLineLabel: 'Votre loyer',
                tooltip: {
                  title: 'Loyer',
                  label: 'Votre investissement',
                },
              },
              'chart-2': {
                title: 'Rentabilité (VAN) en fonction du loyer (avec vacance)',
                yAxisTitle: 'Valeur Actuelle Nette',
                redLineLabel: 'Votre loyer',
                tooltip: {
                  title: 'Loyer',
                  label: 'Votre investissement',
                },
              },
            },
          },
          common: {
            'year-one': 'Première année',
            'year-two': 'Deuxième année',
            'in-5-year': 'Dans 5 ans',
            'next-year': 'Année 2',
            'in-10-year': 'Dans 10 ans',
            'resell-year': 'Année de la REVENTE',
            beauty: 'On se refait une beauté !',
            info: 'Cette information',
            'detailed-report': 'est accessible sur notre rapport détaillé.',
            responsive: {
              'year-one': 'Année 1',
              'year-two': 'Année 2',
              'in-5-year': 'Année 5',
              'next-year': 'Année 2',
              'in-10-year': 'Année 10',
              'resell-year': 'Année REVENTE',
            },
          },
        },
        lightbox: {
          'save-favorite': {
            title: 'Sauvegarder une simulation',
            titleUpdate: 'Sauvegarder une simulation existante',
            updateSwitchLabel: 'Type de sauvegarde',
            updateSwitchChoice1: 'Mettre à jour la sauvegarde existante',
            updateSwitchChoice2: 'Nouvelle sauvegarde',
            'favorite-name': 'Nom de la simulation',
            reference: 'Référence personnalisée',
            referencePlaceholder: 'Saisir une référence',
            repositorySelect: 'Choix du répertoire',
            repositorySelectPlaceholder: 'Nouveau répertoire',
            newRepository: 'Nom du répertoire à créer',
            newRepositoryPlaceholder: 'Saisir le nom de votre nouveau répertoire',
            cta: 'Valider',
            success: 'Sauvegarde ajoutée avec succès !',
            deleted: 'La simulation a été retirée de vos sauvegardes',
            addFolder: 'Ajouter',
          },
          cashFlow: {
            title: 'Ici c’est la notion de cashflow<br> que vous cherchez à définir.',
            body: {
              part1:
                'Ou traduit “Flux de trésorerie” pour les moins bilingues mais c’est moins gracieux, avouons-le. <b>Il peut être positif, dans ce cas là, jackpot, vous empochez de l’argent tous les mois</b>, le bien est alors autofinancé. <b>S’il est négatif, vous devrez alors débourser tous les mois de l’argent pour ce projet.</b>',
              part2:
                'Concrètement, ce calcul comptabilise toutes les entrées et sorties d’argent liées à cet investissement :',
              part3: '{icon} → Loyers, revente, etc',
              part4: '← {icon}  Impôts, mensualités d’emprunt, assurance, etc.',
              part5:
                '<b>La somme de tout cela nous permet d’estimer l’effort financier mensuel nécessaire à ce projet</b>. Ne vous attendez pas à payer exactement tous les mois cette somme ! Certains mois vous allez payer plus et d’autres moins mais lissé sur l’année cela sera représentatif. ',
            },
            cta: 'En savoir plus',
          },
          performance: {
            title: 'Le rendement,<br> le Graal de tout investisseur.',
            body: {
              part1:
                'Cet indicateur vous permet de comparer la rentabilité de cet investissement à un autre type de placement.',
              part2: 'C’est un rapport entre :',
              part3:
                '<b>Les pépètes que vous empochez :</b> principalement vos loyers ou ce que vous récupérez à la revente du bien.',
              part4:
                "<b>Ce qui sort de votre poche :</b> charges, période d'inoccupation du logement, impôt, etc.",
              part5:
                "<b>Le montant investi :</b> votre investissement au global avec les frais d'agence, frais de notaire, frais bancaires, etc.",
              part6: 'Pour les plus matheux , ça revient à cette formule :',
              dividend: 'Recette <b>-</b> Charges',
              divisor: 'Montant investi',
            },
            cta: 'En savoir plus',
          },
          taxes: {
            title: "Les impôts,<br> l'angoisse de tout investisseur",
            body: {
              part1:
                "On le sait, c’est le mot qui fâche, mais qui dit investissement locatif, dit impact sur vos impôts. Cette donnée est primordiale à analyser pour déterminer la rentabilité de l'investissement.",
              part2: 'Pour une projection la plus fidèle possible, sont pris en compte dans ce calcul :',
              part3:
                'Vos revenus, ainsi que ceux de votre conjoint.e le cas échéant. Tout du moins ceux déclarés 🙈🙉',
              part4: "Le nombre d'enfants à votre charge. Pas ceux que vous croisez dans la rue.",
              part5: 'Le nombre de parts fiscales associé à votre foyer.',
              part6: "Les recettes et frais liés à l'investissement : loyers, charges, etc",
              part7: 'Le régime fiscal choisi',
            },
            cta: 'Vérifier mes infos',
          },
        },
        hypothesis: {
          form: {
            parameters: 'Paramètres du simulateur',
            default: 'Valeurs par défaut',
            save: 'Sauvegarder',
            modify: 'Modifier',
            close: 'Annuler',
            'advanced-modify': 'Modifications avancées',
            noUseCredit: "Je n'utilise pas de crédit bancaire",
            defaultRevenu: 'Valeur par défaut',
            parametersVideo: 'Vidéo explicative sur les paramètres avancés',
            howTo: 'Comment remplir le simulateur ?',
            chargesVideo: 'Vidéo explicative sur les charges totales et locataire',
            encadrementLoyer:
              'Encadrement des loyers à Paris, Bordeaux, Lille, Lyon, Montpellier, Plaine Commune et Est Ensemble (loi Elan)',
            loyersInfo:
              '<span style="text-decoration: underline;">Dans cette ville :</span><span style="margin-left: 2px;"> Loyers bas : {low}€, moyens: {average}€,  hauts : {high}€</span>',
            inParis: "Voir l'encadrement des loyers à Paris",
            inLille: "Voir l'encadrement des loyers à Lille",
            inBordeaux: "Voir l'encadrement des loyers à Bordeaux",
            inMontpellier: "Voir l'encadrement des loyers à Montpellier",
            inPlaine: "Voir l'encadrement des loyers à Plaine Commune",
            inLyon: "Voir l'encadrement des loyers à Lyon et Villeurbanne",
            inEst: "Voir l'encadrement des loyers à Est Ensemble",
            mortgageHowTo: "Comment l'obtenir ?",
            notarialFees: 'Frais de notaire : {fees}€',
            explain: 'Explications',
            apportemptun110: 'Champ désactivé car option <u><strong>Emprunt 110% activée</strong>.</u>',
            type: {
              label: 'Type de bien',
            },
            state: {
              label: 'Etat du bien',
            },
            rent: {
              label: 'Loyer',
              tooltip:
                'Loyer mensuel demandé au locataire excluant les charges locatives. {break}Location saisonnière : cliquez “Modifications avancées” puis remplissez le champ “Loyer annuel”.',
            },
            mensualRent: {
              label: 'Loyer Mensuel',
              subLabel: 'Hors charges',
              tooltip:
                'Le loyer mensuel hors charges. Il sera plafonné automatiquement pour les régimes spéciaux sauf si vous avez désactivé la fonction dans les paramètres du simulateur. Pour de la location saisonnière (airbnb), utilisez le champs loyer annuel.',
            },
            annualRent: {
              label: 'Loyer Annuel',
              subLabel: 'Immeuble/Airbnb',
              tooltip:
                "Toujours hors charges. Ce champ est utile si vous faites de la location saisonnière type airbnb ou si vous ne connaissez que les recettes à l'année (pour un immeuble par exemple).",
            },
            surface: {
              label: 'Surface',
              tooltip: 'Surface du bien en m², aussi appelée surface Carrez.',
            },
            price: {
              label: 'Prix FAI',
              subLabel: 'frais agence et chasseur inclus',
              tooltip:
                'Prix du bien frais d’agence inclus. (Prix Frais d’Agence Inclus = Prix net vendeur + Frais d’agence).',
            },
            work: {
              label: 'Travaux de rénovation | Travaux rénovation',
              subLabel: 'TTC',
              tooltip:
                'Montant des éventuels travaux de rénovation réalisés avant la mise en location.{break}{icon}{link}',
              tooltip2:
                "Le montant des travaux de réparation, d'entretien ou d'amélioration effectués après l'achat (déductibles). Il s'agit ici des travaux de rénovation qui ont pour objet de maintenir ou de remettre en état un immeuble afin d’en permettre un usage normal sans en modifier la consistance, l’agencement ou l’équipement initial. Ce montant sera pris en compte pour le calcul de la plus-value à la revente.",
              link: 'Pourquoi faire des travaux sur un bien ancien ?',
            },
            regime: {
              placeholder: "Choisissez votre régime fiscal d'imposition",
              label: "Régime fiscal d'imposition",
              tooltip:
                'Le régime fiscal que vous choisissez pour déclarer vos revenus locatifs à l’Etat.{break}{icon}{link}',
              tooltip2: 'Le régime fiscal choisi pour les revenus fonciers.',
              link: 'Quel régime choisir ?',
            },
            revenu: {
              label: 'Revenus net',
              subLabel: '(1er)',
              tooltip:
                'Votre revenu net imposable sur une année entière.{break}{icon1} Mémorisez votre revenu pour toutes les simulations en cochant “Valeur par défaut”.{break}{icon2} En couple ? Cliquez “Modifications avancées” pour indiquer les revenus de votre conjoint et le nombre de parts fiscales de votre foyer.',
              tooltip2:
                "Vos revenus de salaires net imposables annuels indiqués sur votre fiche de paie de décembre (revenus avant calcul de l'impôt et avant abattements des frais). Vous pouvez y ajouter d'autres revenus (non foncier) le cas échéant. Les calculs d'impôt par le barème progressif seront fait automatiquement.",
            },
            tax: {
              label: 'Taxe foncière',
              tooltip: 'Montant total de la taxe foncière à payer chaque année.',
            },
            charges: {
              label: 'Charges totales',
              subLabel: 'par an',
              tooltip:
                'Total des charges payées par le propriétaire, incluant : charges locataires, charges de copropriété, frais de syndic, frais d’entretien de l’immeuble, de réparation, etc.{break}{icon}{link}',
              tooltip2:
                "Les charges totales du bien payées par le propriétaire (inclues également les charges locataires récupérables). Elles comprennent les frais de syndic, les frais d'entretien de l'immeuble, les frais de réparation, de ravalement, de travaux, les charges EDF/GAZ payés par le propriétaire, etc. Ce sont les charges de copropriété totales. S'il n'y a pas de copropriété ce sont également les charges payés par le propriétaire pour l'immeuble. Le simulateur va calculer le coût les charges déductibles (non récupérables sur le locataire) en déduisant de ce montant les charges récupérables (provisions locataire).",
              link: 'Quelle différence entre charges totales et charges locataires ?',
            },
            charges_loc: {
              label: 'Dont charges locataires | Charges locataires',
              subLabel: 'par mois',
              tooltip:
                'Ce montant est inclus dans les charges totales payées par le propriétaire. Ce sont les charges mensuelles prévues, qui s’ajoutent au loyer. On les appelle aussi “charges récupérables” ou “charges locatives” ou encore “provision pour charges”. {break}{icon}{link}',
              tooltip2:
                "Le montant des charges mensuelles prévisionnelles (provision pour charges) que vous allez demander au locataire en plus du loyer. Ces charges correspondent à une partie des charges totales de copropriété que l'on appelle les charges récupérables ou locatives. La liste des charges récupérables est strictement réglementée. Il s'agit des frais d'entretien, d'ascenseur, d'eau consommé si part d'un compteur commun, etc. Ce montant doit également inclure la taxe d'enlèvement des ordures ménagères.",
              link: 'Locataire ou propriétaire : qui paie quoi ?',
            },
            duration: {
              label: 'Durée d’emprunt',
              tooltip: '{icon}{link}',
              link: 'Quelle est la durée d’emprunt optimale ?',
            },
            rate: {
              label: 'Taux',
              tooltip:
                'Le taux d’intérêt nominal indiqué par la banque, hors assurance. Exemple : mon taux nominal est 1,10%, mon taux d’assurance est 0,30% et mon TAEG est 1,94% alors j’indique ici 1,10%.{break}{icon}{link}',
              link: 'Quel taux de crédit choisir ?',
            },
            contribution: {
              label: 'Apport personnel',
              tooltip:
                'Le montant de votre apport personnel. Laissez vide si l’emprunt couvre la totalité de votre investissement. Ce champ est désactivé si vous avez sélectionné l’option Emprunt à 110%.{break}{icon}{link}',
              tooltip2:
                "L'apport personnel. Vous pouvez mettre les frais de notaire en apport en cliquant la petite icône ci-dessous.",
              link: 'Comment faire un investissement locatif sans apport ?',
            },
            city: {
              label: 'Ville',
              tooltip: '',
            },
            charges_oth: {
              label: 'Charges non prévus estimées',
              tooltip:
                "Estimation des charges et travaux imprévus déductibles (réparation, entretien, amélioration) fait dans l'appartement. Vient s'ajouter aux charges totales (déductibles mais non récupérables sur le locataire).",
            },
            furniture: {
              label: 'Coût d’ameublement',
              tooltip:
                "Le coût des meubles. Cela est utilisé dans les calculs d'amortissement dans le cas du LMNP.",
            },
            anahDuration: {
              label: 'Durée convention Anah',
              tooltip:
                'La durée de conventionnement Anah. Elle peut être de 6 ou 9 ans lorsque des travaux sont engagés.',
            },
            prime: {
              label: 'Primes Anah',
              tooltip:
                "Le total des primes Anah. Ces subventions sont obtenus par accord signé avec l'Anah en échange de la réalisation de travaux ou sous certaines autres conditions.",
              help: 'Aide',
              twentyFive: 'Prime 25% : {fees}€',
              thirtyFive: 'Prime 35% : {fees}€',
            },
            revenu2: {
              label: 'Revenus net (2ème personne)',
              subLabel: '(2ème)',
              tooltip:
                "Les revenus de salaires net imposables du conjoint (ainsi que du capital).  Les calculs d'impôt par le barème progressif seront fait automatiquement. Le fait d'indiquer une valeur (même 0) considera qu'il s'agit d'un couple.",
            },
            part: {
              label: 'Part fiscale',
              tooltip:
                'Le nombre de parts ficales. Un couple marié compte pour 2 parts, le 1er et le 2ème enfant compte 0.5 parts, 1 part à partir du 3ème enfant.',
            },
            children: {
              label: 'Enfants',
              tooltip: "Le nombre d'enfants à charge.",
            },
            assurance: {
              label: 'Assurance',
              tooltip:
                "Le taux d'assurance exprimé en % du montant emprunté. Le montant mensuel de l'assurance vous est donné à titre indicatif automatiquement.",
            },
            assuranceeuros: {
              label: 'Assurance en euros',
              subLabel: 'indicatif',
              tooltip: '',
            },
            fees: {
              label: 'Frais de dossier',
              subLabel: '(et courtage)',
              tooltip:
                "Les frais de dossiers demandés par la banque pour l'emprunt. Ils sont pré-calculés en prenant 1% du prix du bien. Si vous avez des frais de courtage, vous pouvez les ajouter ici également.",
            },
            credit: {
              label: 'Garantie Crédit Logement',
              tooltip:
                'Le montant de la garantie crédit logement à payer au départ. Le montant est pré-calculé en prenant 1.4% du prix du bien.',
            },
            agence: {
              label: "Frais Agence (et Chasseur) à l'achat",
              tooltip:
                "Les frais d'agence en % du net vendeur. Mettre 0 si la transaction se fait directement entre particuliers. Il faut y inclure les frais de chasseur ou d'accompagnement à l'achat (exemple achat clés en main), vous pouvez utiliser le champ montant en euros pour plus de facilité.",
              hint: '% du net vendeur',
            },
            agenceeuros: {
              label: 'Montant',
              tooltip:
                "Ce champ vous permet d'entrer le montant des frais d'agence en euros. N'oubliez pas d'y ajouter les frais éventuels de chasseur ou d'accompagnateur clés en main.",
              hint: 'Pensez à inclure les frais d’agence et de chasseur dans le prix FAI',
            },
            fraisg: {
              label: 'Frais de gestion par agence',
              subLabel: '(HT)',
              hint: '% du loyer HC (taux habituel: 7%)',
              tooltip:
                'Les frais de gestion si vous faites gérer votre bien par un professionnel. Il se calcul en % du loyer hors charges. Un taux habituellement pratiqué est 7%.',
            },
            fraisrecherche: {
              label: 'Frais agence propriétaire à la location',
              subLabel: '(inclus la recherche du locataire)',
              hint: '% du loyer annuel HC (taux habituel: 8,33% = 1 mois de loyer)',
              tooltip:
                "Les honoraires d'agence à la location ou relocation. Ces frais inclus la mise en place du bail ainsi que la recherche eventuelle d'un locataire. Le montant est habituellement de 12 à 20% du loyer HC annuel partagé à moitié entre le locataire et le propriétaire. Vous devez renseigner ici uniquement la part propriétaire. Le tarif le plus fréquent est un mois de loyer donc 16.66% au total et donc une part propriétaire de 8.33%. Ces frais sont appliqués tous les 3 ans en l'absence de vacance locative.",
            },
            assuranceli: {
              label: 'GRL/GLI - Assurance loyers impayés',
              subLabel: '(HT)',
              tooltip:
                "Le coût de l'assurance loyers impayés en % du loyer hors taxes (HT). Un taux habituellement pratiqué est 3%.",
              hint: '% du loyer HC (taux habituel: 3%)',
            },
            tauxmois: {
              label: 'Vacance locative',
              tooltip:
                "Le nombre de mois par an durant lesquels l'appartement sera vacant (vide). Cela permet de prévoir les vacances locatives dans le calcul.",
            },
            pno: {
              label: 'Assurance propriétaire non occupant',
              subLabel: '(PNO)',
              tooltip:
                "Assurance qui permet d'assurer l'appartement lorsqu'il est vide ou dans certains cas non pris en charges par l'assurance locataire.",
            },
            valorisation: {
              label: 'Augmentation annuelle du loyer',
              tooltip: "Permet de simuler l'augmentation annuelle du loyer pour le calcul année par année.",
            },
            tauxemprunt: {
              label: 'Taux nominal',
              tooltip: 'Correspond au taux nominal de l’emprunt hors assurance.',
            },
            valorisationc: {
              label: 'Augmentation annuelle des charges',
              tooltip:
                "Permet de simuler l'augmentation annuelle des charges pour le calcul année par année.",
            },
            valorisations: {
              label: 'Augmentation annuelle des revenus',
              tooltip: "Permet de simuler l'augmentation annuelle de vos revenus de salaires.",
            },
            typeemprunt: { label: "Type d'emprunt" },
            emprunt110: {
              label: 'Emprunt 110%',
              tooltip: '',
              active: 'Activé',
              inactive: 'Désactivé',
            },
            fraisnotaire: {
              label: 'Frais de notaire',
              tooltip:
                "Indiquez le montant de frais de notaires si vous les connaissez ou s'ils sont différents de ceux calculés automatiquement. Laissez vide pour un calcul automatique.",
            },
            tauxmi: {
              label: "Votre taux marginal d'imposition",
              tooltip:
                "Ce taux ne sera pris en compte que si vous n'avez pas indiqué de revenu plus haut. En se basant sur un taux fixe, les calculs seront moins précis.",
            },
            reventeannee: { label: 'Revente du bien au bout de' },
            fonciers2: {
              label: 'Autres revenus fonciers imposables',
              tooltip:
                "Si vous avez d'autres revenus fonciers, indiquez le montant imposable ici (net de charges etc.).",
              state: 'Actif | Inactif',
            },
            reventeprix: { label: 'Au prix de' },
            charges_rec_loc: {
              label: 'Charges récupérables locataire',
              subLabel: '(en + du loyer)',
            },
            charges_syndic_loc: {
              label: 'Charges syndic totales',
            },
            charges_non_prevues: {
              label: 'Charges non prévues estimées',
            },
            estate_cost: {
              label: 'Coût du mobilier',
            },
            your_revenues: {
              label: 'Vos revenus nets imposables',
            },
            second_revenues: {
              label: '2ème revenus (si couple)',
            },
            parts_number: {
              label: 'Nombre de parts',
            },
            children_number: {
              label: 'Enfants à charge',
            },
            frais_owner: {
              label: 'Frais propiétaire (re-)location',
            },
            vacancy_duration: {
              label: 'Vacance (durée)',
            },
            vacancy_period: {
              label: 'Vacance (périodicité)',
            },
            tauxps: {
              label: 'Taux de prélèvements sociaux',
            },
            thermalRenovation: {
              label: 'Travaux de rénovation thermique',
              tooltip:
                "Valable pour des travaux de rénovation thermique permettant de passer d'une classe énergétique E, F ou G, à une classe de performance énergétique A, B, C ou D, dont le devis est payé entre le 1er janvier 2023 et le 31 décembre 2025.",
              disabled: "Champ réservé au régime Déficit foncier au réel / SCI à l'IR",
            },
            locAvantages: {
              rentLevel: {
                label: 'Niveau de loyer',
                tooltip:
                  'Plus le montant du loyer demandé est bas, plus la réduction d’impôt est importante.',
                howToDefineLevel: 'Comment définir le niveau de loyer ?',
              },
              intermediation: {
                label: 'Recours à une intermédiation locative',
                tooltip:
                  "Si vous avez recours à une intermédiation locative (agence immobilière sociale ou association agrée par l'État), vous bénéficiez d’une réduction d’impôt plus importante.",
              },
              info: "Plus d'informations sur ce régime",
            },
          },
          defaultParams: {
            active:
              'Ces paramètres sont utilisés automatiquement dans les calculs. Vous pouvez les modifier à votre guise. Laissez vide pour laisser le calculateur décider.',
            activate: 'Activer les paramètres par défaut du simulateur',
            autoCalculation: 'Calculs automatiques simulateur',
            autoCalculationQuestion: 'Voulez vous activer les calculs automatiques dans le simulateur ?',
            source2018: 'Prélèvement à la source (2018/2019)',
            activateSource2018:
              'Activer la prise en compte du prélèvement à la source (2019) pour le sort des travaux effectués en 2018/2019 (CIMR)',
            plafondLoyer: 'Plafonnement du loyer',
            activatePlafondLoyer: 'Activer le plafonnement automatique du loyer ?',
            otherIncome: 'Autres revenus',
            otherIncomeQuestion:
              'Prise en compte de la fiscalité de votre patrimoine existant dans les calculs (explications )?',
            autoEmprunt: 'Taux emprunt automatiques',
            emprunta: 'Taux Emprunt {year} ans',
            revente: 'Scénario revente automatique',
            reventeLabel: 'Valorisation annuelle',
            reventeTooltip:
              'Valorisation (ou dépréciation si valeur négative) annuelle du prix pour le calcul automatique de revente à 10 ans',
            assuVie: 'Assurance Vie',
            assuVieLabel: 'Taux assurance vie',
            assuVieTooltip: 'Taux assurance vie (utilisé pour la comparaison)',
            reducCGALabel: 'Mode de déduction',
            reducCGATooltip: 'Frais CGA et comptable',
            reducCGAOption1: 'Réduction impôt',
            reducCGAOption2: 'Charges déficit',
            regimes: 'Régimes LMNP / LMP / SCI',
            cfe: 'CFE',
            cfeTooltip: 'Cotisation foncière des entreprises',
            adCGA: 'Adhésion à un CGA ?',
            adCGATooltip: 'Adhésion à un CGA ?',
            cga: "Coût de l'adhésion à un CGA",
            comptable: 'Comptable (tarif annuel)',
            amoGODuree: 'Durée amortissement gros oeuvre',
            amoFacDuree: 'Durée amortissement façades et étanchéité',
            amoEquDuree: 'Durée amortissement équipements',
            amoAgDuree: 'Durée amortissement agencements et meubles',
            amoGOPourcentage: '% du prix amortissement gros oeuvre',
            amoFacPourcentage: '% du prix amortissement façades et étanchéité',
            amoEquPourcentage: '% du prix amortissement équipements',
            amoAgPourcentage: '% du prix amortissement agencements et meubles',
            pTerrain: '% du prix pour le terrain',
            pDividendes: 'Pourcentage des dividendes',
            pDividendesTooltip: 'Pourcentage des bénéfices distribués chaque année en dividendes',
            pDividendesRevente: 'Versement du résultat en dividendes lors de la vente ?',
            pSCICRL: 'Votre SCI est soumise à la CRL (contribution sur les revenus locatifs) ?',
            microBicTooltip: 'Abattement forfaitaire micro-BIC',
            microbic: 'Abattement Micro-BIC',
          },
          cta: 'Modifier',
          'fin-to-pin': 'Bientôt propriétaire ?',
          sections: {
            'budget-per-month': 'Budget alloué {budget} €/mois',
            'by-at': 'Acheter à',
            yourRealEstate: 'Votre bien',
            yourSituation: 'Charges et impôts',
            yourMortgage: 'Votre crédit',
            yearlyIncomes: 'Revenus {incomes}/an',
            renovationWork: 'Sans travaux | Avec travaux | Avec travaux',
            rentPerMonth: '{rent}/mois de loyer',
            charges: 'Charges totales {charges}/an',
            estateTax: 'Taxe foncière {tax}/an',
            apport: 'Apport {apport}',
            emprunt110: 'Emprunt à 110%',
            children: 'Sans enfant | 1 enfant | {n} enfants',
            fiscalPart: '{n} part fiscale',
            fiscalParts: '{n} parts fiscales',
            mortgageDuration: 'Sans durée | Durée de 1 an | Durée de {n} ans',
            mortgageRatio: 'Taux à {ratio} %',
            noMortgage: 'Pas de crédit renseigné',
            responsive: {
              'by-at': 'Acheter à',
              yourRealEstate: 'Bien',
              yourSituation: 'Charges/impôts',
              yourMortgage: 'Crédit',
            },
          },
          defaultValues: {
            description:
              'Les valeurs indiquées ici seront utilisées à chaque fois que vous lancerez une nouvelle simulation, pour gagner du temps. Pour définir une valeur par défaut, activez l’interrupteur à droite, renseignez votre valeur par défaut puis cliquez “Sauvegarder”.',
            active: 'ACTIVÉ',
            inactive: 'DÉSACTIVÉ',
          },
        },
        'simulation-history': {
          title: 'Historique & sauvegardes',
          'simulation-ready': 'Votre simulation est prête',
          'search-by-keywords': 'Rechercher par mot clé',
          history: 'Historique',
          favorites: 'Sauvegardes',
          'cancel-search': 'Annuler ma recherche',
          simulatedThe: 'Simulé le : ',
          empty: 'Vide',
          confirmDeleteFolder: 'Attention, cela supprimera les sauvegardes de ce répertoire',
          notSorted: 'Non rangées',
        },
        popovers: {
          calculationUpdated: 'Votre calcul a été mis à jour',
        },
        charts: {
          cashFlow: {
            labels: {
              rent: 'Loyer',
              mortgage: "Mensualité\nd'emprunt",
              mortgageShort: 'Emprunt',
              rentCharges: 'Charges',
              taxes: 'Impôts',
              button: {
                positive: 'Cash Flow positif',
                negative: 'Cash Flow négatif',
              },
            },
          },
        },
        pdf: {
          cover: {
            title: 'Business Plan Immobilier',
            resume: 'Résumé',
            resumeContent: {
              personalSituation: 'Situation personnelle',
            },
            keyIndicators: 'Indicateurs clés',
            toc: 'Sommaire',
            tocContent: {
              1: {
                title: 'Coût',
                description:
                  'Cashflow mensuel 2ème année - Evolution par année - Investissement VS placement financier avec revente - Cashflow annuel sans revente',
              },
              2: {
                title: 'Rentabilité',
                description: 'Rendement locatif - Rendement patrimonial',
              },
              3: {
                title: 'Impôts',
                description: 'Impact (hausse/baisse) - Evolution par année',
              },
              4: {
                title: 'Emprunt',
                description: 'Résumé (investissement) - Durée optimale',
              },
              5: {
                title: 'Fiscalité',
                description: 'Meilleur régime - Comparatif',
              },
              6: {
                title: 'Revente',
                description: 'Plus-value - Quand revendre ?',
              },
              7: {
                title: 'Prix d’achat',
                description: 'Prix du marché -Négocier le prix',
              },
              8: {
                title: 'Loyer',
                description: 'Prix du marché - Rentabilité sans vance - Rentabilité avec vacances',
              },
            },
          },
        },
      },
      'real-estate': {
        'building-start-date': ({ plural, named }) =>
          plural([`Depuis ${named('date')}`, `En ${named('date')}`]),
        dataLastUpdated: 'Dernière mise à jour le {date}',
        card: {
          surface: '{n} m²',
          tenants: ({ plural, named }) =>
            plural(['Aucun locataire', '1 locataire', `${named('n')} locataires`]),
          rent: '{sum}€ /mois',
          occupancyRate:
            " Le taux d’occupation représente le % de jours occupés de l'achat sur l'année en cours.",
          rentTooltip:
            'Correspond à la somme des loyers des locataires actuellement dans le bien, provisions pour charges comprises. <br /> Actuellement :<br />{rent}€ de loyers +<br />{provision}€ de provisions pour charges.',
          valuation:
            'La valorisation est obtenue en multipliant la surface totale par la valeur du marché pondérée.',
          netReturn:
            "Rendement net = [recettes - charges - taxe foncière - impôts - intérêt de la mensualité - assurance de la mensualité] / [Prix d’achat - frais d’acquisition] * 100 (Notaire, frais bancaires, etc).<br />Le rendement net nécessite la déclaration des revenus de l’année. Celui-ci est calculé sur l'année en cours.",
          tooltips: {
            rent: 'Recettes',
            taxe: 'Impots et Prel. Soc',
            monthly: 'Mensualités',
            socials: 'Contributions sociales',
            charges: 'Autres charges',
          },
          links: {
            operations: 'Opérations',
            documents: 'Documents',
            loans: 'Emprunts',
            options: 'Déclarations annuelles',
            params: 'Paramètres',
            checklist: 'Check list annuelle',
            group: 'Grouper mes biens',
            balance: 'Arrêté de comptes de syndic',
            income: 'Déclaration des revenus',
            summary: 'Résultats fonciers globaux',
            declaration: 'Déclarations foncières',
          },
          'rel-links': {
            mel: 'Remettre en location',
            lease: 'Créer un bail électronique',
            inventory: 'Inventaire',
            receipt: 'Paramètres des quittances',
          },
          drawer: {
            'automatic-receipt': {
              title: 'Quittance automatique',
              description:
                "Les quittances pour ce bien seront envoyées automatiquement au locataire. Vous recevrez également une copie.<br /> Si le solde du locataire n'est pas nul, la quittance ne sera pas envoyée au locataire mais à vous uniquement pour vérification.",
              warning:
                'Attention à bien indiquer le jour du mois pour lequel le loyer est attendu dans les infos du locataire. Il sera utilisé pour envoyer la quittance (avec une marge de 3 jours).',
              submit: 'Valider',
              switchWording1: 'Désactiver',
              switchWording2: 'Activer',
            },
            'building-parameters': {
              title: 'Paramètres',
              'alerts-emails': {
                label: 'Alertes emails',
                description:
                  'Alertes de confirmation de paiement des opérations marquées en automatique (comme le loyer par exemple)',
              },
              taxes: {
                label: "Taxe d'enlèvement des ordures ménagères facturée à part",
                description:
                  'Par défaut la taxe d\'enlèvement des ordures ménagères est comprise dans les charges locatives et donc dans les provisions pour charges payée par votre locataire. <br> Elle apparaîtra au moment de la régularisation des charges. <br> Si vous souhaitez la facturer à part dans la quittance du mois du paiement de la taxe foncière, il faut passer ce bouton à "ON".',
              },
              delete: {
                label: 'Suppression',
                warning:
                  'Attention : ces actions supprimeront les données de façon définitives et sans possibilité de récupération.',
                operations: "Supprimer toutes les opérations de l'immeuble",
                building:
                  "Supprimer complètement l'immeuble. (ainsi que toutes ces déclarations, operations, biens, locataires, groupes, etc.)",
                'confirm-operations':
                  "Attention: cet action supprimera toutes les opérations liées à cet immeuble (loyers, charges, etc.). C'est irréversible.",
                'confirm-all':
                  'Attention: Etes-vous sûr de vouloir supprimer cet immeuble? Toutes les données (locataires, opérations) seront supprimées. Le choix est définitif.',
              },
            },
            'check-lists': {
              title: 'Check list',
              'description-title': 'Informations complémentaires',
              description:
                "Cette liste permet de faire le point en vérifiant qu'aucune opération n'a été oubliée (par exemple avant de remplir votre déclaration). <br> Cochez les éléments terminés lorsque tout vous semble complet.",
              'select-label': 'Année',
              year: {
                label: 'Année',
                placeholder: 'Sélectionnez une année',
              },
              elements: {
                recettes: {
                  label: 'Recettes',
                  rent: 'Loyer HC',
                  CAF: 'CAF',
                },
                provisions: {
                  label: 'Provisions Syndic',
                  syndic: 'Provision Syndic',
                  syndicUnexpected: 'Provision Syndic Non Prévue',
                },
                renovation: {
                  label: 'Travaux éventuels',
                  'real-estate': 'Travaux Déduc. dans bien',
                },
                taxes: {
                  label: 'Taxes Foncière',
                },
                agency: {
                  label: 'Dépenses Gestion Agence',
                  gestion: 'Gestion',
                },
                regulations: {
                  label: 'Régulation de charges',
                  debit: 'Régul. Débit Locataire',
                  credit: 'Régul. Crédit Locataire',
                },
              },
              see: 'Voir',
              add: 'Ajouter',
            },
          },
        },
      },
      'real-estate-ads': {
        title: 'Annonces immobilières',
        ads: {
          title: 'Annonces immobilières',
          content:
            'Trouvez votre futur investissement parmi les annonces dont nous avons connaissance. <br /> Filtrez votre recherche par taux de rendement, prix au m², etc.',
          cta: 'Consulter',
        },
        'create-alert': {
          title: 'Créer une alerte',
          fullTitle: '<strong>Créer une alerte</strong> pour ne plus les manquer !',
          content:
            "Soyez alerté dès que nous avons connaissance<br/>d'un bien correspondant à votre recherche.",
          cta: 'Paramétrer',
        },
        alerts: {
          title: 'Vos alertes',
          content: 'Retrouvez la liste de vos alertes immobilières actives.',
          cta: 'Consulter',
        },
      },
      'real-estate-market': {
        title: 'Marché immobilier',
        cta: 'Consulter',
        'what-kind': {
          title: "Quel type d'investissement faire ?",
          content:
            "Nous déterminons quel serait le meilleur choix d'investissement pour vous selon vos revenus et les villes où vous souhaiteriez investir.\n",
        },
        where: {
          title: 'Où investir ?',
          content:
            'Retrouvez nos analyses du marché locatif en France pour vous aider à faire votre choix.\n',
        },
        'active-search': {
          title: 'En recherche active ?',
          content1:
            'Découvrez notre extension disponible sur les plus grands sites de ventes immobilières : ',
          content2: 'Visualisez instantanément le rendement des annonces immobilières que vous consultez.',
          content3: 'Gratuit et sans inscription.',
          mobile: 'Profitez de cette expérience sur votre ordinateur',
          'mobile-notification': 'Cette expérience n’est disponible que sur votre ordinateur.',
          functionalities: {
            yield: 'Rendement brut',
            price: 'Prix au m²',
            rent: 'Loyer estimé',
            market: 'Tendance marché',
          },
        },
      },
      'real-estate-types': {
        appartement: 'Appartement',
        maison: 'Maison',
        immeuble: 'Immeuble',
        parking: 'Parking',
      },
      'tax-regimes': {
        rr: "Déficit foncier au réel / SCI à l'IR",
        locAvantages2022: 'Loc’Avantages',
        mf: 'Micro-foncier',
        me: 'LMNP micro-bic',
        mer: 'LMNP au réel',
        mepr: 'LMP au réel',
        ni: 'Non imposable',
        norm6: 'Denormandie location 6 ans',
        norm9: 'Denormandie location 9 ans',
        norm12: 'Denormandie location 12 ans',
        sciis: "SCI/SARL à l'IS",
        pin6: 'Pinel location 6 ans',
        pin9: 'Pinel location 9 ans',
        pin12: 'Pinel location 12 ans',
        cointer: 'Cosse ancien intermédiaire',
        cosocial: 'Cosse ancien social',
        cotressocial: 'Cosse ancien très social',
        cointermed: 'Cosse ancien intermédiation',
        malss: 'Malraux Secteur Sauvegardé (30%)',
        malzp: 'Malraux ZPPAUP (22%)',
        cens: 'Censi-Bouvard',
        duf: 'Duflot',
        bo30: 'Borloo ancien 30%',
        bo60: 'Borloo ancien 60%',
        bo70: 'Borloo ancien 70%',
        se2009: 'Scellier 2009-2010',
        se2011: 'Scellier 2011',
        se2011b: 'Scellier 2011 BBC',
        se2012: 'Scellier 2012',
      },
      topbar: {
        trial: {
          cta: 'Souscrire',
          daysRemaining: '1 jour restant | {days} jours restants',
          isTrialing: 'Actuellement en offre d’essai',
        },
      },
      widgets: {
        'estimated-rent': {
          defaultFooter:
            'Si votre bien possède des caractéristiques particulières (parking, piscine, prestations luxueuses, etc.), cela est également à prendre en compte lorsque vous fixerez le montant de votre loyer.',
          highFooter: {
            warning: 'Attention,',
            text: 'un loyer élevé entraîne des changements de locataires plus fréquents. ',
          },
          lowFooter:
            "Votre loyer se situe dans la tranche basse de ce qui est pratiqué dans votre ville pour un bien d'une même surface.",
          middleFooter: 'Vous louez au juste prix !',
          moreInformation: 'En savoir plus sur le marché',
          rent: 'Votre loyer : ',
          surfaceArea: 'Pour un bien de ',
          title: "L'immobilier à {city}",
          'not-enough-data': 'Désolé, nous n’avons pas encore de données sur {city}',
          veryLowFooter: {
            prefix: 'Vous pourriez louer votre',
            suffix: 'bien plus cher.',
          },
        },
      },
      tutorial: {
        skip: "Passer l'accompagnement",
        next: 'Suivant',
        finish: 'Terminer',
        'fin-to-pin': {
          'welcome-pin-title': 'Bienvenue dans la gestion locative !',
          'presentation-part-1': 'Cet outil va vous aider à gérer vous-même votre bien.',
          'presentation-part-2': 'J’ai repris les informations de votre simulation !',
          'presentation-part-3': 'Vous n’avez plus qu’à vous installer dans votre fauteuil.',
          'find-tenant-part-1': 'Vous avez déjà trouvé votre locataire ?',
          'find-tenant-part-2': 'Enregistrez-le en allant par là.',
          'help-part-1': "Besoin d'un coup de pouce ?",
          'help-part-2': 'On est là pour vous aider.',
        },
        'performance-page': {
          introduction: {
            title: 'Rapport de performance',
            content:
              'Nous vous souhaitons la bienvenue dans ce rapport de performance. Vous y retrouverez un ensemble d’indicateurs correspondant à la performance de tout ou partie de votre patrimoine.' +
              '<br />' +
              '<br />' +
              'Les indicateurs sont actualisés régulièrement, vous trouverez la date de dernière mise à jour en haut à gauche de votre écran.' +
              '<br />' +
              '<br />' +
              'Pour obtenir un rapport clair et exhaustif, vous devez renseigner correctement : l’ensemble de vos emprunts, de vos déclarations d’impôts, de vos locataires ainsi que toutes les opérations associées. ',
          },
          'general-indicators': {
            content:
              'Retrouvez d’abord l’ensemble de vos chiffres clés à date d’aujourd’hui. Pour chaque indicateur, une explication détaillée est fournie. Le calcul est actualisé une fois par jour.',
          },
          charts: {
            content:
              'Pour chaque indicateur, deux formats sont proposés : en graphique ou en tableau. La première année correspond à l’année d’achat du bien le plus vieux.',
          },
          filters: {
            content:
              'Enfin, vous pouvez aussi “filtrer” les données référentielles ou encore les “comparer” avec une ou plusieurs autres donnée(s) spécifique(s).',
          },
        },
      },
      sellAd: {
        report: {
          superiorRate: {
            title: "A la recherche d'un <span>bien rentable</span> ?",
            text: 'Bénéficiez de notre service clé en main personnalisé.',
            button: 'Me faire accompagner',
          },
          inferiorRate: {
            title: 'Nous avons des <span>projets plus rentables</span> à vous proposer.',
            text: "Ce projet semble avoir un faible rendement. Nous avons de meilleures opportunités d'investissement pour vous.",
            button: 'Découvrir',
          },
        },
      },
    },
    stripeCodes: {
      invalid_promo_code: {
        default: "Ce code promo n'est pas valide",
        expired: 'Ce code promo est expiré',
        minimumAmount: "Le montant de la commande n'est pas assez élevé pour utiliser ce code promo",
        nonExistant: "Ce code promo n'existe pas",
        notFirstSub: 'Ce code promo est utilisable uniquement lors du premier abonnement.',
        usedUp: "Le nombre maximal d'utilisation de ce code promo a été atteint",
        notEligible: "Ce code promo n'est pas valable sur cette offre",
        notForThisCustomer: "Ce code promo n'est pas disponible pour cet utilisateur",
      },
      insufficient_funds: 'Vos fonds sont insuffisants',
      lost_card: 'Votre carte est déclarée perdue',
      stolen_card: 'Votre carte est déclarée volée',
      card_declined: 'Votre carte a été refusée',
      already_sub: 'Vous êtes déjà abonné',
      processing_error: 'Une erreur est survenue lors du paiement, veuillez réessayer plus tard',
    },
    legacy: {
      'nav-building-finances': 'Opérations',
      'nav-building-global-report': 'Rapport',
      'nav-building-page': 'Mon Achat',
      'nav-building-investment': 'Mon Achat',
      'nav-building-loans': 'Mes emprunts',
      'nav-building-edit': 'Editer mon achat',
      'nav-building-real-estate-add': 'Ajouter un bien',
      'nav-building-real-estate-edit': 'Éditer mon bien',
      'nav-building-declaration': "Déclaration d'impôts",
      'nav-building-balance-sheet': 'Arrêté des comptes de syndic',
      'nav-building-summary': 'Résumé général des opérations par année',
      'nav-building-add-investment': 'Ajouter un achat',
      'nav-building-group': 'Grouper mes biens',
      'nav-churn': 'Désabonnement',
      'nav-end-mel': 'Cloturer la mise en location',
      'nav-real-estate-edit': 'Editer mon bien',
      'nav-real-estate-inventory': 'Inventaire du mobilier',
      'nav-real-estate-mel': 'Recherche de locataire',
      'nav-real-estate-add-lease': 'Ajouter un bail',
      'nav-real-estate-decoration': 'Décorer votre intérieur',
      'nav-real-estate-add-ad': 'Annonce',
      'nav-tenant-add-payment-label': 'Ajouter un loyer',
      'nav-tenant-add-payment': 'Ajouter un loyer',
      'nav-tenant-add': 'Ajouter un locataire',
      'nav-tenant-accounting': 'Solde Locataire',
      'nav-tenant-add-operation': 'Ajouter une opération',
      'nav-tenant-add-operation-label': 'Ajouter une opération',
      'nav-tenant-add-receipt': 'Rédiger une quittance',
      'nav-tenant-add-receipt-label': 'Rédiger une quittance',
      'nav-tenant-edit-operation': 'Modifier une opération',
      'nav-tenant-balance': 'Opérations',
      'nav-tenant-balance-label': 'Voir paiements',
      'nav-tenant-edit': 'Editer le locataire',
      'nav-tenant-edit-label': 'Editer le locataire',
      'nav-tenant-edit-day-rent': 'Editer le locataire',
      'nav-tenant-emails': 'Emails envoyés',
      'nav-tenant-document-add': 'Générer un document',
      'nav-tenant-document-add-label': 'Générer un document',
      'nav-tenant-documents': 'Documents',
      'nav-tenant-documents-label': 'Mes documents',
      'nav-tenant-revision': 'Réviser le loyer',
      'nav-tenant-revision-label': 'Réviser le loyer',
      'nav-tenant-regularize': 'Régulariser les charges',
      'nav-tenant-regularize-label': 'Régulariser les charges',
      'nav-user-bank': 'Banque',
      'nav-user-income': 'Revenus',
      'nav-user-profile': 'Mon profil',
      'nav-user-add-identity': 'Ajouter une identité',
      'nav-user-subscription': 'Mon abonnement',
      'nav-view-ad': "Aperçu de l'annonce",
      'documents-saved': 'Vos documents sauvegardés',
      'documents-bail-1': 'Bail de location nue (pré-rempli)',
      'documents-bail-2': 'Bail de location nue (vide)',
      'documents-bail-3': 'Bail de location meublée (pré-rempli)',
      'documents-bail-4': 'Bail de location meublée (vide)',
      'documents-bail-5': 'Bail de location mobilité (pré-rempli)',
      'documents-bail-6': 'Bail de location mobilité (vide)',
      'documents-charges': 'Liste des charges récupérables',
      'documents-rental-repair': 'Liste des réparations locatives',
      'documents-obligations-before-exit': 'Rappel obligations avant sortie',
      'documents-inventory-of-entry': "Etat des lieux d'entrée",
      'documents-inventory-of-exit': 'Etat des lieux de sortie',
      'documents-joint-surety-1': 'Caution solidaire (pré-remplie)',
      'documents-joint-surety-2': 'Caution solidaire (vide)',
      'documents-flat-sharing-1': 'Bail colocation nue (pré-rempli)',
      'documents-flat-sharing-2': 'Bail colocation meublée (pré-rempli)',
      'documents-flat-sharing-3': 'Bail colocation mobilité (pré-rempli)',
      'documents-flat-sharing-4': "Etat des lieux d'entrée colocation",
      'documents-flat-sharing-5': 'Etat des lieux de sortie colocation',
      'documents-flat-sharing-6': 'Caution solidaire (pré-remplie) colocation',
      'documents-flat-sharing-7': 'Avenant au bail - départ locataire',
      'documents-flat-sharing-8': 'Avenant au bail - changement de locataire',
      'documents-tenant-list-placeholder': 'Locataire actuel',
      'documents-account-balance': 'Solde du Compte',
      'documents-receipt-of-deposit': 'Reçu du dépôt de garantie',
      'documents-proof-of-insurance': "Demande de justificatif d'assurance",
      'documents-certificate-of-rent': 'Attestation de loyer',
      'documents-refund-tax-household-waste': 'Remboursement Taxe Ordures Ménagères',
      'documents-claim-for-unpaid-rent': 'Reclamation loyer impayé',
      'documents-furniture-inventory': 'Inventaire des meubles',
    },
    lightboxContent: {
      collectFirstRent: {
        'main-title': 'Empochez rapidement votre 1er loyer !',
        'subtitle-1': 'Une nouvelle page se tourne :\n',
        'subtitle-2':
          'vous allez maintenant commencer à rentabiliser votre bien. Horiz.io peut vous y aider !',
        'subtitle-3': 'Il nous manque une seule chose :\n',
        'subtitle-4': "L'adresse de votre bien afin de préparer vos documents administratifs",
        'find-tenant': 'Trouvez votre\nlocataire facilement',
        'rental-contract': 'Créez votre contrat\nde location complet',
        'insurance-cover': 'Soyez couvert\nen cas de problème',
        'submit-1': "C'est parti !",
        'submit-2': "J'y vais !",
      },
    },
    modify: 'Modifier',
    month: 'mois',
    'more-from-our-partner': 'En savoir plus sur la confidentialité de notre partenaire bancaire',
    'nav-real-estates': 'Mon patrimoine',
    'nav-dashboard': 'Tableau de bord',
    'nav-document-templates': 'Modèles de documents',
    'nav-documents': 'Documents',
    'nav-finances': 'Finances',
    'nav-housing-market': 'Marché immobilier',
    'nav-locataires': 'Locataires',
    'nav-loggout': 'Se déconnecter',
    'nav-profile': 'Mon profil',
    'nav-property-ads': 'Annonces immobilières',
    'nav-report': 'Rapport de rentabilité',
    'nav-ideal-property': 'Projet idéal à réaliser',
    'nav-signin': 'Se connecter',
    'nav-signup': "S'inscrire",
    'nav-investor-area': 'Espace investisseur',
    'nav-tenant-area': 'Espace locataire',
    'nav-opinion': 'Donner mon avis',
    'nav-help': 'Aide',
    'nav-bank': 'Compte bancaire',
    'nav-income': 'Mes revenus',
    'nav-subscription': 'Mon abonnement',
    'nav-ideal-project': 'Trouver un bien',
    'nav-operations': 'Mes opérations',
    'nav-performance': 'Performance détaillée',
    no: 'non',
    of: 'de',
    operations: {
      lightbox: {
        'add-tenant': 'Ajouter un locataire',
        'add-mortgage': 'Ajouter un emprunt',
        'add-operation': 'Ajouter une opération',
        'change-operation': 'Modifier une opération',
        operation: 'Opération',
        purchase: 'Achat',
        tenant: 'Locataire',
        loan: 'Emprunt',
        amountFull: 'Montant reçu',
        amount: 'Montant',
        rent: 'Loyer',
        withoutCharges: 'Hors charges',
        charges: 'Charges',
        TEOM: "Dont taxe d'ordures ménagères",
        date: 'Date de versement',
        dateStart: 'Début période charges',
        dateEnd: 'Fin période charges',
        recurrenceDateEnd: 'Fin de la récurrence',
        repeat: "Répéter l'opération tous les mois",
        descPlus: '+ Ajouter une description',
        descMinus: '- Description',
        description: 'Description',
        operationAdded: 'Opération ajoutée avec succès',
        operationUpdated: 'Opération modifiée avec succès',
        descriptionLength: 'caractères restants',
        validate: 'Valider',
        alreadyPaid: 'Montant déjà payé',
        totalAmount: 'Montant total',
        select: {
          building: 'Selectionner un achat',
          mortage: 'Selectionner un emprunt',
          tenant: 'Selectionner un locataire',
          operation: 'Selectionner une opération',
        },
        repeatOperation:
          'A partir du {date}, une opération de {amount}€ sera créée tous les mois. | Opération répétée pour {months} mois soit un total de {totalAmount}€.',
        repeatOperationError: 'Veuillez indiquer une date de versement.',
        synchronized: 'Synchro bancaire <span style="color:#5dd26d">activée</span>',
        willBeLinked: 'Sera liée à la transaction bancaire :',
      },
      cashflow: {
        income: 'Rentrées ',
        outcome: 'Sorties ',
        ofMoney: 'd’argent ',
        since: 'depuis le ',
        moneyIncome: 'Rentrées d’argent',
        moneyOutcome: 'Sortie d’argent',
      },
      operationTypes: {
        rent: 'Loyers',
        charges: 'Prov. charges',
        property_tax: 'Taxe foncière',
        caf: 'CAF',
        work: 'Travaux',
        loan: 'Échéances emprunts',
        other: 'Autres',
      },
    },
    password: 'Mot de passe',
    optional: 'Facultatif',
    perMonthChargesIncluded: '{rentCI} / mois CC',
    'profile-access': 'Accéder à mon profil',
    'profile-informations': 'Mes informations',
    'profile-nav-income': 'Déclaration de revenus',
    'profile-nav-info': 'Informations',
    'property-address': 'Adresse du bien',
    'property-charge': 'Charges mensuelles',
    'property-entry-date': "Date d'entrée : JJ / MM / AA",
    'property-firstname': 'Prénom',
    'property-lastname': 'Nom',
    'property-purchase-price': "Prix d'achat",
    'property-purchase-price-description':
      'Voici notre estimation par rapport aux données de marché. Cette estimation suppose que vous avez acheté ce bien récemment. Vous pouvez la remplacer par le prix exact si vous le connaissez.',
    'property-rent': 'Loyer hors charges',
    'property-surface': 'Surface',
    'property-tenant-email': 'Adresse email du locataire',
    pinTrialOffer: {
      messages: {
        'trial-ending-in': 'Votre période d’essai se termine dans {0}.',
        'x-days': '{remainingDays} jours',
        'subscribe-now': 'M’abonner dès maintenant.',
        'extend-experience': 'Prolonger l’expérience.',
        'trial-in-grace-period': 'Votre période d’essai est terminée et votre compte va être {0}.',
        'deactivate-soon': 'désactivé prochainement',
        'continue-manage-estates': 'Continuer de gérer mes biens',
      },
      modal: {
        title: 'Gérer vos biens devient facile !',
        body: {
          'line-1': {
            text: 'Profitez {0} de notre plateforme et de tous ses avantages {1}.',
            free: 'gratuitement',
            duration: 'pendant {duration}',
          },
          'line-2':
            'Rédaction de bail, signature électronique, envoi de quittance automatique, alerte de non paiement des loyers, etc !',
        },
        action: 'Essayer',
      },
    },
    'purchase-at': 'Achat au',
    'realEstates-estimated-value': 'Valeur estimée des biens',
    'realEstate-complete-label': '{type} de {surfaceArea}m² à {city}',
    'realEstate-furnished': 'Meublé',
    'realEstates-header-line':
      "En ajoutant un bien vous pourrez suivre son évolution sur le marché de l'immobilier, gérer sa fiscalité, suivre sa comptabilité...",
    'realEstates-menu-appartement': "l'appartement",
    'realEstates-menu-maison': 'la maison',
    'realEstates-menu-duplex': 'le duplex',
    'realEstates-menu-studio': 'le studio',
    'realEstates-menu-parking': 'le parking',
    'realEstates-menu-local': 'le local',
    'realEstates-menu-cave': 'la cave',
    'realEstates-menu-terrain': 'le terrain',
    'realEstates-menu-edit': 'Editer {0}',
    'realEstates-menu-edit-invest': "Modifier l'investissement",
    'realEstates-menu-inventory': 'Inventaire du mobilier',
    'realEstates-menu-re-rental': 'Remettre en location',
    'realEstates-menu-lease': 'Créer un bail électronique',
    'realEstates-menu-receipts-params': 'Paramètres des quittances',
    'realEstates-menu-decorate': 'Décorer {0}',
    'realEstate-not-furnished': 'Non meublé',
    'realEstate-other-tenants': '+1 autre locataire | +{activeTenantsNumber} autres locataires',
    'realEstate-rent-by-month': '/mois',
    'realEstate-rent-cc': 'cc',
    'realEstate-rent-charges-included': '/mois cc',
    'realEstate-rent-only-bubble': 'loyer',
    'realEstate-rent-only-tooltip': 'Loyer seul',
    'realEstate-rent-charges-only-bubble': 'charges',
    'realEstate-rent-charges-only-tooltip': 'Charges locatives',
    'realEstate-rent-full-tooltip': 'Montant total charges comprises',
    'realEstate-short-label': '{type} de {surfaceArea}m²',
    synchro: {
      'boursorama-disclaimer':
        '<span>Votre banque rencontre régulièrement des problèmes et peut transmettre plusieurs fois la même transaction. Ce défaut d’affichage est souvent résolu sous 48h. Si vous constatez toujours le souci passé ce délai, n’hésitez pas à <a style="text-decoration: underline" href="https://support.horiz.io/hc/fr/requests/new" target="_blank">nous contacter.</a></span>',
    },
    select: {
      'no-items': 'Aucun choix disponible',
    },
    'square-meter': 'm²',
    'ideal-project': {
      title: 'Trouver un bien',
      description:
        'Accédez à toutes les annonces immobilières en temps réel, la rentabilité en plus ! Pour chaque annonce immo : rendement, prix au m2 et loyer envisageable.',
      search: {
        'that-is': 'Soit',
        'per-month': '€/mois',
        budgetTooltip: 'En cas de financement sur 20 ans à un taux de 3,05%',
        where: 'Où souhaitez-vous investir ?',
        where_placeholder: 'Ville ou code postal',
        budget: 'Quel est votre budget maximum ?',
        cta: 'Rechercher',
        withWork: 'Avec travaux',
        exclusive: 'Annonces exclusives seulement',
        alreadyRented: 'Déjà loué',
        minPrice: 'Prix mini',
        settings: 'Paramètres avancés',
        surface: 'Surface',
        squareMeterPrice: 'Prix au m2',
        rawPerformance: 'Rendement brut',
        distance: {
          label: 'Dans un rayon autour de ',
          department: 'Département',
          region: 'Région',
          aroundMe: 'Autour de moi',
          geoError: 'Veuillez autoriser la géolocalisation sur votre navigateur.',
          submit: 'Valider',
        },
        specificity: 'Spécificités',
        type: 'Type de bien',
        types: {
          apartment: 'Appartement',
          building: 'Immeuble',
          home: 'Maison',
          parking: 'Parking',
        },
        exclusiveTitle: 'Annonces premium',
      },
      summary: {
        budget: 'Votre budget',
        total: '{total} annonce trouvée | {total} annonces trouvées',
        advice: {
          1: 'Votre budget semble insuffisant pour investir ici.',
          2: 'Votre budget semble légèrement inférieur au budget moyen observé.',
          3: 'Votre budget semble adapté à votre recherche.',
          4: 'Votre budget semble élevé par rapports aux résultats.',
        },
        callExpert: 'En discuter avec un expert',
      },
      ad: {
        overBudget: 'Au dessus du budget',
        underBudget: 'En dessous du budget',
        rent: 'Se loue {loyer}€/mois',
        isRented: 'Déjà loué',
        renovationWork: 'Avec travaux',
        calculate: 'Calculer le rendement net',
        example: "A titre d'exemple",
        dateDistance: 'Il y a',
        exclusivity: 'EXCLUSIVITÉ',
        exclusivityCta: 'Prendre rendez-vous',
      },
      empty: {
        desc: 'Aucune annonce ne correspond, pour l’instant, à votre recherche.',
      },
      help: 'Remplissez d’abord les informations au-dessus !<br>Les résultats s’afficheront ici.',
      handleAnnouncesPro: 'Gérer mes annonces',
    },
    finDashboard: {
      newSimulation: {
        cityPlaceholder: 'Ville ou code postal',
        pricePlaceholder: 'Prix du bien',
        rentPlaceholder: 'Loyer',
        surfacePlaceholder: 'Surface',
      },
    },
    tenants: {
      alerts: {
        negativeBalance: 'Solde négatif',
        onDeparture: 'Sur le départ',
        signatureInProgress: 'Signature en cours',
      },
    },
    'tenants-add': 'Ajouter un locataire',
    'tenants-details': 'Détails du locataire',
    'tenants-balance-at': 'Solde au',
    'tenants-balance-account': 'Voir le solde',
    'tenants-current-rent': 'loyer du mois',
    'tenants-next-rent': 'paiement prochain loyer',
    'tenants-electronic-lease': 'Signer le bail électroniquement',
    'tenants-check-in': 'État des lieux d’entrée',
    'tenants-send-urging-email': 'Envoyer un email de relance',
    'tenants-looking-for-tenant': 'Chercher un nouveau locataire',
    'tenants-filter-by-property': 'Filtrer par biens',
    'tenants-filter-by-tenants': 'Filtrer par locataires',
    'tenants-filter-by-status': 'Filtrer par statut',
    'tenants-filter-by-balance': 'Filtrer par solde',
    'tenants-current-tenants': 'Locataires actuels',
    'tenants-former-tenants': 'Anciens locataires',
    'tenants-uptodate-payments': 'Paiements à jour',
    'tenants-late-payments': 'Paiement en retard',
    'tenants-header-line':
      "En ajoutant un locataire, vous pourrez par exemple activer l'envoi automatique des quittances de loyer, suivre les paiements de vos locataires, gérer le suivi de vos charges locatives...",
    'tenants-lease-signature': 'signature bail',
    'tenants-entry-date': 'entrée du locataire',
    'tenants-end-date': 'départ du locataire',
    'tenants-duration-entry-date': 'Depuis {0}',
    'tenants-duration-entry-date-coming': 'Dans {0}',
    'tenants-duration-end-date': "Jusqu'au {0}",
    'tenants-menu-caf': 'CAF',
    'tenants-menu-edit': 'Modifier le locataire',
    'tenants-menu-lease': 'Bail électronique',
    'tenants-menu-other-payments': 'Autres paiements',
    'tenants-menu-rent': 'Loyer',
    'tenants-menu-show-payments': 'Voir paiements',
    'tenants-rent': 'Loyer <b>{0}</b> dû le <b>{1} du mois</b>',
    'tenants-rent-by-month': '/mois',
    'tenants-rent-charges-included': ' cc',
    'tenants-rent-charges-only-bubble': 'charges',
    'tenants-rent-charges-only-tooltip': 'Charges locatives',
    'tenants-rent-full-tooltip': 'Montant total charges comprises',
    'tenants-rent-only-bubble': 'loyer',
    'tenants-rent-only-tooltip': 'Loyer seul',
    'tenants-reset-filters': 'Supprimer les filtres',
    'tenents-sort-by-entry-date': "Trier par date d'entrée",
    'tenant-email-rgpd-tooltip':
      'Nous utiliserons l’adresse email de votre locataire pour lui envoyer, en votre nom, des emails d’information exclusivement dans le cadre de votre relation de bailleur avec lui (quittance de loyer, solde de tous comptes, etc). Vous serez toujours informé de l’envoi de ces emails et pourrez librement les désactiver.',
    tenantRentingSummary: {
      begin: 'Vous êtes {tenant}',
      tenant: 'locataire',
      surface: 'D’un {surface} à {city}',
      rent: 'Pour un loyer de',
      month: '{rent}€/mois',
    },
    tenantsAdd: {
      title: 'Ajouter un locataire',
      building: 'Bien concerné',
      realEstates: 'Biens',
      submit: 'Valider',
      successMessage: 'Locataire ajouté !',
    },
    'tenant-area-title': 'Continuer à louer ou acheter ?',
    'tenant-area-page-title': 'Acheter ou Louer',
    'tenant-area': {
      header: {
        partner: 'Partenaire',
      },
    },
    'tenant-balance': 'Solde du locataire',
    'trial-ended-modal': {
      title: 'Votre période d’essai a pris fin',
      body: 'Continuer de gérer vos biens de façon automatisée en vous abonnant à tous nos services :',
      list: {
        1: 'envoi de quittances automatique',
        2: 'détections des impayés de loyer',
        3: 'déclaration foncière pré-remplie',
      },
      action: 'M’abonner',
    },
    to: 'au',
    today: "Aujourd'hui",
    todo: 'À faire',
    'validation-desc': 'Votre mot de passe doit contenir :',
    validation: {
      beforeToday: 'La date de début doit être antérieure à la date du jour',
      email: 'Cette adresse mail est invalide.',
      min: '{length} caractères en tout',
      required: 'Ce champ est obligatoire.',
      'valid-city-postal-code': 'Veuillez sélectionner une ville dans la liste',
    },
    'validation.email-already-exist': 'Cette adresse email existe déjà',
    'validation.email-or-username': 'Adresse email incorrecte',
    'validation.password':
      'Votre mot de passe doit contenir : au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial',
    'validation.password-different':
      'Les champs mot de passe/confirmation du mot de passe ne corrrespondent pas.',
    'validation.wrong-current-password': 'Le mot de passe actuel ne correspond pas',
    'validation.old-password-equals-new': 'Vous utilisez déjà ce mot de passe',
    'validation.unknown-update-password-error': 'Impossible de mettre à jour le mot de passe',
    'validation.minOneUppercase': 'une majuscule',
    'validation.minOneLowercase': 'une minuscule',
    'validation.minOneSpecialCharacter': 'un caractère spécial parmi : @ # $ ! % * ? & . , ; / :',
    'validation.minOneNumber': 'un chiffre',
    'validation.property-address':
      "Merci de sélectionner une adresse dans la liste proposée. Si votre adresse n'apparaît pas, utilisez une autre adresse proche (nom de la rue principale voisine). Vous pourrez la modifier plus tard pour améliorer sa précision.",
    'validation.property-address-try-guess-postal-code':
      "Merci de sélectionner une adresse dans la liste proposée. Si votre adresse n'apparaît pas, utilisez une autre adresse proche (nom de la rue principale voisine). Vous pourrez la modifier plus tard pour améliorer sa précision.",
    'validation.basic-fr-date': 'Date incorrecte',
    'validation.email-exist': 'Cette adresse email/pseudo existe déjà',
    'validation.phone-number': 'Numéro de téléphone incorrect',
    'validation.biggerthan': 'La valeur saisie doit être supérieure à {min}',
    'validation.lowerOrEqual': 'La valeur saisie doit être inférieure ou égale à {max}',
    'validation.biggerOrEqual': 'La valeur saisie doit être supérieure ou égale à {min}',
    'validation.year': 'Le champs année doit être composé de quatre chiffres',
    yes: 'oui',
    yesterday: 'Hier',
    unauthorized: {
      title: "Votre abonnement ne vous permet pas d'effectuer cette action",
      link: 'Nos offres',
    },
    error: 'Une erreur est survenue !',
    tenantsPage: {
      realEstates: {
        search: 'Rechercher par bien',
        filters: 'Filtres',
        noResult: 'Aucun résultat ne correspond à votre recherche.',
        warning: {
          unleased: 'non loué',
          negativeBalance: 'solde négatif',
          onDeparture: 'sur le départ',
        },
        process: {
          signatureInProgress: 'signature en cours',
        },
        default: {
          title: 'Tous les biens',
          numberRealEstates: 'bien au total |biens au total',
          occupied: 'occupé | occupés',
          notOccupied: 'non loué |non loués',
        },
        tag: {
          alert: 'Vous avez des alertes',
          all: 'Tous les biens sélectionnés',
          selected: '{nbr} bien sélectionné | {nbr} biens sélectionnés',
        },
        realEstateSelectedUnleased: 'Votre bien est actuellement inoccupé.',
        realEstatesSelectedUnleased:
          'Vos biens sont actuellement inoccupés. Veuillez sélectionner <strong>un seul bien.</strong>',
        realEstatesSelectedUnleasedMobile:
          'Vos biens sont actuellement inoccupés.<br> Veuillez sélectionner <strong>un seul bien.</strong>',
      },
      tenants: {
        add: 'Ajouter un locataire',
        title: 'Mes locataires',
        filterByActiveTenants: 'Locataires actuels',
        filterByGoneTenants: 'partis',
        search: 'Rechercher un locataire',
        card: {
          departureDate: 'est parti(e) le {date}',
          departureDateShort: 'parti(e) le {date}',
          cta: {
            addOperation: 'Ajouter une opération',
            addOperationShort: 'Opération',
            addRentReceipt: 'Générer une quittance',
            addRentReceiptShort: 'Quittance',
            indicateDeparture: 'Indiquer le départ',
            updateRent: 'Réviser le loyer',
            documents: 'Documents',
            regulateCharges: 'Régulariser les charges',
            showMore: "Plus d'options",
            tenantInfo: 'Infos locataire',
            seeRentsHistory: "Voir l'historique des loyers",
            seeRentsHistoryShort: 'Historique des loyers',
            tenantBalance: 'Solde locataire',
          },
          drawer: {
            departure: {
              title: 'Indiquer le départ',
              submit: 'Valider',
            },
          },
        },
        searchNoResult: 'Aucun résultat ne correspond à votre recherche.',
        defaultResult: 'Résultats dans les autres locataires :',
        noTenantActions: {
          findTenant: {
            title: 'Trouver un locataire facilement',
            description:
              'Evaluation automatique du profil des candidats, récupération des pièces du dossier en ligne, et plus encore !',
            cta: 'Commencer',
          },
          addTenant: {
            title: 'Ajouter vos locataires',
            description:
              'Renseignez vos locataires pour que nous puissions <strong>vous aider à gérer votre bien.</strong>',
            cta: 'Ajouter un locataire',
          },
        },
      },
    },
    tenantDocumentsPage: {
      generateDocument: 'Générer un document',
      lease: 'Bail électronique',
    },
    operationsPage: {
      filters: {
        searchPlaceholder: 'Rechercher',
        buildings: {
          title: 'Achats',
          placeholder: 'Tous les achats',
        },
        accounts: {
          title: 'Comptes',
          placeholder: 'Tous les comptes',
        },
        tenants: {
          title: 'Locataires',
          placeholder: 'Tous les locataires',
        },
        types: {
          title: 'Types',
          placeholder: 'Tous les types',
        },
        status: {
          title: 'Statuts',
          placeholder: 'Tous les statuts',
        },
        reInitFilters: 'Réinitialiser les filtres',
        lightbox: {
          title: 'Filtres',
          validate: 'Valider',
        },
        dates: {
          title: 'Dates',
          from: 'De',
          fromMobile: 'Date de',
          until: 'À',
          untilOptional: '(falculatif)',
        },
      },
      operations: {
        actions: {
          modifyOperation: 'Modifier cette opération',
          deleteOperation: 'Supprimer cette opération',
          confirmDelete: 'Voulez-vous supprimer cette opération ?',
          successDelete: 'Opération supprimée avec succès',
          errorDelete: 'Echec de la suppression',
        },
        statusPayed: 'Payée',
        statusNotPayed: 'Non payée',
      },
      transactions: {
        actions: {
          associateTransaction: 'Associer une transaction',
          unlinkTransaction: 'Dissocier',
        },
        lightbox: {
          title: 'Vos transactions bancaires',
          account: 'Compte bancaire',
          actions: {
            modify: 'Modifier cette opération',
            unlinkOperation: "Dissocier l'opération",
            confirmUnlinkOperation: "Dissocier l'opération de cette transaction ?",
            associate: 'Associer',
            associateMobile: 'Associer une opération',
            delete: "Supprimer l'opération",
          },
          globalDisclaimer:
            '<span>Votre historique de transactions bancaires apparait ici. Cependant, si une transaction transmise par votre banque est datée de plus de 5 jours, celle-ci ne sera pas associée automatiquement. Vous devrez alors effectuer cette action manuellement.</span>',
          transactionDisclaimer: "Cette transaction n'a pas été associée automatiquement (reçue le {date}).",
          operationType: {
            auto: 'Automatique',
            recurrent: 'Récurrente',
            manual: 'Manuelle',
          },
        },
      },
      actions: {
        exportOperations: 'Exporter les opérations',
        createOperation: 'Créer une opération',
        redirectSynchro: 'Gérer mes comptes',
        createSynchro: 'Automatiser la saisie des opérations',
        seeTransactions: 'Voir mes transactions bancaires',
      },
      noOperation: {
        text1: 'Aucune opération trouvée, ',
        text2: ' ou ',
        cta: 'ajouter une opération',
        cta2: 'réinitialiser votre recherche.',
      },
    },
    bankSynchronizationPage: {
      createSynchro: {
        description:
          'Automatisez la création de vos opérations (loyers, taxe foncière, etc) <br>en reliant vos comptes bancaires.',
        cta: 'Automatiser la saisie',
        linkKnowMore: 'En savoir plus',
        benefits: {
          security: {
            title: 'La sécurité est une priorité',
            description:
              "Vos identifiants bancaires ne sont pas stockés chez Horiz.io mais chez notre partenaire Powens, leader dans l'agrégation bancaire.",
          },
          time: {
            title: 'Gagnez un temps précieux',
            description:
              'Automatisez la saisie de vos opérations. Grâce à notre reconnaissance intelligente, vos opérations locatives seont automatiquement créées et liées dans votre gestion.',
          },
          serenity: {
            title: "L'esprit tranquille",
            description:
              'Nous vous alertons immédiatement lorsqu’un loyer attendu a du retard. Vous pourrez alors décider de relancer vos locataires.',
          },
        },
      },
      addOrUpdateSynchro: 'Ajouter ou modifier un compte bancaire',
      bank: {
        actions: {
          confirmDeleteBank:
            'ATTENTION : La suppression du compte supprimera également toutes les transactions liées à vos opérations.',
          confirmUnlinkBankBuilding: 'Vous vous vraiment dissocier cette achat de cette banque ?',
          addBuilding: 'Ajouter un achat',
        },
        detailsBuildings: 'Détails des achats',
        linkedBuildings: 'Achat lié au compte | Achats liés au compte',
        lastSynchro: 'Dernières transactions récupérées le {lastSync}.',
        synchronizationActive: 'Vos transactions sont à jour.',
        numberRealEstatesLinked: 'BIEN LIÉ | BIENS LIÉS',
        lightbox: {
          title: 'Sélectionner un achat',
          validate: 'Valider',
          numberLinkedRealEstates: 'BIEN LIÉ | BIENS DÉJÀ LIÉS',
          numberRealEstates: 'BIEN | BIENS',
        },
      },
    },
    realEstatesPage: {
      noResult: 'Aucun résultat ne correspond à votre recherche.',
    },
    supportBuyRealEstate: {
      new: 'NOUVEAU !',
      description: 'Concrétisez plus vite votre projet.',
      cta: 'Découvrir',
    },
    performancePage: {
      back: 'Retour',
      graphs: {
        brut: 'Brut',
        net: 'Net',
        cashflow: {
          type: 'Cashflow',
          title: 'Graphique de cashflow',
          subtitle: 'Cashflow net par année',
        },
        performance: {
          type: 'Rendement',
          title: 'Graphique de rendement',
          subtitle: 'Rendement par année',
        },
        active: {
          type: 'Actifs',
          title: 'Graphique des actifs',
          subtitle: 'Actifs par année',
        },
        passive: {
          type: 'Passifs',
          title: 'Graphique des passifs',
          subtitle: 'Passifs net par année',
        },
        taxes: {
          type: 'Impôts',
          title: 'Graphique des impôts',
          subtitle: 'Impôts dû par année',
        },
        occupancy: {
          type: 'Occupation',
          title: "Graphique d'occupation",
          subtitle: "Taux d'occupation par année",
        },
      },
      noGraph: {
        title: 'Informations nécessaires',
        infos: "Veuillez vérifier qu'aucune information ne soient manquantes pour générer ce graphique :",
        noInfos: 'Nous ne disposons pas des informations nécessaires pour générer ce graphique.',
      },
      statCard: {
        rentability: { title: 'Rendement net', subtitle: "par rapport à l'année précédente" },
        capital: { title: 'Capital acquis', subtitle: 'Capital restant dû de {rest}€' },
        more: {
          title: 'Plus value à la revente',
          subtitle: "par rapport à l'investissement",
          link: 'Vendre mon bien',
        },
      },
      tooltips: {
        rentability:
          "Calcul sur l'année en cours : {break}{break} Rentabilité = [(recettes - charges - prélèvements sociaux - impôts - intérêt de la mensualité - assurance de la mensualité)*100] / investissement {break}{break} Investissement = prix d’achat net vendeur + commission agence + travaux + frais de dossier + garantie de crédit + meubles + frais de notaire - primes Anah)",
        capital:
          'Prix d’achat - capital emprunté + capital amorti à date. {break}{break} VS somme capital restant du à date.',
        more: 'Tranche basse = [(prix moyen au m2 de la ville x nombre de m2)-30%] - investissement {break}{break} Tranche haute = [(prix moyen au m2 de la ville x nombre de m2)+30%] - investissement {break}{break} VS investissement',
        cashflow:
          'Flux financiers entrants (Recettes & Loyers) - Flux financiers sortants (Charges, emprunts, impôts & prélèvements sociaux)',
        performance:
          'Brut (d’une année) = (recettes*100) / prix FAI {break}{break} Net (d’une année) = [(recettes - charges - prélèvements sociaux - impôts - intérêt de la mensualité - assurance de la mensualité)*100] / investissement {break}{break} Investissement = prix d’achat net vendeur + commission agence + travaux + frais de dossier + garantie de crédit + meubles + frais de notaire - primes Anah',
        active:
          'Bruts : Somme des prix d’achats des biens par année {break}{break} Nets : Somme des prix d’achat - capital restant dû (hors intérêts, hors assurance)',
        passive: 'Capital restant du (hors intérêts hors assurance)',
        taxes:
          'Les impôts affichés représentent les impôts et prélèvements sociaux générés par vos investissements seulement. Vous devez renseigner {link}.',
        taxesLink: 'votre déclaration d’impôts pour cela',
        occupancy:
          'Nombre de jours de présence d’au moins un locataire dans le bien entre le 1er janvier et aujourd’hui.',
      },
    },
    notPremiumChart: {
      title: "Vous n'êtes pas <strong>abonné</strong>",
      desc: 'Débloquez cette information et toutes<br />les fonctionnalités en vous abonnant.',
      action: "Je m'abonne",
    },
    pdfParameters: {
      title: 'Paramétrer mon rapport PDF',
      submit: 'Enregistrer et générer mon rapport PDF',
      options: {
        shareLink: {
          title: 'Lien de partage',
          desc: 'Activer le lien de partage',
        },
        personalSituation: {
          title: 'Situation personnelle',
          desc: 'Activer la situation personnelle',
        },
        cost: 'Coûts',
        profitability: 'Rentabilité',
        taxes: 'Impôts',
        loan: 'Emprunt',
        taxation: 'Fiscalité',
        resale: 'Revente',
        purchasePrice: "Prix d'achat",
        rent: 'Quel loyer',
      },
      identity: {
        title: 'Identité professionnelle',
        manage: 'Gérer les identités professionnelles',
      },
      graph: 'Graphiques',
      table: 'Tableaux',
      notifications: {
        success: 'Paramètres enregistrés',
        error: 'Une erreur est survenue',
      },
    },
    professionalIdentity: {
      add: {
        title: 'Ajouter une identité pro',
        lastName: 'Nom',
        firstName: 'Prénom',
        phone: 'Numéro de téléphone',
        email: 'Email',
        companyName: 'Société',
        siret: 'SIRET',
        country: 'Pays',
        city: 'Ville',
        postalCode: 'Code postal',
        address: 'Adresse postale',
        submit: 'Valider',
        notifications: {
          success: 'Nouvelle identité professionnelle créée',
          error: 'Une erreur est survenue',
        },
      },
      edit: {
        title: 'Modifier une identité pro',
        submit: 'Valider',
        notifications: {
          success: 'Identité professionnelle mise à jour',
        },
      },
      list: {
        title: 'Gérer mes identités pro',
        desc: "Permet d'assigner des identités de pro différentes à chacun de vos rapports PDF pour vos clients",
        add: 'Ajouter une identité pro',
      },
      delete: {
        confirm: "Souhaitez-vous supprimer l'identité professionnelle \n{name} ?",
        notifications: {
          success: 'Identité professionnelle supprimée avec succès',
          error: "Impossible de supprimer l'identité professionnelle",
        },
      },
    },
    announces: {
      title: 'Mes annonces',
      addAnnounce: 'Déposer une annonce',
      emptyList: 'Aucune annonce pour le moment',
      tabs: {
        active: 'En ligne',
        expired: 'Expirées',
      },
      card: {
        renovation: 'Travaux',
        alreadyRented: 'Déjà loué',
      },
      actions: {
        short: {
          preview: 'Prévisualiser',
          edit: 'Modifier',
          disabled: "Désactiver l'annonce",
        },
        preview: "Prévisualiser l'annonce",
        edit: "Modifier l'annonce",
        disable: "Désactiver l'annonce",
        enable: "Activer l'annonce",
      },
      form: {
        addTitle: 'Déposer une annonce',
        editTitle: 'Modifier une annonce',
        picturesLabel: 'Images',
        inputPictureLabel: 'Ajouter des images',
        submit: 'Valider',
        priceLabel: 'Prix',
        renovationLabel: 'Travaux',
        typeLabel: 'Type',
        typePlaceholder: 'Sélectionner un type',
        nbrPiecesLabel: 'Nombre de pièces',
        isNewSwitch: {
          label: 'Neuf',
          old: 'Ancien',
          new: 'Neuf',
        },
        alreadyRentedSwitch: {
          label: 'Déjà loué',
          no: 'Non',
          yes: 'Oui',
        },
        squareMeterLabel: 'Surface habitable (en m2)',
        squareMeterLabelShort: 'Surface habitable',
        rentLabel: 'Loyer (charges comprises)',
        descriptionLabel: 'Description',
        cityLabel: 'Ville',
        cityPlaceholder: 'Ville',
        postalCodeLabel: 'Code postal',
        agencyLabel: "Nom de l'agence",
        agencyPlaceholder: "Nom de l'agence",
        refLabel: "Référence de l'agence",
        refLabelShort: 'Référence agence',
        refPlaceholder: "Référence de l'agence",
        refPlaceholderShort: 'Référence agence',
        phoneLabel: 'Téléphone',
        phonePlaceholder: '06 00 00 00 00',
        notifications: {
          add: {
            success: 'Annonce créée avec succès',
            error: "Une erreur s'est produite lors de la création de l'annonce",
          },
          edit: {
            success: 'Annonce modifiée avec succès',
            error: "Une erreur s'est produite lors de la modification de l'annonce",
          },
          delete: {
            success: 'Annonce supprimée avec succès',
            error: "Une erreur s'est produite lors de la suppression de l'annonce",
          },
        },
        creationDateLabel: 'Date de publication',
        expirationDateLabel: "Date d'expiration",
        deleteLabel: "Suppression de l'annonce",
        deleteButton: "Supprimer l'annonce",
        delete: "Supprimer l'annonce",
        confirmDelete: 'Voulez vous vraiment supprimer cette annonce ?',
      },
    },
    uiKit: {
      validation: {
        rules: {
          virtualTourPartner: "Cette url n'est pas autorisée.",
          withoutUrl: 'Les urls ne sont pas autorisées.',
          withoutEmail: 'Les emails ne sont pas autorisées.',
          withoutPhoneNumber: 'Les numéros de téléphone ne sont pas autorisés.',
          file: 'Le fichier ne doit pas dépassé un poids de { size } Mo maximum.',
          imageFile: 'Les images au format heic ou heif ne sont pas supportés.',
        },
      },
    },
  },
}
