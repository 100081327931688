import Keycloak from 'keycloak-js'

import { config } from '@/core/config'
import Identity from '@/helpers/identity'
import store from '@/store'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useKeycloakService } from '@/services/keycloak.service'

const sessionStorageStartAuthDateKey = 'start-auth-date'

function getNowTimeStamp() {
  return Math.floor(Date.now() / 1000)
}

async function storeToken() {
  if (window.keycloak && window.keycloak.tokenParsed) {
    const token = window.keycloak.tokenParsed
    await store.dispatch('auth/setToken', token)
    useAuthStore().setToken(token)

    if (token && token.rl_profile) {
      useUserStore().setUpFromRlProfile(token.rl_profile)
    }
  }
}

export async function createKeycloak() {
  let keycloak = new Keycloak(`https://${location.hostname}/keycloak-phoenix.json`)
  let keycloakInitOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    redirectUri: window.location.href,
  }

  const refreshAccessTokenCallback = async () => {
    await useKeycloakService().getToken()
  }

  // Refresh Token
  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(config.keycloak.tokenTtl)
      .then(() => {})
      .catch(function () {})
      .then(() => {})
      .catch(function () {})
  }
  keycloak.onAuthSuccess = async () => {
    await storeToken()
    useAuthStore().setIsLoggedIn()
  }
  keycloak.onAuthLogout = () => {
    window.removeEventListener('focus', refreshAccessTokenCallback)
    useAuthStore().$reset()
  }
  keycloak.onAuthRefreshSuccess = async () => {
    await storeToken()
  }
  const startAuthDateKey = sessionStorage.getItem(sessionStorageStartAuthDateKey)
  let userSignInLessThant5min = false

  if (startAuthDateKey) {
    userSignInLessThant5min = getNowTimeStamp() - parseInt(startAuthDateKey) < 60 * 5
  }
  sessionStorage.removeItem(sessionStorageStartAuthDateKey)

  const authenticated = await keycloak.init(keycloakInitOptions)
  // Attach keycloak in global scope
  // eslint-disable-next-line no-undef
  window.keycloak = keycloak

  if (authenticated) {
    if (userSignInLessThant5min) {
      await store.dispatch('auth/setUserSignInLessThant5min')
    }
    await storeToken()
    useAuthStore().setIsLoggedIn()
    window.identity = new Identity(keycloak)
    await window.identity.ready
    window.addEventListener('focus', refreshAccessTokenCallback)
  }
}
