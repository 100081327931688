import { localStorageService } from '@/services/localStorage.service'
import { realEstatesService } from '@/services/realEstates.service'
import { widgetService } from '@/services/widget.service'
import { IFRAME_RELOAD_TYPES } from '@/store/index'
import { statsService } from '@/services/stats.service'
import { catch404ToNull } from '@/services/api'

const STORAGE_KEYS = {
  BUILDING_ID: 'USER_CONTEXT_BUILDING_ID',
  REAL_ESTATE_ID: 'USER_CONTEXT_REAL_ESTATE_ID',
  DISPLAY_WELCOME_POPUP: 'USER_CONTEXT_DISPLAY_WELCOME_POPUP',
}

export const state = {
  iframeMessageId: 0,
  buildings: [],
  buildingId: localStorageService.getString(STORAGE_KEYS.BUILDING_ID, undefined),
  mortgageSummary: {},
  realEstateId: localStorageService.getString(STORAGE_KEYS.REAL_ESTATE_ID, undefined),
  reloadIframe: 0,
  userStats: {},
}

export const MUTATIONS = {
  SET_RELOAD_IFRAME: 'RELOAD_IFRAME',
  SET_BUILDINGS: 'SET_BUILDINGS',
  SET_MORTGAGE_SUMMARY: 'SET_MORTGAGE_SUMMARY',
  SELECTED_REAL_ESTATE: 'SELECTED_REAL_ESTATE',
  INCREASE_IFRAME_MESSAGE_ID: 'INCREASE_IFRAME_MESSAGE_ID',
  SET_USER_STAT: 'SET_USER_STAT',
}

export function findLastCreatedRealEstate(buildings) {
  if (!buildings.length || !buildings[0].realEstates.length) return undefined

  return { buildingId: buildings[0].id, realEstateId: buildings[0].realEstates[0].id }
}

export const actions = {
  clearSelection({ commit }) {
    commit(MUTATIONS.SELECTED_REAL_ESTATE, { buildingId: undefined, realEstateId: undefined })
  },

  /*
   * TODO:
   * Turn default params force to false and ensure to pass force = true when there is necessary to reload all real estates
   * we will be able to do it in security when we'll have migrated all the forms for adding real estates in the app
   */
  async loadUserRealEstates({ commit, dispatch, getters, state, rootGetters }, payload) {
    const { sort, force } = { ...{ sort: 'desc', force: true }, ...payload }

    let buildings = []

    if (force || rootGetters.isForceReload(IFRAME_RELOAD_TYPES.REAL_ESTATES) || !state.buildings.length) {
      buildings = (await realEstatesService.getRealEstatesByBuildings(sort)) || []
      dispatch('setStoreReload', { type: IFRAME_RELOAD_TYPES.REAL_ESTATES, status: false }, { root: true })
    }

    if (buildings.length) {
      commit(MUTATIONS.SET_BUILDINGS, buildings)
    }

    if (buildings.length && !getters.realEstateIsSelected) {
      const { buildingId, realEstateId } = findLastCreatedRealEstate(buildings)

      commit(MUTATIONS.SELECTED_REAL_ESTATE, { buildingId, realEstateId })
      await dispatch('getMortgageSummary', buildingId)
    }
  },
  async getMortgageSummary({ commit }, buildingId) {
    const mortgage = await widgetService.getMortgageByBuildingID(buildingId)
    commit(MUTATIONS.SET_MORTGAGE_SUMMARY, mortgage)
  },
  async loadUserMortgageAffordabilityStat({ commit, state }) {
    let mortgageAffordability = state.userStats.mortgageAffordability

    if (!mortgageAffordability) {
      const res = await statsService.getUserMortgageAffordabilityStat().catch(catch404ToNull)
      mortgageAffordability = res?.mortgageAffordability
      commit(MUTATIONS.SET_USER_STAT, { ...state.userStats, mortgageAffordability })
    }

    return mortgageAffordability
  },
  setReloadIframe({ commit, state }) {
    commit(MUTATIONS.SET_RELOAD_IFRAME, `${++state.reloadIframe}`)
  },
  async sendIframeMessage({ commit, state }, { target, msg }) {
    if (window?.frames['legacy']?.length > 0) {
      commit(MUTATIONS.INCREASE_IFRAME_MESSAGE_ID)

      return await new Promise((resolve) => {
        const seq = state.iframeMessageId

        const handleIframeMessage = (res) => {
          if (res && res.data && res.data.__seq && res.data.__seq === seq) {
            window.removeEventListener('message', handleIframeMessage)
            resolve(res.data)
          }
        }

        window.addEventListener('message', handleIframeMessage, false)
        window.frames['legacy'].contentWindow.postMessage({ msg, __seq: seq }, target)
      })
    }
  },
}

export const mutations = {
  [MUTATIONS.SET_RELOAD_IFRAME]: (state, value) => {
    state.reloadIframe = value
  },

  [MUTATIONS.INCREASE_IFRAME_MESSAGE_ID]: (state) => {
    state.iframeMessageId = state.iframeMessageId + 1
  },
  [MUTATIONS.SET_BUILDINGS](state, buildings) {
    state.buildings = buildings || []
  },
  [MUTATIONS.SELECTED_REAL_ESTATE](state, { buildingId, realEstateId }) {
    state.buildingId = buildingId
    localStorageService.setString(STORAGE_KEYS.BUILDING_ID, buildingId)
    state.realEstateId = realEstateId
    localStorageService.setString(STORAGE_KEYS.REAL_ESTATE_ID, realEstateId)
  },
  [MUTATIONS.SET_MORTGAGE_SUMMARY](state, mortgageSummary) {
    state.mortgageSummary = mortgageSummary
  },

  [MUTATIONS.SET_USER_STAT](state, stats) {
    state.userStats = stats
  },
}

export const getters = {
  reloadIframe: (state) => state.reloadIframe,
  buildings: (state) => state.buildings,
  getBuildingById: (state) => (buildingId) => state.buildings.find((building) => building.id === buildingId),
  realEstates: (state) =>
    state.buildings.flatMap((building) =>
      building.realEstates.map((realEstate) => {
        return {
          ...realEstate,
          postalCode: building.postalCode,
          address: building.address,
          city: building.city,
        }
      }),
    ),
  mortgageSummary: (state) => state.mortgageSummary,
  selectedBuilding: ({ buildings, buildingId }) => buildings.find((building) => buildingId === building.id),
  selectedRealEstate: ({ realEstateId }, getters) => {
    const selectedBuilding = getters.selectedBuilding

    if (!selectedBuilding) return undefined

    return selectedBuilding.realEstates.find((realEstate) => realEstateId === realEstate.id)
  },
  realEstateIsSelected: (state, getters) => !!getters.selectedRealEstate,
  userMortgageAffordabilityStat: (state) => state.userStats.mortgageAffordability,
}

const userContext = {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}

export default userContext
