import { HOOKS, removePatchFields } from '@/services/api'

const BASE_PATH = '/v1/proprietary-identities'

export const useProprietaryIdentitiesService = () => ({
  create: async (identity) => {
    return await HOOKS.post(BASE_PATH, identity)
  },
  find: async (params) => {
    return await HOOKS.get(BASE_PATH, {
      params,
    })
  },
  get: async (id) => {
    return await HOOKS.get(`${BASE_PATH}/${id}`)
  },
  update: async (id, identity) => {
    return await HOOKS.patch(`${BASE_PATH}/${id}`, removePatchFields(identity))
  },
  delete: async (id) => {
    return await HOOKS.delete(`${BASE_PATH}/${id}`)
  },
})
