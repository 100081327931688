import { defineAsyncComponent, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import { simulationService } from '@/services/simulation.service'
import store from '@/store'
import i18n from '@/i18n'
import { usePermissionsStore } from '@/stores/permissions'
import { useDrawerStore } from '@hz/ui-kit'
import { useToastsStore } from '@hz/ui-kit'
import { useT } from '@/tolgee'

/*
 * App Simulation History store mission is to manage simulation history
 */
export const useSimulationsHistoryStore = defineStore('app-simulations-history', {
  state: () => ({
    simulations: [],
    defaultFolder: [],
    folders: [],
  }),
  actions: {
    openSaveFavoriteDrawer(params) {
      const props = {
        isCreationMode: false,
        unsavedSimulationParams: false,
        postSaveCallback: () => {},
        ...params,
      }

      useDrawerStore().addDrawer({
        id: 'drawer-save-favorite-simulation',
        component: shallowRef(
          defineAsyncComponent(() =>
            import('@/components/FutureInvestorDashboard/Drawer/DrawerSaveFavoriteSimulation.vue'),
          ),
        ),
        props,
      })
    },
    async openSimulationHistoryDrawer(options) {
      if (await store.dispatch('fin/hasAccess')) {
        const props = {
          displayHistoryTab: true,
          hideDeleteFolder: false,
          hideEmptyFolder: false,
          title: null,
          filterByAdId: null,
          createCta: null,
          ...options,
        }

        useDrawerStore().addDrawer({
          id: 'drawer-simulations-history',
          component: shallowRef(
            defineAsyncComponent(() =>
              import('@/components/FutureInvestorDashboard/Drawer/DrawerSimulationsHistory.vue'),
            ),
          ),
          props,
        })
      }
    },
    async fetchSimulations() {
      const { history, favorites } = await simulationService.getSimulations()
      this.simulations = [...history, ...favorites]
    },
    async fetchDirectory() {
      const directory = await simulationService.fetchUserDirectory()
      this.folders = directory.folders ?? []
      this.defaultFolder = directory.defaultFolder ?? []
    },
    async addFavorite(payload, displayConfirmation = true) {
      const userFavoriteDirectory = await simulationService.addFavorite(payload)
      this.defaultFolder = userFavoriteDirectory.defaultFolder ?? []
      this.folders = userFavoriteDirectory.folders ?? []
      await usePermissionsStore().refreshQuotasAndPermissions()
      if (displayConfirmation) {
        useToastsStore().success(i18n.global.t('horiz.fin-report.lightbox.save-favorite.success'))
      }
    },
    async unlinkFavoriteFromAd(folderName, favorite) {
      await this.deleteFavorite(favorite.simulationId, false)
      const payload = {
        folderName,
        summary: {
          name: favorite.name,
          simulationId: favorite.simulationId,
          reference: favorite.reference,
          adId: null,
          adTitle: null,
        },
      }

      await this.addFavorite(payload, false)
      const t = useT()
      useToastsStore().success(t('drawer-simulation-history.message.unlink-ad.success'))
    },
    async deleteFavorite(simulationId, refreshQuotasAndPermissions = true) {
      await simulationService.deleteFavorite(simulationId)
      if (refreshQuotasAndPermissions) {
        await usePermissionsStore().refreshQuotasAndPermissions()
      }

      if (this.defaultFolder.some((s) => s.simulationId === simulationId)) {
        this.defaultFolder = this.defaultFolder.filter(
          (simulation) => simulation.simulationId !== simulationId,
        )
      } else {
        this.folders = this.folders.map((folder) => {
          if (folder.simulations.some((s) => s.simulationId === simulationId))
            folder.simulations = folder.simulations.filter((s) => s.simulationId !== simulationId)

          return folder
        })
      }
    },
    async addFolder(folderName) {
      await simulationService.addFolder(folderName)
      this.folders.push({ name: folderName, simulations: [] })
      useToastsStore().success('Répertoire créer')
    },
    async updateFolderName(folderName, payload) {
      await simulationService.updateFolderName(folderName, payload)
      this.folders = this.folders.map((f) => {
        if (f.name === folderName) f.name = payload.folderName

        return f
      })
    },
    async deleteFolder(folderName) {
      await simulationService.deleteFolder(folderName)
      this.folders = this.folders.filter((f) => f.name !== folderName)
      await usePermissionsStore().refreshQuotasAndPermissions()
    },
  },
  getters: {
    simulationsFromAllFolders: (state) => {
      const simulations = [...state.defaultFolder]
      state.folders.forEach((folder) => {
        simulations.push(...folder.simulations)
      })

      return simulations
    },
  },
})
