import DrawerSubscription from '@/components/MySubscription/DrawerSubscription.vue'
import { shallowRef } from 'vue'
import { useDrawerStore } from '@hz/ui-kit'
import { useToastsStore } from '@hz/ui-kit'

import i18n from '@/i18n'

export const actions = {
  setDisplayNotPremium({ rootGetters }) {
    const drawerStore = useDrawerStore()
    drawerStore.addDrawer({
      id: 'drawer-subscription',
      props: { isPin: rootGetters['auth/isPIN'] },
      component: shallowRef(DrawerSubscription),
    })
  },

  setDisplayNotLoggedIn({ rootGetters }) {
    const drawerStore = useDrawerStore()
    drawerStore.addDrawer({
      id: 'drawer-subscription',
      props: { isPin: rootGetters['auth/isPIN'] },
      component: shallowRef(DrawerSubscription),
    })
  },

  setDisplayNotAllowed() {
    useToastsStore().warning(i18n.global.t('horiz.pin-dashboard.toaster.realEstate.notAllowed'))
  },
}

const notificationsModule = {
  namespaced: true,
  actions,
}

export default notificationsModule
