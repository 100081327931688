import { config } from '@/core/config'
import { Layouts } from '@/layouts'
import checkPermission from '@/router/middleware/checkPermission'
import { Permissions } from '@/helpers'
import appendSlug from '@/helpers/appendSlug'
import { defineAsyncComponent, h, markRaw, resolveComponent } from 'vue'
import { useDrawerStore } from '@hz/ui-kit'
import { investorBeforeEnter } from '@/router/rules/investorBeforeEnter'
import { allDocumentRoute, realEstatesRoute } from '@/router/routes/contants'
import { profileAttributes } from '@/stores/userProfile'
import { ensureUserProfileCompletedGuard } from '@/router/guards/ensure-user-profile-completed.guard'

const layout = Layouts.IFRAME

export const buildingRoutes = [
  // L4 - Page immeuble
  {
    path: '/building/:immeubleID',
    name: 'iframe-building-page',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/completude/:immeubleID',
      locale: 'legacy.nav-building-page',
      layout,
    },
    redirect: () => {
      return { name: realEstatesRoute.name }
    },
  },
  // 'Modifier investissement
  {
    path: '/building-investment/:investID',
    name: 'iframe-investment-page',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_investissement/:investID',
      locale: 'legacy.nav-building-investment',
      layout,
    },
    component: () => import('@/views/Iframes/BuildingInvestment.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_investissement/' + route.params.investID,
      }
    },
  },
  // Emprunts
  {
    path: '/building-loans/:immeubleID',
    name: 'iframe-building-loans',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/emprunts/:immeubleID',
      locale: 'legacy.nav-building-loans',
      layout,
      ignorePresentPushNotifications: true,
    },
    component: () => import('@/views/Iframes/BuildingLoans.vue'),
    props: (route) => {
      return {
        path: '/gestion/emprunts/' + route.params.immeubleID,
      }
    },
  },
  // L7 - Modifier immeuble
  {
    path: '/building-edit/:immeubleID',
    name: 'iframe-building-edit',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_immeuble/:immeubleID',
      locale: 'legacy.nav-building-edit',
      layout,
    },
    component: () => import('@/views/Iframes/BuildingEdit.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_immeuble/' + route.params.immeubleID,
      }
    },
  },
  // L9 - Ajout d'un bien
  {
    path: '/building-real-estate-add/:immeubleID?',
    name: 'iframe-building-real-estate-add',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_bien/:immeubleID',
      locale: 'legacy.nav-building-real-estate-add',
      layout,
    },
    component: () => import('@/views/Iframes/BuildingRealEstateAdd.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_bien/' + route.params.immeubleID ?? '',
      }
    },
  },
  {
    path: '/real-estate-add',
    name: 'iframe-real-estate-add',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_bien',
      locale: 'legacy.nav-building-real-estate-add',
      layout,
    },
    component: () => import('@/views/Iframes/BuildingRealEstateAdd.vue'),
    props: () => {
      return {
        path: '/gestion/ajout_bien/',
      }
    },
  },
  {
    path: '/building-real-estate-edit/:immeubleID/:bienID',
    name: 'iframe-building-real-estate-edit',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_bien/:immeubleID/:bienID',
      locale: 'legacy.nav-building-real-estate-edit',
      layout,
    },
    component: () => import('@/views/Iframes/BuildingRealEstateEdit.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_bien/' + route.params.immeubleID + '/' + route.params.bienID,
      }
    },
  },
  {
    path: '/building-declaration/:immeubleID',
    name: 'iframe-building-declaration',
    meta: { requireAuth: true, locale: 'legacy.nav-building-declaration', layout },
    component: () => import('@/views/Iframes/BuildingDeclaration.vue'),
    props: (route) => ({
      path: '/gestion/ajout_declaration/' + route.params.immeubleID,
    }),
  },
  {
    path: '/building-balance-sheet/:immeubleID',
    name: 'iframe-building-balance-sheet',
    meta: { requireAuth: true, locale: 'legacy.nav-building-balance-sheet', layout },
    component: () => import('@/views/Iframes/BuildingBalanceSheet.vue'),
    props: (route) => ({
      path: '/gestion/ajout_bilan_syndic/' + route.params.immeubleID,
    }),
  },
  {
    path: '/building-summary/:immeubleID',
    name: 'iframe-building-summary',
    meta: { requireAuth: true, locale: 'legacy.nav-building-summary', layout },
    component: () => import('@/views/Iframes/BuildingSummary.vue'),
    props: (route) => ({
      path: '/gestion/resumeGeneral/' + route.params.immeubleID,
      clear: 'operations',
    }),
  },
  {
    path: '/building-add-investment',
    name: 'iframe-building-add-investment',
    meta: { requireAuth: true, locale: 'legacy.nav-building-add-investment', layout },
    component: () => import('@/views/Iframes/BuildingAddInvestment.vue'),
    props: {
      path: '/gestion/ajout_investissement',
    },
  },
  // L29 - Documents immeuble
  {
    path: '/real-estate-documents/:immeubleID',
    name: 'iframe-real-estate-documents',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/documents/:immeubleID',
      locale: 'legacy.nav-tenant-documents',
      layout,
    },
    component: () => import('@/views/Iframes/RealEstateDocuments.vue'),
    props: (route) => {
      return {
        path: '/gestion/documents/' + route.params.immeubleID,
      }
    },
  },
  {
    path: '/building-group/:immeubleID/:year?',
    name: 'iframe-building-group',
    meta: { requireAuth: true, locale: 'legacy.nav-building-group', layout },
    component: () => import('@/views/Iframes/BuildingGroup.vue'),
    props: (route) => {
      if (route.params.year) {
        return {
          path: `/gestion/ensemblesImmeuble/${route.params.immeubleID}/${route.params.year}`,
        }
      } else {
        return {
          path: `/gestion/ensemblesImmeuble/${route.params.immeubleID}}`,
        }
      }
    },
  },
]

export const realEstateRoutes = [
  // L11 - Modifier bien
  {
    path: '/real-estate-edit/:immeubleID/:bienID',
    name: 'iframe-real-estate-edit',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_bien/:immeubleID/:bienID',
      locale: 'legacy.nav-real-estate-edit',
      layout,
      hideZendesk: true,
    },
    component: () => import('@/views/Iframes/RealEstateEdit.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_bien/' + route.params.immeubleID + '/' + route.params.bienID,
      }
    },
  },
  // L12 - Inventaire
  {
    path: '/real-estate-inventory/:bienID',
    name: 'iframe-real-estate-inventory',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/inventaire/:bienID',
      locale: 'legacy.nav-real-estate-inventory',
      layout,
    },
    component: () => import('@/views/Iframes/RealEstateInventory.vue'),
    props: (route) => {
      return {
        path: '/gestion/inventaire/' + route.params.bienID,
      }
    },
  },
  // L13 - MEL
  {
    path: '/real-estate-mel/:immeubleID/:bienID',
    name: 'iframe-real-estate-mel',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/mescandidats/:immeubleID/:bienID',
      locale: 'legacy.nav-real-estate-mel',
      layout,
    },
    beforeEnter: ensureUserProfileCompletedGuard([profileAttributes.lastName, profileAttributes.firstName]),
    component: () => import('@/views/Iframes/RealEstateMEL.vue'),
    props: (route) => {
      return {
        path: '/gestion/mescandidats/' + route.params.immeubleID + '/' + route.params.bienID,
      }
    },
  },
  // L14 - Ajouter un bail
  {
    path: '/real-estate-add-lease/:bienID/:bailID?',
    name: 'iframe-real-estate-add-lease',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/editBail/:bienID/:bailID?',
      locale: 'legacy.nav-real-estate-add-lease',
      layout,
    },
    beforeEnter: ensureUserProfileCompletedGuard([profileAttributes.lastName, profileAttributes.firstName]),
    component: () => import('@/views/Iframes/RealEstateAddLease.vue'),
    props: (route) => {
      return {
        realEstateId: route.params.bienID,
        path: '/gestion/editBail/' + route.params.bienID + appendSlug(route.params.bailID),
      }
    },
  },
  {
    path: '/real-estate-add-ad/:bienID',
    name: 'iframe-real-estate-add-ad',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_annonce/:bienID',
      locale: 'legacy.nav-real-estate-add-ad',
      layout,
    },
    component: () => import('@/views/Iframes/RealEstateAddAd.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_annonce/' + route.params.bienID,
      }
    },
  },
  {
    path: '/end-mel/:bienID',
    name: 'iframe-end-mel',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/clore/:bienID',
      locale: 'legacy.nav-end-mel',
      layout,
    },
    component: () => import('@/views/Iframes/EndMel.vue'),
    props: (route) => {
      return {
        path: '/gestion/clore/' + route.params.bienID,
      }
    },
  },
]

export const tenantRoutes = [
  // Add operation
  {
    path: '/tenant-add-operation/:immeubleID/:tenantID',
    name: 'iframe-tenant-add-operation-bis',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_operation/:immeubleID',
      locale: 'legacy.nav-tenant-add-operation',
      layout,
    },
    props: {},
    beforeEnter(to) {
      const drawerStore = useDrawerStore()
      drawerStore.addDrawer({
        id: 'drawer-operations',
        props: {
          buildingId: to.params.immeubleID,
          tenantId: to.params.tenantID,
        },
        component: () => import('@/components/Operations/DrawerAddOperation.vue'),
      })
    },
  },
  // L17 - Add rent
  {
    path: '/tenant-add-operation/:immeubleID/rent/:tenantID',
    name: 'iframe-tenant-add-operation',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_operation/:immeubleID/a/recetteLoyer/a/a/:tenantID',
      locale: 'legacy.nav-tenant-add-payment',
      layout,
    },
    props: {},
    beforeEnter(to) {
      const drawerStore = useDrawerStore()
      drawerStore.addDrawer({
        id: 'drawer-operations',
        props: {
          buildingId: to.params.immeubleID,
          tenantId: to.params.tenantID,
          operationName: 'recetteLoyer',
        },
        component: () => import('@/components/Operations/DrawerAddOperation.vue'),
      })
    },
  },
  // - Edit operation
  {
    path: '/tenant-add-operation/:immeubleID/:operationID',
    name: 'iframe-tenant-edit-operation',
    meta: {
      requireAuth: true,
      notInMenu: true,
      legacyURL: '/gestion/ajout_operation/:immeubleID/:operationID',
      locale: 'legacy.nav-tenant-edit-operation',
      layout,
    },
    props: {},
    beforeEnter(to) {
      const drawerStore = useDrawerStore()
      drawerStore.addDrawer({
        id: 'drawer-operations',
        props: {
          operationId: to.params.operationID,
        },
        component: () => import('@/components/Operations/DrawerAddOperation.vue'),
      })
    },
  },
  // L18 - Fill in end date ?
  {
    path: '/tenant-add-receipt/:tenantID',
    name: 'iframe-tenant-add-receipt-from-quittance',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/quittance/:tenantID',
      locale: 'legacy.nav-tenant-add-receipt',
      layout,
    },
    beforeEnter: ensureUserProfileCompletedGuard([profileAttributes.lastName, profileAttributes.firstName]),
    component: () => import('@/views/Iframes/TenantAddReceipt.vue'),
    props: (route) => {
      return {
        path: '/gestion/quittance/' + route.params.tenantID,
      }
    },
  },
  // L19 - Show tenant payments
  {
    path: '/tenant-balance/:immeubleID/:tenantID',
    name: 'iframe-tenant-balance',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/operationsImmeuble/:immeubleID/?filters=locataireId_:tenantID',
      locale: 'legacy.nav-tenant-balance',
      layout,
    },
    component: () => import('@/views/Iframes/TenantBalance.vue'),
    props: (route) => {
      return {
        path:
          '/gestion/operationsImmeuble/' +
          route.params.immeubleID +
          '/?filters=locataireId_' +
          route.params.tenantID,
      }
    },
  },
  // L21.2 - Indiquer le jour du loyer
  {
    path: '/tenant-edit-day-rent/:immeubleID/:tenantID',
    name: 'iframe-tenant-edit-day-rent',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_locataire/:immeubleID/:tenantID/a/dayLoyer',
      locale: 'legacy.nav-tenant-edit-day-rent',
      layout,
    },
    component: () => import('@/views/Iframes/TenantEdit.vue'),
    props: (route) => {
      return {
        path:
          '/gestion/ajout_locataire/' + route.params.immeubleID + '/' + route.params.tenantID + '/a/dayLoyer',
      }
    },
  },
  // L22 - Editer / Générer un document
  {
    path: '/tenant-document-generation/:bienID/:tenantID',
    name: 'iframe-tenant-document',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/documents/:bienID/locataire/:tenantID',
      locale: 'legacy.nav-tenant-document-add',
      layout,
    },
    beforeEnter: ensureUserProfileCompletedGuard([profileAttributes.lastName, profileAttributes.firstName]),
    component: () => import('@/views/Iframes/TenantDocumentAdd.vue'),
    props: (route) => {
      return {
        path: '/gestion/documents/' + route.params.bienID + '/locataire/' + route.params.tenantID,
      }
    },
  },
  // L23 - Documents locataire
  {
    path: '/tenant-documents/:immeubleID/:tenantID',
    name: 'iframe-tenant-documents',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/documents/:immeubleID/locataire/:tenantID',
      locale: 'legacy.nav-tenant-documents',
      layout,
    },
    component: () => import('@/views/Iframes/TenantDocuments.vue'),
    props: (route) => {
      return {
        tenantId: route.params.tenantID,
        path: '/gestion/documents/' + route.params.immeubleID + '/locataire/' + route.params.tenantID,
      }
    },
  },
  // L24 - Révision loyer
  {
    path: '/tenant-revision/:tenantID',
    name: 'iframe-tenant-revision',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/revisions/:tenantID',
      locale: 'legacy.nav-tenant-revision',
      layout,
      ignorePresentPushNotifications: true,
    },
    beforeEnter: ensureUserProfileCompletedGuard([profileAttributes.lastName, profileAttributes.firstName]),
    component: () => import('@/views/Iframes/TenantRevision.vue'),
    props: (route) => {
      return {
        path: '/gestion/revisions/' + route.params.tenantID,
      }
    },
  },
  // L25 - Régularisation charges
  {
    path: '/tenant-regularize/:tenantID',
    name: 'iframe-tenant-regularize',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_regul/:tenantID',
      locale: 'legacy.nav-tenant-regularize',
      layout,
      ignorePresentPushNotifications: true,
    },
    beforeEnter: ensureUserProfileCompletedGuard([profileAttributes.lastName, profileAttributes.firstName]),
    component: () => import('@/views/Iframes/TenantRegularize.vue'),
    props: (route) => {
      return {
        path: '/gestion/ajout_regul/' + route.params.tenantID,
        clear: 'operations',
      }
    },
  },
]

export const userRoutes = [
  {
    path: '/user-bank',
    name: 'iframe-user-bank',
    meta: {
      requireAuth: true,
      locale: 'legacy.nav-user-bank',
      layout,
    },
    redirect: () => {
      return { name: 'bank-synchronization' }
    },
  },
  // L27 - Revenus
  {
    path: '/user-income',
    name: 'iframe-user-income',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/revenus',
      locale: 'legacy.nav-user-income',
      layout,
    },
    component: () => import('@/views/Iframes/UserIncome.vue'),
    props: {
      path: '/gestion/revenus',
    },
  },

  // change user's identity
  {
    ...allDocumentRoute,
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/documents',
      locale: 'legacy.nav-tenant-documents-label',
      layout,
    },
    component: () => import('@/views/Iframes/UserDocuments.vue'),
    beforeEnter: investorBeforeEnter,
    props: {
      path: `/user/login?redirectAfterLogin=${config.urls.rl}/gestion/documents`,
    },
  },
]

const TenantAddComponent = {
  props: ['tenantId', 'realEstateId', 'buildingId', 'path'],
  components: {
    TenantEdit: defineAsyncComponent(() => import('@/views/Iframes/TenantEdit.vue')),
  },
  mounted() {
    if (!this.tenantId) {
      const drawerStore = useDrawerStore()
      drawerStore.addDrawer({
        id: 'drawer-add-tenant',
        component: () => import('@/components/Tenant/DrawerAddTenant.vue'),
        props: {
          realEstateId: this.realEstateId,
        },
      })
    }
  },
  render() {
    const tenantId = this.tenantId
    const path = this.path

    let TenantEditComponent
    if (tenantId) {
      TenantEditComponent = resolveComponent('tenant-edit')
    }

    return tenantId ? h(TenantEditComponent, { path }) : null
  },
}

const OperationAddComponent = {
  mounted() {
    const drawerStore = useDrawerStore()
    drawerStore.addDrawer({
      id: 'drawer-operations',
      component: markRaw(
        defineAsyncComponent(() => import('@/components/Operations/DrawerAddOperation.vue')),
      ),
    })
  },
  render() {
    return null
  },
}

let routes = [
  // * SCOPE 1 -- IMMEUBLE
  ...buildingRoutes,
  // * SCOPE 2 -- BIEN
  ...realEstateRoutes,
  // * SCOPE 3 -- LOCATAIRE
  ...tenantRoutes,
  // * SCOPE 4 -- USER
  ...userRoutes,
  {
    path: '/tenant-emails/:tenantID',
    name: 'iframe-tenant-emails',
    meta: { requireAuth: true, locale: 'legacy.nav-tenant-emails', layout },
    component: () => import('@/views/Iframes/TenantEmails.vue'),
    props: (route) => {
      return {
        path: '/gestion/emails/' + route.params.tenantID,
      }
    },
  },
  {
    path: '/tenant-accounting/:tenantID',
    name: 'iframe-tenant-add-receipt-from-acccount',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/compteLocataire/:tenantID',
      locale: 'legacy.nav-tenant-accounting',
      layout,
    },
    component: () => import('@/views/Iframes/TenantAccounting.vue'),
    props: (route) => {
      return {
        path: '/gestion/compteLocataire/' + route.params.tenantID,
        clear: 'operations',
      }
    },
  },
  {
    path: '/bank-transactions/:accountId',
    name: 'iframe-bank-transaction',
    meta: {
      requireAuth: true,
      legacyURL: '/banque/transactions/:accountId',
      locale: 'legacy.nav-user-bank',
      layout,
    },
    component: () => import('@/views/Iframes/TenantAccounting.vue'),
    props: (route) => {
      return {
        path: '/banque/transactions/' + route.params.accountId,
      }
    },
  },
  // Add tenant - TODO: Remove this three routes when tenant edit is migrated to horiz
  {
    path: '/tenant-add',
    name: 'lightbox-tenant-add',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_locataire',
      locale: 'legacy.nav-tenant-add',
      layout: Layouts.DEFAULT,
    },
    component: TenantAddComponent,
  },
  {
    path: '/tenant-add/:buildingId/a/:bienId',
    name: 'lightbox-tenant-add-from-real-estate',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_locataire/:buildingId/a/:bienId',
      locale: 'legacy.nav-tenant-add',
      layout,
    },
    component: TenantAddComponent,
    props: (route) => {
      return {
        path: `/gestion/ajout_locataire/${route.params.buildingId}/${route.params.tenantId || 'a'}/${
          route.params.bienId
        }`,
        buildingId: route.params.buildingId,
        tenantId: route.params.tenantId,
        realEstateId: route.params.bienId,
      }
    },
  },
  // moved here to prevent it from appearing in the menu, but we may still need it for inter-iframe nav
  {
    path: '/tenant-edit/:immeubleID/:tenantID/:focus?',
    name: 'iframe-tenant-edit',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_locataire/:immeubleID/:tenantID',
      locale: 'legacy.nav-tenant-edit',
      layout,
      hideZendesk: true,
    },
    component: TenantAddComponent,
    props: (route) => {
      return {
        path:
          '/gestion/ajout_locataire/' +
          route.params.immeubleID +
          '/' +
          route.params.tenantID +
          (route.params.focus ? `?focus=${route.params.focus}` : ''),
        buildingId: route.params.immeubleID,
        tenantId: route.params.tenantID,
      }
    },
  },
  {
    path: '/tenant-add-operation',
    name: 'lightbox-tenant-add-operation',
    meta: {
      requireAuth: true,
      legacyURL: '/gestion/ajout_operation',
      locale: 'legacy.nav-tenant-add-operation',
      layout: Layouts.DEFAULT,
    },
    component: OperationAddComponent,
  },
]

// * Add Permissions check
const ALLOW_ACCESS_WITHOUT_PERMISSIONS_CHECK = ['iframe-user-documents']
routes = routes.map((r) => {
  const obj = r
  if (!ALLOW_ACCESS_WITHOUT_PERMISSIONS_CHECK.includes(r.name)) {
    obj.meta = {
      ...r.meta,
      middlewares: [checkPermission],
      permissions: [Permissions.LEGACY],
    }
  }

  return obj
})

export default routes
