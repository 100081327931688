import { journalEntriesService } from '@/services/journalEntries.service'

export const state = {
  operations: [],
  cashFlow: {
    income: '',
    outcome: '',
    incomeUnpaid: '',
    outcomeUnpaid: '',
    positive: [],
    negative: [],
  },
  alreadyFetched: 0,
  isLastPage: false,
}

const batchSize = 50

export const actions = {
  async fetchCashFlow({ commit }, filters) {
    const cf = await journalEntriesService.cashFlow(filters)
    commit('setCashFlow', cf)
  },
  async fetchOperations({ commit, state }, filters) {
    let operations = await journalEntriesService.getAll(
      filters + `&skip=${state.alreadyFetched}&limit=${batchSize}`,
    )

    // Remove duplicated loan operations
    operations = operations.filter((operation) => !state.operations.find((op) => op._id === operation._id))

    // no more operation to display
    if (operations.length < batchSize) commit('setLastPage', true)

    commit('updateFetchNumber', batchSize)
    commit('addOperation', operations)
  },
  async resetOperations({ commit }, filters) {
    const operations = await journalEntriesService.getAll(filters + `&limit=${batchSize}`)
    commit('resetFetchNumber')
    commit('setLastPage', false)

    // no more operation to display
    if (operations.length < batchSize) commit('setLastPage', true)

    commit('updateFetchNumber', batchSize)
    commit('setOperations', operations)
  },
  addOperation({ commit, dispatch, rootGetters }, operation) {
    commit('addOperation', operation)

    const op = operation[0]
    if (op.transaction) {
      if (op.locataireId) {
        const tenant = rootGetters['tenants/getAll'].find((tenant) => tenant.tenant.id === op.locataireId)
        if (tenant) {
          op.locataireId = {
            id: op.locataireId,
            nom: tenant.tenant.lastName,
            prenom: tenant.tenant.firstName,
          }
        }
      }
      dispatch(
        'synchro/linkOperation',
        { operation: op, transactionId: op.transaction.sourceTransaction.id },
        { root: true },
      )
    }
  },
  async deleteOperation({ commit }, operationId) {
    const { error } = await journalEntriesService.deleteOperation(operationId)
    if (error) return false
    commit('deleteOperation', operationId)

    return true
  },
  updateOperation({ commit, dispatch }, operation) {
    commit('updateOperation', operation)

    if (operation.transaction) {
      dispatch(
        'synchro/updateLinkedOperation',
        { operation: operation, transactionId: operation.transaction.sourceTransaction._id },
        { root: true },
      )
    }
  },

  unlinkTransaction({ commit, state }, operationId) {
    const op = state.operations.find((op) => op._id === operationId)
    if (op) {
      op.transaction = null
      commit('updateOperation', op)
    }
  },
  linkTransaction({ commit, state, dispatch, rootGetters }, { operationId, transaction }) {
    const op = state.operations.find((op) => op._id === operationId)
    op.transaction = transaction

    commit('updateOperation', op)
    if (op.locataireId) {
      const tenant = rootGetters['tenants/getAll'].find((tenant) => tenant.tenant.id === op.locataireId)
      if (tenant) {
        op.locataireId = {
          id: op.locataireId,
          nom: tenant.tenant.lastName,
          prenom: tenant.tenant.firstName,
        }
      }
    }
    dispatch(
      'synchro/linkOperation',
      { operation: op, transactionId: transaction.sourceTransactionId },
      { root: true },
    )
  },
  async toggleOperationPaid({ commit, state }, operationId) {
    const op = state.operations.find((op) => op._id === operationId)
    op.operationPaid = !op.operationPaid
    await journalEntriesService.patchOnlyWantedPayload(operationId, { operationPaid: op.operationPaid })
    commit('updateOperation', op)
  },
  async toggleOperationAuto({ commit, state }, operationId) {
    const op = state.operations.find((op) => op._id === operationId)
    op.operationAuto = !op.operationAuto
    await journalEntriesService.patchOnlyWantedPayload(operationId, { operationAuto: op.operationAuto })
    commit('updateOperation', op)
  },
}

export const mutations = {
  setCashFlow(state, cashFlow) {
    state.cashFlow = cashFlow
  },
  setOperations(state, ops) {
    state.operations = ops
  },
  updateFetchNumber(state, num) {
    state.alreadyFetched += num
  },
  resetFetchNumber(state) {
    state.alreadyFetched = 0
  },
  setLastPage(state, value) {
    state.isLastPage = value
  },
  addOperation(state, operation) {
    state.operations.push(...operation)
    state.operations = state.operations.sort(
      (op1, op2) => new Date(op2.operationDate) - new Date(op1.operationDate),
    )
  },
  deleteOperation(state, operationId) {
    state.operations = state.operations.filter((op) => op._id !== operationId)

    return true
  },
  updateOperation(state, operation) {
    state.operations = state.operations.map((op) => {
      if (op._id === operation._id) {
        return operation
      }

      return op
    })
  },
}

export const getters = {
  operations: (state) => state.operations,
  cashFlow: (state) => state.cashFlow,
  isLastPage: (state) => state.isLastPage,
}

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
}
