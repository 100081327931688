<template>
  <uikit-drawer id="drawer-notifications-parameters">
    <template #header>
      <div class="title">
        {{ $t('uiKit.notifications.parameters.title') }}
      </div>
    </template>
    <div class="parameters-options">
      <div class="switch-text">
        <span class="switch-title">{{ $t('uiKit.notifications.parameters.switch.title') }}</span>
        <span>{{ $t('uiKit.notifications.parameters.switch.description') }}</span>
      </div>

      <uikit-button
        v-if="browserPermissionNotAsked || (browserPermissionGranted && !userIsOptIn)"
        type="primary"
        :disabled="browserPermissionDenied"
        @click="handleActiveNotifications"
      >
        Activer les notifications
      </uikit-button>

      <uikit-button
        v-if="userIsOptIn && browserPermissionGranted"
        :disabled="browserPermissionDenied"
        type="secondary"
        @click="handleDeActiveNotifications"
      >
        Désactiver les notifications
      </uikit-button>
    </div>

    <div
      v-if="browserPermissionDenied"
      class="warning-notifications-denied"
    >
      <div class="explanations">
        <span class="warning-symbol">⚠️</span>
        <div class="texts">
          <span>
            <strong>{{ $t(`uiKit.notifications.browserDenied.${i18nPath}.title`) }}</strong>
          </span>

          <span>
            {{ $t(`uiKit.notifications.browserDenied.${i18nPath}.text`) }}
            <a
              class="help-link"
              href="https://aide.horiz.io/hc/fr/articles/5604911168402-Comment-g%C3%A9rer-les-notifications-push-"
              target="_blank"
            >
              {{ $t(`uiKit.notifications.browserDenied.${i18nPath}.link`) }}
            </a>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="browserPermissionDenied"
      class="browser-explanations"
      v-html="notificationsParametersExplanations"
    />
  </uikit-drawer>
</template>

<script setup>
import { UikitDrawer, UikitButton } from '@hz/ui-kit'
import { computed } from 'vue'
import { useNotificationsPushStore } from '@/stores/appNotificationsPush'

const pushStore = useNotificationsPushStore()

const userIsOptIn = computed(() => pushStore.userIsOptIn)
const browserPermissionGranted = computed(() => pushStore.browserPermissionGranted)
const browserPermissionDenied = computed(() => pushStore.browserPermissionDenied)
const browserPermissionNotAsked = computed(() => pushStore.browserPermissionNotAsked)

const browserUsed = computed(() => {
  const userAgent = navigator.userAgent
  let browserName = ''

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  } else {
    browserName = 'No browser detection'
  }

  return browserName
})
const isChrome = computed(() => browserUsed.value === 'chrome')
const isFirefox = computed(() => browserUsed.value === 'firefox')

const isBrowserHandled = computed(
  () => browserUsed.value === 'chrome' || browserUsed.value === 'safari' || browserUsed.value === 'firefox',
)
const i18nPath = computed(() => (isBrowserHandled.value ? 'browserHandled' : 'browserNotHandled'))

const notificationsParametersExplanations = computed(() => {
  let notificationsParametersExplanationsTemplate = ''

  if (isChrome.value) {
    notificationsParametersExplanationsTemplate = `<img class="browser-img chrome-img" src="${
      new URL('../../../assets/images/Notifications/chrome-push.gif', import.meta.url).href
    }" alt="resetNotificationsChrome" />`
  } else if (isFirefox.value) {
    notificationsParametersExplanationsTemplate = `<img class="browser-img firefox-img" src="${
      new URL('../../../assets/images/Notifications/firefox-push.gif', import.meta.url).href
    }" alt="resetNotificationsFirefox" />`
  } else {
    notificationsParametersExplanationsTemplate = `<img class="browser-img safari-img" src="${
      new URL('../../../assets/images/Notifications/safari-push.gif', import.meta.url).href
    }" alt="resetNotificationsSafari" />`
  }

  return notificationsParametersExplanationsTemplate
})

const handleActiveNotifications = async () => {
  if (pushStore.browserPermissionNotAsked) {
    await pushStore.requestBrowserPermission()
  } else if (pushStore.browserPermissionGranted && !pushStore.userIsOptIn) {
    await pushStore.optIn()
  }
}

const handleDeActiveNotifications = async () => {
  await pushStore.optOut()
}
</script>

<style lang="less" scoped>
.title {
  font-weight: 500;
  font-size: 20px;
}

.parameters-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: -12px;
  gap: var(--ds-space-lg);

  .switch-text {
    font-size: var(--ds-font-xs);
    color: var(--ds-color-gray-50);
    display: flex;
    flex-direction: column;
    font-weight: var(--ds-weight-semi-bold);
    text-align: left;
    margin-left: 5px;

    .switch-title {
      font-size: var(--ds-font-sm);
      color: var(--ds-color-gray-100);
      line-height: 25px;
    }
  }
}

.warning-notifications-denied {
  padding: 5px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .explanations {
    border: 2px solid var(--ds-color-error-100);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    font-size: 14px;
    font-style: italic;
    text-align: left;

    .warning-symbol {
      font-size: 18px;
      margin-top: -2px;
    }

    .texts {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .help-link {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.browser-explanations {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  :deep(.browser-img) {
    max-width: 95%;
    max-height: 95%;
  }

  :deep(.safari-img) {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
