import { API } from '@/services/api'

async function gatherOnBoardingUID() {
  return await API.get('/onboarding')
}

function completeOnBoardingPin(payload) {
  return API.post('/onboarding/pin/completed', payload)
}

function completeOnBoardingFin() {
  return API.post('/onboarding/fin/completed', {})
}

export const marketingService = {
  gatherOnBoardingUID,
  completeOnBoardingPin,
  completeOnBoardingFin,
}
