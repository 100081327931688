import { useValidationService } from '@/services/validation.service'

const fieldRefs = {}

const phoneNumberValidator = async (newValue, params) => {
  let countryCode = 'FR'
  if (!params.fieldRef || !params.fieldRef.value || !params.fieldRef.value.getCountryCode) {
    console.warn('Field reference is not defined for phone number validator')
  } else {
    countryCode = params.fieldRef.value.getCountryCode()
  }

  if (!newValue || !newValue.length) {
    return true
  }

  if (!fieldRefs[params.fieldRef]) {
    fieldRefs[params.fieldRef] = {
      value: null,
      result: null,
    }
  }

  const oldResult = fieldRefs[params.fieldRef]

  if (oldResult.value !== newValue) {
    const phoneValidation = await useValidationService().phone(countryCode, newValue)
    oldResult.value = newValue
    oldResult.result = phoneValidation.valid

    return phoneValidation.valid
  } else {
    return await Promise.resolve(oldResult.result)
  }
}

export default phoneNumberValidator
