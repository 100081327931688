import { UNAUTHORIZED } from '@/router/constants'
import { PRO_PERMISSIONS, usePermissionsStore } from '@/stores/permissions'
import { useUserStore } from '@/stores/user'

export default function checkPermissions(permissions) {
  const permissionsToCheck = Object.keys(permissions)

  return ({ next }) => {
    const permissionsAndQuotas = usePermissionsStore()
    for (let i = 0; i < permissionsToCheck.length; i++) {
      const permissionKey = permissionsToCheck[i]

      if (!permissionsAndQuotas[permissionKey]) {
        const userStore = useUserStore()
        if (userStore.isFreeUser) {
          return { name: UNAUTHORIZED }
        } else if (userStore.isMemberOfProOrganization) {
          return {
            name: 'hz-organization-subscription',
            query: {
              actionNotPermit: true,
            },
          }
        } else if (userStore.isPremium && PRO_PERMISSIONS.includes(permissionKey)) {
          return {
            name: 'hz-current-subscription',
            query: {
              actionNotPermit: true,
            },
          }
        } else {
          return { name: UNAUTHORIZED }
        }
      }
    }

    return next()
  }
}
