import store from '@/store'
import i18n from '@/i18n'
import { Layouts } from '@/layouts'
import { HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME } from '@/router/constants'

const layout = Layouts.ON_BOARDING

function navigationGuard(to, from, next) {
  const hasData = store.getters['stepper/hasData']
  if (hasData) {
    next()
  } else {
    next({ name: HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME })
  }
}

const title = i18n.global.t('horiz.onboarding.meta.page-title')
const description = i18n.global.t('horiz.onboarding.meta.description')
const featureImageUrl = 'https://horiz.io/imgs/Horiz-accompagnement-immobilier.png'
const featureImageAltText = i18n.global.t('horiz.onboarding.meta.img-alt')

const onbaordingMetas = (url) => ({
  title,
  metaTags: [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:image',
      content: featureImageUrl,
    },
    {
      property: 'og:url',
      content: `https://horiz.io/onboarding/future-investor/${url}`,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'og:site_name',
      content: 'https://horiz.io',
    },
    {
      name: 'twitter:image:alt',
      content: featureImageAltText,
    },
  ],
})

const routes = [
  {
    component: () => import('@/views/Onboardings/FutureInvestor/Entry.vue'),
    path: 'step/entry',
    name: HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME,
    meta: {
      ...onbaordingMetas('step/entry'),
      available: true,
      layout,
      ignorePresentPushNotifications: true,
      spontaneousInteractionAllowed: false,
    },
  },
  {
    beforeEnter: navigationGuard,
    component: () => import('@/views/Onboardings/FutureInvestor/VerifyRentabilityOne.vue'),

    path: 'step/verifyRentabilityOne',
    name: 'hz-fin-step-verify-rentability-one',
    meta: {
      ...onbaordingMetas('step/verifyRentabilityOne'),
      available: true,
      layout,
      ignorePresentPushNotifications: true,
      spontaneousInteractionAllowed: false,
    },
  },
]

export default routes
