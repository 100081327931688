import { PromiseWithLoading } from '@/helpers'
import { statsService } from '@/services/stats.service'

const state = {
  buildingsStats: {},
}

export const SET_BUILDINGS_STATS = 'SET_BUILDINGS_STATS'

const actions = {
  getBuildingsStats({ commit, state }, { year, force } = { force: false }) {
    if (force || !state.buildingsStats[year]) {
      return PromiseWithLoading((resolve, reject) => {
        statsService
          .getBuildings({ year })
          .then((stats) => {
            commit(SET_BUILDINGS_STATS, { year, buildingsStats: stats })
            resolve(stats)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
}

const mutations = {
  [SET_BUILDINGS_STATS](state, { year, buildingsStats }) {
    state.buildingsStats[year] = buildingsStats
  },
}

const statsModule = {
  namespaced: true,
  actions,
  mutations,
  state,
}

export default statsModule
