import i18n from '@/i18n'
import { useToastsStore } from '@hz/ui-kit'

export default function notificationMessageHandler({ to, next, store, router }) {
  if (to.query.fromMessage) {
    const query = Object.assign({}, to.query)
    const isLoggedIn = store.getters['auth/isLoggedIn']

    let translateKey
    switch (query.fromMessage) {
      case 'subscriptionSuccess':
        translateKey = 'horiz.notification-messages.subscription-success'
        break

      case 'signInSignUpSuccess':
        if (isLoggedIn) {
          translateKey = 'horiz.notification-messages.sign-in-sign-up-success'
        }
        break

      case 'gatewayFinToPinSuccess':
        translateKey = 'horiz.notification-messages.gateway-fin-to-pin-success'
        break

      default:
        translateKey = ''
    }

    if (translateKey && translateKey.length) {
      useToastsStore().success(i18n.global.t(translateKey))
    }

    delete query.fromMessage
    router.replace(Object.assign({}, to, { query }))
  } else {
    return next()
  }
}
