import { keycloakService } from '@/services/keycloak.service'
import { config } from '@/core/config'

export const requireAuthRedirect = {
  LANDING_INVESTOR: 'LANDING_INVESTOR',
}

export default function requiredAuth({ next, to }) {
  // eslint-disable-next-line no-undef
  const { keycloak } = window
  const { meta = {} } = to

  if (meta && meta.requireAuth && (!keycloak || !keycloak.authenticated)) {
    if (meta.requireAuthRedirect) {
      switch (meta.requireAuthRedirect) {
        case requireAuthRedirect.LANDING_INVESTOR:
          location.href = config.urls.home + '/gestion-locative'
          break
      }
    } else {
      // * handles payment from offer page
      if (to.query.behavior === 'register') {
        keycloakService.registerAndGetBack()
      } else {
        keycloakService.loginAndGetBack()
      }
    }

    return false
  }

  return next()
}
