import { API, HOOKS } from '@/services/api'

function get(params) {
  return API.get('/tenants', { params, withCredentials: true })
}

export function create(tenantData) {
  return API.post('/tenants', tenantData)
}

export function getById(id) {
  return API.get(`/tenants/${id}`)
}

export function update(id, data) {
  return API.put(`/tenants/${id}`, data)
}

export function patch(id, data) {
  return HOOKS.patch(`/v1/tenants/${id}`, data)
}

export const tenantsService = {
  create,
  get,
  getById,
  update,
  patch,
}
