import { userActionHistoryService } from '@/services/userActionHistory.service'
import { defineStore } from 'pinia'

export const useUserActionHistoryStore = defineStore('app-user-action-history', {
  state: () => ({
    history: {},
    presentFinToPinGatewayTutorialDone: true,
    presentPerformanceReportTutorialDone: true,
    presentRealEstatesAdsTutorialDone: true,
  }),
  actions: {
    async fetch() {
      const data = await userActionHistoryService.get()
      this.history = data?.history || null
      this.presentFinToPinGatewayTutorialDone = !!this.history?.tutorial_finPinGateway
      this.presentPerformanceReportTutorialDone = !!this.history?.tutorial_presentPerformanceReport
      this.presentRealEstatesAdsTutorialDone = !!this.history?.tutorial_presentRealEstatesAds
    },
    async flagActionAsDone(action) {
      await userActionHistoryService.put(action)
      await this.fetch()
    },
  },
  getters: {
    signUpEventIsTracked: (state) => !!state.history?.signUpEventIsTracked,
    completeProfileAfterSignUpDone: (state) => !!state.history?.complete_profile_after_sign_up,
  },
})
