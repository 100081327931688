const fileValidator = (values) => {
  if (!values || !values.length) {
    return true
  }

  const files = values.filter((file) => file.isNew)
  const notSupportedMimeTypes = ['image/heic', 'image/heif']

  for (const file of files) {
    if (notSupportedMimeTypes.includes(file.file.type)) {
      return false
    }
  }

  return true
}

export default fileValidator
