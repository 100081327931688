import { HOOKS } from '@/services/api'

const BASE_PATH = '/v1/users/me/action-history'

export function get() {
  return HOOKS.get(BASE_PATH)
}

export function put(action) {
  return HOOKS.patch(`${BASE_PATH}/${action}`, {})
}

export const userActionHistoryService = { get, put }
