import { config } from '@/core/config'

const isNotDevelopmentEnv = config.mode !== 'development'
const isNotCrawlerBot = navigator.userAgent.indexOf('Pinterestbot') === -1

let SentryInstance = null

export function startMonitoring(app, router) {
  if (isNotDevelopmentEnv && isNotCrawlerBot) {
    import('@sentry/vue').then((Sentry) => {
      SentryInstance = Sentry
      Sentry.init({
        app,
        dsn: config.sentry.dns,
        environment: config.sentry.env,
        release: `${config.app.name}@${config.app.version}`,

        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

        captureUnhandledRejections: false,

        tracePropagationTargets: [new URL(config.urls.api).hostname, new URL(config.urls.hooks).hostname],

        tracesSampleRate: config.sentry.env === 'production' ? 0.2 : 1,

        tracingOptions: {
          trackComponents: true,
        },

        replaysSessionSampleRate: config.sentry.env === 'production' ? 0.01 : 0,
        replaysOnErrorSampleRate: config.sentry.env === 'production' ? 0.05 : 0,

        // Vue specific
        logErrors: true,
        attachProps: true,
        attachStacktrace: true,

        ignoreErrors: [
          /Navigation cancelled from "(.*)" to "(.*)" with a new navigation\./,
          /Redirected when going from "\/(.*)" to "\/(.*)" via a navigation guard\./,
          /Avoided redundant navigation to current location: "(.*)"\./,
          /Redirected when going from "(.*)" to "(.*)" via a navigation guard\./,
          'ChunkLoadError',
          'Loading CSS chunk',
          /Failed to fetch dynamically imported module(.*)/,
          /Unable to preload CSS(.*)/,
          'Non-Error promise rejection captured',
          /Non-Error promise rejection captured with (.*)/,
          /(.*)Failed to register a ServiceWorker(.*)/,
          /Object captured as promise rejection with keys(.*)/,
        ],

        // eslint-disable-next-line no-unused-vars
        beforeSend: (event, hint) => {
          const patternToIgnore = ['chrome-extension://', 'jimo-invader', '"filename":"/ekr/snippet.js"']
          const stringException = JSON.stringify(event.exception)
          if (event && event.exception) {
            for (const pattern of patternToIgnore) {
              if (stringException.includes(pattern)) {
                return null
              }
            }
          }

          return event
        },
      })
    })
  }
}

export function setMonitoringUserId(userId, username) {
  if (isNotDevelopmentEnv && SentryInstance) {
    SentryInstance.setUser({ userId, username })
  }
}

export function clearMonitoringUserId() {
  if (isNotDevelopmentEnv && SentryInstance) {
    SentryInstance.setUser(null)
  }
}
