import { defineStore } from 'pinia'

/*
 * App Zendesk store mission is to manage zendesk details, no more
 */
export const useZendeskStore = defineStore('app-zendesk', {
  state: () => ({
    hide: false,
  }),
  actions: {
    hideZendesk(value) {
      this.hide = value
      const iframeButton = document.querySelector('iframe#launcher')
      if (iframeButton) {
        iframeButton.style.display = value ? 'none' : 'block'
      }
    },
  },
})
