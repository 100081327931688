const withoutPhoneNumberValidator = (value) => {
  if (!value || !value.length) {
    return true
  }

  // const tempDivElement = document.createElement('div')
  // tempDivElement.innerHTML = value
  // const strippedHtml = tempDivElement.textContent || tempDivElement.innerText || ''

  const strippedHtml = value.replace(/(<([^>]+)>)/gi, ' ')

  /*
^
    (?:(?:\+|00)33|0)     # Dialing code
    \s*[1-9]              # First number (from 1 to 9)
    (?:[\s.-]*\d{2}){4}   # End of the phone number
$
*/

  // eslint-disable-next-line no-useless-escape
  const regex = /(?:(?:\+|00)?33|0)(?:\s*(?:\(0\))?[1-9])(?:[\s.-]*\d{2}){4}/

  let isPhoneNumber = false

  strippedHtml.replace(regex, (match) => {
    // eslint-disable-next-line no-useless-escape
    if (/^[0-9.,\/]+$/.test(match)) {
      const contentTokens = strippedHtml.split(/(\s|\(|\)|\[|\])+/)

      if (contentTokens.includes(match)) {
        isPhoneNumber = true
      }
    } else {
      isPhoneNumber = true
    }
  })

  return !isPhoneNumber
}

export default withoutPhoneNumberValidator
