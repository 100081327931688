export const HZ_INVESTOR_ONBOARDING = 'hz-onboarding-investor-entry'
export const HZ_INVESTOR_ONBOARDING_PATHNAME = '/onboarding/investor'

export const HZ_FUTURE_INVESTOR_ONBOARDING = 'hz-onboarding-fin-entry'
export const HZ_FUTURE_INVESTOR_ONBOARDING_PATHNAME = '/onboarding/future-investor'
export const HZ_FUTURE_INVESTOR_ONBOARDING_ENTRY_POINT_NAME = 'hz-fin-step-entry'

export const LOGIN = 'login'
export const SIGNUP = 'signup'
export const LOADING = 'loading'
export const ERROR = 'error'
export const UNAUTHORIZED = 'unauthorized'

export const SMART_BANNER_META = {
  PERFORMANCE_ALERTS: {
    smartBanner: 'PERFORMANCE_ALERTS',
    metaTags: [
      {
        name: 'apple-itunes-app',
        content: 'app-id=1596721394',
      },
    ],
  },
}
