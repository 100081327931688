import store from '@/store'
import stepperModule from '@/store/stepper.module'
import router from '@/router'
import { keycloakService } from '@/services/keycloak.service'
import { useSimulationStore } from '@/stores/simulation'
import { HZ_FUTURE_INVESTOR_ONBOARDING_PATHNAME } from '@/router/constants'
import { useAuthStore } from '@/stores/auth'
import { marketingService } from '@/services/marketing.service'

const onBoardingSq = ['ob_prix', 'ob_code_postal', 'ob_ville', 'ob_surface', 'ob_loyer']

export const futureInvestorBeforeEnter = async (to) => {
  const authStore = useAuthStore()

  if (to.query.origin && to.query.uuid) {
    const query = Object.assign({}, to.query)

    delete query.origin
    delete query.uuid

    // Use router instead of next to prevent navigation guard throwing error
    return router.push(Object.assign({}, to, { query }))
  } else if (Object.keys(to.query).some((key) => onBoardingSq.includes(key))) {
    if (!authStore.isLoggedIn) {
      await marketingService.gatherOnBoardingUID()
    }

    return true
  } else if (to.query.computeParameters) {
    return true
  } else {
    const simulationStore = useSimulationStore()
    const lastSimulation = await simulationStore.getLastSimulation(true)

    if (lastSimulation) {
      return true
    } else {
      if (authStore.isLoggedIn) {
        return HZ_FUTURE_INVESTOR_ONBOARDING_PATHNAME
      } else {
        keycloakService.loginAndGetBack()
      }
    }

    if (!store.hasModule('stepper')) {
      store.registerModule('stepper', stepperModule)
    }

    return true
  }
}
